import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useLocation } from 'react-router';
import * as yup from 'yup';

import { translations } from '@/locales';

import { apiClient } from '@/services/api';

import { Auth } from '@/containers/use-auth';

import { Button } from '@/components/Button';
import { CenteredBoxLayout } from '@/components/CenteredBoxLayout';
import { Form } from '@/components/Form';
import { Checkbox } from '@/components/Form/Checkbox';
import { InputWithLabel } from '@/components/Form/InputWithLabel';
import { PasswordInput } from '@/components/Form/PasswordInput';
import { ValidatedField } from '@/components/Form/ValidatedField';
import { Link } from '@/components/Link';
import { LoadingButton } from '@/components/LoadingButton';

export const schema = yup
  .object({
    agree: yup.boolean().required().isTrue(translations.validation.mixed.required).label(translations.fields.agreement),
    email: yup.string().trim().email().required().label(translations.fields.emailAddress),
    firstName: yup.string().trim().required().label(translations.fields.firstName),
    lastName: yup.string().trim().required().label(translations.fields.lastName),
    password: yup.string().trim().required().label(translations.fields.password)
  })
  .required();

export const RegisterPage: React.FC = () => {
  const { loginWithPassword } = Auth.useContainer();

  const location = useLocation();

  const params = new URLSearchParams(location.search);

  const [token, email] = [params.get('token'), params.get('email')];

  const onSubmit = React.useCallback(
    async (values: yup.InferType<typeof schema>) => {
      await apiClient.onboarding
        .signUp({ requestBody: { ...values, token: token! } })
        .then(async () => await loginWithPassword({ email: values.email, password: values.password }));
    },
    [loginWithPassword, token]
  );

  return (
    <CenteredBoxLayout title={<FormattedMessage id={translations.pages.register.title} />}>
      <Form initialValues={{ email: email! }} {...{ onSubmit, schema }}>
        {({ submitting }) => (
          <div className="space-y-6">
            <ValidatedField
              field={InputWithLabel}
              label={<FormattedMessage id={translations.fields.emailAddress} />}
              name="email"
              readOnly
              type="email"
            />

            <div className="flex gap-4">
              <ValidatedField
                field={InputWithLabel}
                id="firstName"
                label={<FormattedMessage id={translations.fields.firstName} />}
                name="firstName"
                readOnly={submitting}
                type="text"
              />

              <ValidatedField
                field={InputWithLabel}
                id="lastName"
                label={<FormattedMessage id={translations.fields.lastName} />}
                name="lastName"
                readOnly={submitting}
                type="text"
              />
            </div>

            <ValidatedField
              as={PasswordInput}
              field={InputWithLabel}
              id="password"
              label={<FormattedMessage id={translations.fields.password} />}
              name="password"
              readOnly={submitting}
              type="password"
            />

            <ValidatedField
              field={Checkbox}
              id="agreement"
              label={
                <FormattedMessage
                  id={translations.pages.register.agreement}
                  values={{
                    privacy: (
                      <Link as="a" className="font-normal text-indigo-600" href="https://univerz.life/policies/">
                        <FormattedMessage id={translations.pages.register.privacy} />
                      </Link>
                    ),
                    terms: (
                      <Link
                        as="a"
                        className="font-normal text-indigo-600"
                        href="https://univerz.life/terms-and-conditions/"
                      >
                        <FormattedMessage id={translations.pages.register.terms} />
                      </Link>
                    )
                  }}
                />
              }
              name="agree"
              readOnly={submitting}
              type="checkbox"
            />

            <Button appearance="primary" as={LoadingButton} className="w-full" loading={submitting} type="submit">
              <FormattedMessage id={translations.pages.register.cta} />
            </Button>
          </div>
        )}
      </Form>
    </CenteredBoxLayout>
  );
};
