import { ApiError } from '@billy/management-api-sdk';
import axios from 'axios';

export const uploadFile = async (url: string, headers: Record<string, any>, file: File) => {
  const form = await createFormData({ ...headers, file });

  const response = await axios.post(url, form);

  return response.data;
};

export const createFormData = async (fields: Record<string, any>) => {
  const form = new FormData();

  Object.entries(fields).forEach(([key, value]) => form.append(key, value));

  return form;
};

export const extractErrorMessage = (error: Error, defaultMessage?: string) => {
  if (error instanceof ApiError && error.body?.message) return error.body.message;

  //I did this because sometimes the error is not recognized as ApiError even if it is an ApiError
  // and the body.message is more relevant
  return (error as ApiError)?.body?.message ?? error.message ?? defaultMessage ?? `Something went wrong.`;
};

export const getDateFromString = (dateString: string) => new Date(dateString);
