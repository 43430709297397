import { QuestionDto } from '@billy/management-api-sdk';
import { MinusCircleIcon } from '@heroicons/react/24/outline';
import { ChevronRightIcon, PhotoIcon } from '@heroicons/react/24/solid';
import classnames from 'classnames';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link as RouterLink, useNavigate, useParams } from 'react-router-dom';
import useSWR from 'swr';
import * as yup from 'yup';

import { apiClient } from '@/services/api';

import { translations } from '@/locales';
import { uploadFile } from '@/utils/functions';

import { useModal } from '@/hooks/useModal';
import { page, size, useQueryParams } from '@/hooks/useQueryParams';

import { Button } from '@/components/Button';
import { EmptyList } from '@/components/EmptyList';
import { Form } from '@/components/Form';
import { Checkbox } from '@/components/Form/Checkbox';
import { DragAndDropFile } from '@/components/Form/DragAndDropFile';
import { getSourceFromPossibleValue, ImageFileInput, ImageValue } from '@/components/Form/ImageFileInput';
import { InputWithLabel } from '@/components/Form/InputWithLabel';
import { ValidatedField } from '@/components/Form/ValidatedField';
import { Layout } from '@/components/Layout';
import { Link } from '@/components/Link';
import { LoadingOverlay } from '@/components/Loading';
import { LoadingButton } from '@/components/LoadingButton';
import { Modal } from '@/components/Modal';
import { ConfirmationModal, deleteIcon } from '@/components/Modal/ConfirmationModal';
import { SelectMembersModal } from '@/components/Modal/SelectMembersModal';
import { Pagination } from '@/components/Pagination';
import { MemberProfile } from '@/components/Profile';
import { SubmitFeedback } from '@/components/SubmitFeedback';
import { Table, TableBody, TableHeader, TableHeaderColumn, TableRow, TableRowColumn } from '@/components/Table/Table';

const maxSizeInMB = 10;
const minImageWidth = 2160;
const minImageHeight = 1024;

const config = { page, size };

const LIKE_EMOJI = '👍';
const DISLIKE_EMOJI = '👎';

const BinaryAnswer = {
  NO: 'binary:no',
  YES: 'binary:yes'
} as const;

type BinaryAnswer = typeof BinaryAnswer[keyof typeof BinaryAnswer];

const getEmojiFromAnswer = (answer?: BinaryAnswer): string | null => {
  if (!answer) return null;

  const answerToEmojiMap: Record<BinaryAnswer, string> = {
    [BinaryAnswer.YES]: LIKE_EMOJI,
    [BinaryAnswer.NO]: DISLIKE_EMOJI
  };

  return answerToEmojiMap[answer] ?? null;
};

export const startsWithBinary = (inputString?: string): boolean => !!inputString?.startsWith('binary:');

export const schema = yup
  .object({
    binary: yup.boolean(),
    image: yup
      .mixed()
      .test({
        exclusive: true,
        message: translations.customValidation.maxFileSize,
        name: 'maxFileSize',
        params: { max: maxSizeInMB },
        test: (value) => typeof value === 'string' || !value?.file || value.file.size <= maxSizeInMB * 1024 * 1024
      })
      .test({
        exclusive: true,
        message: translations.customValidation.minImageResolution,
        name: 'minImageResolution',
        params: { height: minImageHeight, width: minImageWidth },
        test: (value) =>
          typeof value === 'string' ||
          !value?.file ||
          (value.image.width >= minImageWidth && value.image.height >= minImageHeight)
      })
      .label(translations.fields.image),
    options: yup.array().of(yup.string().trim().required().label(translations.fields.option)).required(),
    text: yup.string().trim().required().label(translations.pages.questions.question)
  })
  .required();

export const QuestionDetailsPage: React.FC = () => {
  const { questionId } = useParams();
  const [queryParams, updateQueryParams] = useQueryParams({ config });

  const isNew = !questionId;

  const {
    data: loadedQuestion,
    isLoading: loading,
    error
  } = useSWR(
    isNew ? null : ['get-question-details', questionId],
    () => apiClient.question.getQuestionDetails({ questionId: questionId! }),
    {
      revalidateIfStale: true,
      revalidateOnFocus: true,
      revalidateOnMount: true,
      revalidateOnReconnect: true
    }
  );

  const {
    data: members,
    isLoading: membersLoading,
    error: membersError,
    mutate: onMembersReload
  } = useSWR(
    isNew ? null : ['questions-find-audience-members', queryParams],
    () =>
      apiClient.question.findQuestionAudienceMembers({
        pageNumber: queryParams.page,
        pageSize: queryParams.size,
        questionId: questionId!
      }),
    {
      revalidateIfStale: true,
      revalidateOnFocus: true,
      revalidateOnMount: true,
      revalidateOnReconnect: true
    }
  );

  const navigate = useNavigate();

  const imageUpload = React.useCallback(async (image?: string | ImageValue) => {
    if (!image) return;

    if (typeof image === 'string') return image;

    const intent = await apiClient.question.initiateQuestionUpload({ requestBody: { mimeType: image.file.type } });

    await uploadFile(intent.upload.url, intent.upload.headers, image.file);

    return intent.asset;
  }, []);

  const onSubmit = React.useCallback(
    async (values: yup.InferType<typeof schema>) => {
      if (!isNew) return;

      const image = (await imageUpload(values.image)) ?? undefined;

      await apiClient.question
        .createQuestion({
          requestBody: {
            ...values,
            audience: { groupIds: [], userIds: [] },
            image
          }
        })
        .then((question) => navigate(`/questions/${question.id}`));
    },
    [imageUpload, isNew, navigate]
  );
  const confirmationModal = useModal(ConfirmationModal);

  const removeMemberDialogModal = useModal(ConfirmationModal);

  const selectMembersModal = useModal(SelectMembersModal);

  const initialValues = isNew
    ? { options: ['', ''] }
    : {
        ...loadedQuestion,
        binary: startsWithBinary(loadedQuestion?.options?.[0]?.text),
        options: loadedQuestion?.options?.map((option) => option.text)
      };

  const intl = useIntl();

  return (
    <Layout>
      <LoadingOverlay {...{ error, loading }}>
        <div className="mb-5 flex items-center gap-5">
          <Link as={RouterLink} className="text-sm font-medium text-gray-500" to="/questions">
            <FormattedMessage id={translations.pages.questions.questions} />
          </Link>

          <ChevronRightIcon className="w-5 text-sm text-gray-400" />

          <Link as={RouterLink} className="max-w-[12rem] truncate text-sm font-medium text-gray-500" to="#">
            {(initialValues as QuestionDto)?.text || <FormattedMessage id={translations.pages.questions.newQuestion} />}
          </Link>
        </div>

        <Form className="flex flex-col justify-between space-y-6" {...{ initialValues, onSubmit, schema }}>
          {({ submitting, form, values, submitSucceeded, submitError, dirtySinceLastSubmit }) => {
            const showCheckbox = !!isNew || startsWithBinary(values.options[0]);

            return (
              <>
                <div className="flex items-center justify-between text-2xl font-semibold">
                  {values?.text?.trim() || (initialValues as QuestionDto)?.text?.trim() || (
                    <FormattedMessage id={translations.pages.questions.newQuestion} />
                  )}

                  {!isNew && (
                    <Button
                      appearance="deleteSecondary"
                      onClick={() => {
                        confirmationModal.open().catch(() => null);
                      }}
                      type="button"
                    >
                      <span className="whitespace-nowrap">
                        <FormattedMessage id={translations.buttons.deleteQuestion} />
                      </span>
                    </Button>
                  )}
                </div>

                <div className="max-w-xl space-y-6">
                  <div className="flex gap-2">
                    <ValidatedField
                      accept="image/png,image/jpeg"
                      as={ImageFileInput}
                      disabled={!!submitting || !isNew}
                      field={InputWithLabel}
                      id="image"
                      label={
                        <>
                          <FormattedMessage id={translations.fields.image} />{' '}
                          <span className="text-sm font-medium text-gray-400">
                            ({intl.formatMessage({ id: translations.customValidation.optional }).toLowerCase()})
                          </span>
                        </>
                      }
                      name="image"
                      readOnly={!!submitting || !isNew}
                    >
                      <DragAndDropFile onChange={(files) => form.change('image', files)}>
                        {({ dragging }) => (
                          <div
                            className={classnames(
                              'flex h-48 w-80 flex-col',
                              'cursor-pointer items-center  justify-center rounded-md border-dashed border-gray-300',
                              { 'border-2': !values?.image },
                              dragging ? 'bg-gray-100' : 'bg-white'
                            )}
                          >
                            {values.image ? (
                              <img
                                alt="card-preview"
                                className="h-full w-full rounded-md object-cover"
                                src={getSourceFromPossibleValue(values?.image)}
                              />
                            ) : (
                              <>
                                <PhotoIcon className="h-12 w-12 text-gray-400" />

                                <div className="text-indigo-600">
                                  <FormattedMessage id={translations.fields.uploadImage} />
                                </div>

                                <div className="text-xs font-normal leading-4 text-gray-500">
                                  <FormattedMessage
                                    id={translations.pages.community.imageValidation}
                                    values={{ ...{ maxSizeInMB, minImageHeight, minImageWidth } }}
                                  />
                                </div>
                              </>
                            )}
                          </div>
                        )}
                      </DragAndDropFile>
                    </ValidatedField>

                    {!!values.image && !!isNew && (
                      <Button
                        appearance="basic"
                        className="self-end"
                        onClick={() => {
                          form.change('image', undefined);
                        }}
                        type="button"
                      >
                        <FormattedMessage id={translations.buttons.remove} />
                      </Button>
                    )}
                  </div>

                  <ValidatedField
                    field={InputWithLabel}
                    id="text"
                    label={<FormattedMessage id={translations.pages.questions.question} />}
                    name="text"
                    readOnly={!!submitting || !isNew}
                  />

                  {!!showCheckbox && (
                    <ValidatedField
                      disabled={!!submitting || !isNew}
                      field={Checkbox}
                      id="binary"
                      label={<FormattedMessage id={translations.pages.questions.binaryQuestion} />}
                      name="binary"
                      onChange={() =>
                        values.binary
                          ? form.change('options', ['', ''])
                          : form.change('options', [BinaryAnswer.NO, BinaryAnswer.YES])
                      }
                      type="checkbox"
                    />
                  )}

                  {!values.binary && !startsWithBinary(values.options[0]) && (
                    <div className="space-y-6">
                      {values.options?.map((_, index) => (
                        <div className="group flex items-center gap-4" key={index}>
                          <ValidatedField
                            className="w-full"
                            field={InputWithLabel}
                            id="options"
                            label={<FormattedMessage id={translations.fields.optionsList[index]} />}
                            name={`options.${index}`}
                            readOnly={!!submitting || !isNew}
                          />

                          {!!isNew && (
                            <Button
                              appearance="basic"
                              className={classnames('w-48 self-end opacity-0 group-hover:opacity-100', {
                                hidden: values.options?.length < 3
                              })}
                              onClick={() => {
                                form.change(
                                  'options',
                                  values.options?.filter((_, i) => i !== index)
                                );
                              }}
                              type="button"
                            >
                              <FormattedMessage id={translations.buttons.remove} />
                            </Button>
                          )}
                        </div>
                      ))}

                      {!!isNew && (
                        <Button
                          appearance="secondary"
                          className={classnames('mt-2 w-48', { hidden: values.options?.length > 3 })}
                          onClick={() => form.change('options', [...values.options, ''])}
                          type="button"
                        >
                          <FormattedMessage id={translations.buttons.addOption} />
                        </Button>
                      )}
                    </div>
                  )}
                </div>

                {!isNew && (
                  <LoadingOverlay {...{ error: membersError, loading: membersLoading }}>
                    <div className="space-y-4">
                      <div className="flex justify-between text-lg">
                        <FormattedMessage id={translations.pages.pageSections.audience} />

                        {!!members?.items.length && (
                          <Button
                            appearance="secondary"
                            className="w-32"
                            onClick={() => {
                              selectMembersModal.open().catch(() => null);
                            }}
                            type="button"
                          >
                            <FormattedMessage id={translations.buttons.addMembers} />
                          </Button>
                        )}
                      </div>

                      {members?.items.length ? (
                        <Table>
                          <TableHeader>
                            <TableRow>
                              <TableHeaderColumn>
                                <FormattedMessage id={translations.fields.name} />
                              </TableHeaderColumn>

                              <TableHeaderColumn>
                                <FormattedMessage id={translations.fields.communityName} />
                              </TableHeaderColumn>

                              <TableHeaderColumn>
                                <FormattedMessage id={translations.fields.answers} />
                              </TableHeaderColumn>

                              <TableHeaderColumn />
                            </TableRow>
                          </TableHeader>

                          <TableBody>
                            {members.items.map((member, index) => (
                              <TableRow key={index}>
                                <TableRowColumn>
                                  <MemberProfile
                                    email={member.email}
                                    firstName={member.firstName}
                                    lastName={member.lastName}
                                    src={member.image}
                                  />
                                </TableRowColumn>

                                <TableRowColumn>{member.community?.name}</TableRowColumn>

                                <TableRowColumn>
                                  {startsWithBinary(member.answers[0]?.text) ? (
                                    <span className="text-2xl">
                                      {getEmojiFromAnswer(member.answers[0]?.text as BinaryAnswer)}
                                    </span>
                                  ) : (
                                    member.answers.map((answer) => answer.text).join(', ')
                                  )}
                                </TableRowColumn>

                                <TableRowColumn className="relative">
                                  <Button
                                    className="absolute right-8 -translate-y-1/2 transform"
                                    onClick={() => {
                                      removeMemberDialogModal.open(member).catch(() => null);
                                    }}
                                    type="button"
                                  >
                                    <MinusCircleIcon className="h-6 w-6" />
                                  </Button>
                                </TableRowColumn>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      ) : (
                        <EmptyList
                          description={<FormattedMessage id={translations.emptyStates.addMembers} />}
                          title={<FormattedMessage id={translations.emptyStates.noMembers} />}
                        >
                          <Button
                            appearance="secondary"
                            className="w-32"
                            onClick={() => {
                              selectMembersModal.open().catch(() => null);
                            }}
                            type="button"
                          >
                            <FormattedMessage id={translations.buttons.addMembers} />
                          </Button>
                        </EmptyList>
                      )}

                      <Modal
                        {...selectMembersModal.props}
                        onInvite={async (groupIds, userIds) => {
                          await apiClient.audience
                            .addAudienceUser({
                              requestBody: { groupIds, userIds },
                              resourceId: loadedQuestion!.id
                            })
                            .catch((e) => console.log({ e }))
                            .then(() => onMembersReload());
                        }}
                      />
                    </div>

                    {!!members && members.items.length > 0 && (
                      <Pagination
                        className="mt-9"
                        currentPage={members.page.pageNumber}
                        onPageChange={(page: number) => updateQueryParams({ page })}
                        pageSize={members.page.pageSize}
                        totalItems={members.page.total}
                      />
                    )}
                  </LoadingOverlay>
                )}

                <div className="flex gap-6">
                  <Button
                    appearance="basic"
                    className={classnames('mt-6 w-48', { hidden: !isNew })}
                    onClick={() => form.restart()}
                    type="button"
                  >
                    <FormattedMessage id={translations.buttons.cancel} />
                  </Button>

                  <Button
                    appearance="primary"
                    as={LoadingButton}
                    className={classnames('mt-6 w-48', { hidden: !isNew })}
                    loading={submitting}
                    type="submit"
                  >
                    <FormattedMessage id={translations.buttons.send} />
                  </Button>

                  <SubmitFeedback {...{ submitError, submitSucceeded }} show={!submitting && !dirtySinceLastSubmit} />
                </div>
              </>
            );
          }}
        </Form>

        <Modal
          {...removeMemberDialogModal.props}
          buttonMessage={<FormattedMessage id={translations.buttons.removeMember} />}
          description={
            <FormattedMessage
              id={translations.pages.members.removeMemberHelp}
              values={{
                value: removeMemberDialogModal.props.data?.firstName ?? removeMemberDialogModal.props.data?.email
              }}
            />
          }
          icon={<MinusCircleIcon className="h-10 w-10" />}
          onConfirmation={async (memberId) => {
            await apiClient.audience
              .removeAudienceUsers({
                requestBody: { userIds: [memberId!] },
                resourceId: loadedQuestion!.id
              })
              .then(() => onMembersReload());
          }}
          title={<FormattedMessage id={translations.buttons.removeMember} />}
        />

        <Modal
          icon={deleteIcon}
          {...confirmationModal.props}
          buttonMessage={<FormattedMessage id={translations.buttons.deleteQuestion} />}
          description={<FormattedMessage id={translations.pages.questions.deleteQuestionHelp} />}
          onConfirmation={async () =>
            await apiClient.question
              .deleteQuestion({ questionId: loadedQuestion!.id })
              .then(() => navigate('/questions'))
          }
          title={<FormattedMessage id={translations.buttons.deleteQuestion} />}
        />
      </LoadingOverlay>
    </Layout>
  );
};
