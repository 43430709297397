import { translations } from '@/locales';
import { MagnifyingGlassIcon } from '@heroicons/react/24/outline';
import classnames from 'classnames';
import React from 'react';
import { FormattedMessage } from 'react-intl';

export const NoResultsFound: React.FC<{ className?: string }> = ({ className }) => (
  <div className={classnames('flex h-60 flex-col items-center justify-center', className)}>
    <MagnifyingGlassIcon className="h-9 w-9" />

    <div className="text-sm font-medium text-gray-900">
      <FormattedMessage id={translations.emptyStates.noResultsFound} />
    </div>

    <div className="text-sm font-normal text-gray-500">
      <FormattedMessage id={translations.emptyStates.adjustSearch} />
    </div>
  </div>
);
