import { BenefitPreviewDto } from '@billy/management-api-sdk';
import React from 'react';
import { FormattedDate, FormattedMessage } from 'react-intl';
import { Link as RouterLink } from 'react-router-dom';

import { translations } from '@/locales';

import { Avatar } from '@/components/Avatar';
import { Badge } from '@/components/Badge';
import { Button } from '@/components/Button';
import { EmptyList } from '@/components/EmptyList';
import { NoResultsFound } from '@/components/NoResultsFound';

import { Table, TableBody, TableHeader, TableHeaderColumn, TableRow, TableRowColumn } from './Table';

export type BenefitWithCategoryName = Omit<BenefitPreviewDto, 'categoryId'> & { category?: string };

type Props = {
  benefits?: BenefitWithCategoryName[];
  className?: string;
  noResults?: boolean;
  empty?: boolean;
};

export const BenefitsList: React.FC<Props> = ({ benefits, empty, noResults, className }) => {
  return empty ? (
    <EmptyList
      description={<FormattedMessage id={translations.emptyStates.addBenefits} />}
      title={<FormattedMessage id={translations.emptyStates.noBenefits} />}
    >
      <Button appearance="primary" as={RouterLink} className="flex w-48 justify-center" to="/benefits/new">
        <FormattedMessage id={translations.buttons.addBenefit} />
      </Button>
    </EmptyList>
  ) : (
    <Table {...{ className }}>
      <TableHeader>
        <TableRow>
          <TableHeaderColumn>
            <FormattedMessage id={translations.pages.benefits.benefit} />
          </TableHeaderColumn>

          <TableHeaderColumn>
            <FormattedMessage id={translations.fields.category} />
          </TableHeaderColumn>

          <TableHeaderColumn>
            <FormattedMessage id={translations.fields.description} />
          </TableHeaderColumn>

          <TableHeaderColumn>
            <FormattedMessage id={translations.fields.dateCreated} />
          </TableHeaderColumn>
        </TableRow>
      </TableHeader>

      <TableBody>
        {noResults ? (
          <TableRow>
            <td colSpan={4}>
              <NoResultsFound />
            </td>
          </TableRow>
        ) : (
          benefits?.map((benefit, index) => (
            <RouterLink className="table-row hover:bg-indigo-50" key={index} to={`/benefits/${benefit.id}`}>
              <TableRowColumn>
                <div className="flex items-center gap-4">
                  <Avatar alt="benefit-image" size="lg" src={benefit.image} />
                  <span className="max-h-10 min-w-[200px] overflow-auto">{benefit.name}</span>
                </div>
              </TableRowColumn>

              <TableRowColumn className="whitespace-nowrap">
                <Badge appearance="inactive" className="rounded-sm">
                  {benefit.category}
                </Badge>
              </TableRowColumn>

              <TableRowColumn className="max-h-10 max-w-md truncate">{benefit.description}</TableRowColumn>

              <TableRowColumn>
                <FormattedDate day="2-digit" month="2-digit" value={benefit.createdAt} year="numeric" />
              </TableRowColumn>
            </RouterLink>
          ))
        )}
      </TableBody>
    </Table>
  );
};
