import { useEffect, useRef, useState } from 'react';

export const useClickOutside = <T extends HTMLElement>(
  initialOpenState = false
): { isOpen: boolean; setIsOpen: React.Dispatch<React.SetStateAction<boolean>>; ref: React.RefObject<T> } => {
  const [isOpen, setIsOpen] = useState(initialOpenState);
  const ref = useRef<T>(null);

  const handleClickOutside = (event: MouseEvent) => {
    if (ref.current && !ref.current.contains(event.target as Node)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  return { isOpen, ref, setIsOpen };
};
