import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link as RouterLink } from 'react-router-dom';
import useSWR from 'swr';

import { apiClient } from '@/services/api';

import { translations } from '@/locales';

import { page, search, size, status, useQueryParams } from '@/hooks/useQueryParams';

import { Button } from '@/components/Button';
import { DebouncedInput } from '@/components/Form/DebouncedInput';
import { SearchInput } from '@/components/Form/SearchInput';
import { Select } from '@/components/Form/Select';
import { Layout } from '@/components/Layout';
import { LoadingOverlay } from '@/components/Loading';
import { Pagination } from '@/components/Pagination';
import { CommunitiesList } from '@/components/Table/CommunitiesList';

const config = { page, search, size, status };

export const CommunitiesPage: React.FC = () => {
  const [queryParams, updateQueryParams] = useQueryParams({ config });

  const {
    data,
    isLoading: loading,
    error,
    mutate: onReload
  } = useSWR(
    ['communities/find', queryParams],
    () =>
      apiClient.community.findCommunities({
        pageNumber: queryParams.page,
        pageSize: queryParams.size,
        searchQuery: queryParams.search,
        status: queryParams.status
      }),
    {
      revalidateIfStale: true,
      revalidateOnFocus: true,
      revalidateOnMount: true,
      revalidateOnReconnect: true
    }
  );

  const onReloadCallback = React.useCallback(() => onReload(), [onReload]);

  // The communties are not re-fetched after I suspend a community from community-detail-page and navigate here
  React.useEffect(() => {
    onReload();
  });

  const intl = useIntl();

  const isFilterActive = !!queryParams.status || !!queryParams.search;

  const hasCommunities = data?.page.total !== 0 || isFilterActive;

  const noResultsFound = isFilterActive && data?.page.total === 0;

  return (
    <Layout>
      <div className="flex justify-between gap-4">
        <LoadingOverlay displayLoader {...{ loading }}>
          <div className="text-2xl font-semibold">
            <FormattedMessage id={translations.pages.community.communities} />
          </div>
        </LoadingOverlay>

        {!!hasCommunities && (
          <div className="flex items-center gap-6">
            <DebouncedInput
              as={SearchInput}
              className="w-72"
              initialValue={queryParams.search}
              onChange={(search: string) => updateQueryParams({ page: 1, search })}
              placeholder={intl.formatMessage({ id: translations.pages.community.searchCommunities })}
            />

            <Select
              getSelectedDisplayName={({ selectedItems }) =>
                selectedItems[0]?.name ?? intl.formatMessage({ id: translations.pages.community.allCommunities })
              }
              items={[
                { name: intl.formatMessage({ id: translations.pages.community.allCommunities }), value: undefined },
                {
                  name: intl.formatMessage({ id: translations.pages.community.active }),
                  value: 'active'
                },
                { name: intl.formatMessage({ id: translations.pages.community.inactive }), value: 'inactive' }
              ]}
              onChange={(value: 'active' | 'inactive' | undefined) => updateQueryParams({ page: 1, status: value })}
              placeholder={intl.formatMessage({ id: translations.placeholders.selectStatus })}
              searchable
              value={queryParams.status}
            />

            <Button appearance="primary" as={RouterLink} className="flex w-48 justify-center" to="/communities/new">
              <FormattedMessage id={translations.buttons.createCommunity} />
            </Button>
          </div>
        )}
      </div>

      <div className="space-y-9">
        <LoadingOverlay {...{ error, loading }} className={error ? 'mt-24' : undefined}>
          <CommunitiesList
            className="mt-4"
            communities={data?.items}
            empty={!hasCommunities}
            noResults={noResultsFound}
            {...{ onReload: onReloadCallback }}
          />
        </LoadingOverlay>

        {!!data && hasCommunities && (
          <Pagination
            className="mt-9"
            currentPage={data.page.pageNumber}
            onPageChange={(page: number) => updateQueryParams({ page })}
            pageSize={data.page.pageSize}
            totalItems={data.page.total}
          />
        )}
      </div>
    </Layout>
  );
};
