import * as React from 'react';

import { useImageInputChange } from '@/hooks/useImageInputChange';
import { FileInput, Props as FileInputProps } from './FileInput';

export type ImageValue = { file: File; image: HTMLImageElement };

export type PossibleValue =
  | string // This is an URL (e.g. the detail page is opened and the community already has a logo uploaded that is a URL)
  | File // This occurs once the user selects a file but the image has not be processed
  | ImageValue; // This is the final value, once the user selected a file and the image has been processed

export type Props = Omit<FileInputProps, 'onChange' | 'multiple' | 'children'> & {
  value?: PossibleValue | PossibleValue[];
  onChange(image?: ImageValue): void;
};

export const getSingleValue = (value?: PossibleValue | PossibleValue[]) => (Array.isArray(value) ? value[0] : value);

export const isUrl = (value?: PossibleValue): value is string => typeof value === 'string';

export const getSourceFromValue = (value?: PossibleValue) => {
  if (isUrl(value)) return value;
  if (value instanceof File) return;
  return value?.image?.src;
};

export const getSourceFromPossibleValue = (value?: PossibleValue | PossibleValue[]) =>
  getSourceFromValue(getSingleValue(value));

export const ImageFileInput = React.forwardRef<HTMLInputElement, React.PropsWithChildren<Props>>(
  ({ value, onChange: onChangeCallback, ...rest }, ref) => {
    const onImageUpload = useImageInputChange(onChangeCallback);

    React.useEffect(() => {
      // We can assume we will work with a single file
      const file = getSingleValue(value);

      if (!(file instanceof File)) return;

      onImageUpload(file);
    }, [value, onImageUpload]);

    return <FileInput {...rest} {...{ ref }} onChange={(files) => onImageUpload(files[0])} />;
  }
);
