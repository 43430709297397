import qs, { ParsedQuery } from 'query-string';
import * as React from 'react';
import { useLocation, useNavigate } from 'react-router';

export type QueryParam = string | string[] | undefined;

export type QueryParamDecoder<T> = (value: QueryParam) => T;

export type QueryParamEncoder<T> = (
  value: T
) => string | number | boolean | Array<string | number | boolean> | undefined;

export type QueryParamConfig<T> = {
  decode: QueryParamDecoder<T>;
  encode?: QueryParamEncoder<T>;
};
export type QueryParamsConfig<T> = { [x: string]: QueryParamConfig<T> };

export type QueryParamValues<Config extends QueryParamsConfig<unknown>> = {
  [x in keyof Config]: ReturnType<Config[x]['decode']>;
};

export type QueryParamsOptions<Config extends QueryParamsConfig<unknown>> = {
  config: Config;
};

export type QueryParamsResult<Config extends QueryParamsConfig<unknown>> = [
  QueryParamValues<Config>,
  (paramValues: Partial<QueryParamValues<Config>>) => void,
  (paramValues: Partial<QueryParamValues<Config>>, rawParams?: ParsedQuery<string | number | boolean>) => void
];

const defaultEncoder: QueryParamEncoder<QueryParam> = (value) => value;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const useQueryParams = <Config extends QueryParamsConfig<any>>({
  config
}: QueryParamsOptions<Config>): QueryParamsResult<Config> => {
  const navigate = useNavigate();

  const { search } = useLocation();

  const query = React.useMemo(() => qs.parse(search, { arrayFormat: 'comma' }), [search]);

  const paramValues = React.useMemo(() => {
    return Object.entries(config).reduce((paramValues, [key, { decode }]) => {
      paramValues[key as keyof Config] = decode(query[key] || (undefined as any)) as ReturnType<
        Config[keyof Config]['decode']
      >;
      return paramValues;
    }, {} as QueryParamValues<Config>);
  }, [config, query]);

  const update = React.useCallback(
    (values: Partial<QueryParamValues<Config>>) => {
      const updatedParamValues = { ...paramValues, ...values };

      const updatedQuery = Object.entries(config).reduce<ParsedQuery<string | number | boolean>>(
        (data, [key, { encode = defaultEncoder }]) => {
          data[key] = encode(updatedParamValues[key]) as any;
          return data;
        },
        { ...query }
      );
      navigate(
        { search: qs.stringify(updatedQuery, { arrayFormat: 'comma' }) },
        { preventScrollReset: true, replace: true }
      );
    },
    [config, navigate, query, paramValues]
  );

  const stringifyQueryParams = React.useCallback(
    (values: Partial<QueryParamValues<Config>>, rawParams?: ParsedQuery<string | number | boolean>) => {
      const updatedQuery = Object.entries(config).reduce<ParsedQuery<string | number | boolean>>(
        (data, [key, { encode = defaultEncoder }]) => {
          data[key] = encode(values[key]) as any;
          return data;
        },
        { ...rawParams }
      );
      return qs.stringify(updatedQuery, { arrayFormat: 'comma' });
    },
    [config]
  );
  return [paramValues, update, stringifyQueryParams];
};

export const decodeArrayQueryParam = <T>(value: QueryParam, fallback: T) => {
  return Array.isArray(value) ? value : value !== undefined ? [value] : fallback;
};

export const decodeSingleQueryParam = <T>(value: QueryParam, fallback: T) => {
  return Array.isArray(value) ? value[0] : value || fallback;
};

export const decodeNumber = <T>(value: QueryParam, fallback: T) => {
  const unwrapped = decodeSingleQueryParam(value, '');
  const parsed = parseInt(unwrapped, 10);
  return Number.isFinite(parsed) ? parsed : fallback;
};

export const decodeBoolean = <T>(value: QueryParam, fallback: T) => {
  const unwrapped = decodeSingleQueryParam(value, '');
  return unwrapped === 'true' ? true : unwrapped === 'false' ? false : fallback;
};

export const search: QueryParamConfig<string | undefined> = {
  decode: (value) => decodeSingleQueryParam(value, undefined),
  encode: (value) => (value ? value : undefined)
};

export const page: QueryParamConfig<number | 1> = {
  decode: (value) => decodeNumber(value, 1),
  encode: (value) => (value ? value : 1)
};

export const size: QueryParamConfig<number | 10> = {
  decode: (value) => decodeNumber(value, 10),
  encode: (value) => (value ? value : undefined)
};

export const status: QueryParamConfig<'active' | 'inactive' | undefined> = {
  decode: (value) => {
    value = decodeSingleQueryParam(value, undefined);
    if (value === 'active') return 'active';
    if (value === 'inactive') return 'inactive';
    return undefined;
  },
  encode: (value) => (value ? value : undefined)
};

export const time: QueryParamConfig<'upcoming' | 'past' | undefined> = {
  decode: (value) => {
    value = decodeSingleQueryParam(value, undefined);
    if (value === 'upcoming') return 'upcoming';
    if (value === 'past') return 'past';
    return undefined;
  },
  encode: (value) => (value ? value : undefined)
};

export const category: QueryParamConfig<string | undefined> = {
  decode: (value) => decodeSingleQueryParam(value, undefined),
  encode: (value) => (value ? value : undefined)
};
