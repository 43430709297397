import classnames from 'classnames';
import React from 'react';

const styles = {
  active: 'bg-green-100 text-green-800 rounded-full',
  group: 'bg-indigo-100 text-indigo-800 text-sm rounded-[4px]',
  inactive: 'bg-neutral-100 text-neutral-800 rounded-full'
};

export type Appearance = keyof typeof styles;

type Props = {
  appearance: Appearance;
  className?: string;
};
export const Badge: React.FC<React.PropsWithChildren<Props>> = ({ appearance, children, className }) => {
  return (
    <span className={classnames('px-3 py-0.5 text-xs font-medium', styles[appearance], className)}>{children}</span>
  );
};
