import { PlusCircleIcon } from '@heroicons/react/24/outline';
import classnames from 'classnames';
import React from 'react';

type Props = {
  title: React.ReactNode;
  description?: React.ReactNode;
  className?: string;
};

export const EmptyList: React.FC<React.PropsWithChildren<Props>> = ({ className, title, description, children }) => {
  return (
    <div className={classnames('flex flex-col items-center justify-center', className)}>
      <PlusCircleIcon className="w-12 text-gray-400" />

      <h3 className="mt-2 text-sm font-medium text-gray-900">{title}</h3>

      {!!description && <p className="mt-1 text-sm text-gray-500">{description}</p>}

      <div className="mt-6">{children}</div>
    </div>
  );
};
