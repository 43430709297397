import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link as RouterLink } from 'react-router-dom';
import * as yup from 'yup';

import { translations } from '@/locales';

import { Button } from '@/components/Button';
import { CenteredBoxLayout } from '@/components/CenteredBoxLayout';
import { Form } from '@/components/Form';
import { InputWithLabel } from '@/components/Form/InputWithLabel';
import { ValidatedField } from '@/components/Form/ValidatedField';
import { Link } from '@/components/Link';
import { LoadingButton } from '@/components/LoadingButton';
import { SubmitFeedback } from '@/components/SubmitFeedback';
import { authApiClient } from '@/services/auth-api';

export const schema = yup
  .object({
    email: yup.string().trim().email().required().label(translations.fields.emailAddress)
  })
  .required();

export const ForgotPasswordPage: React.FC = () => {
  const onSubmit = React.useCallback(
    async (values: yup.InferType<typeof schema>) =>
      await authApiClient.authentication.initiateResetPassword({ requestBody: values }),
    []
  );

  return (
    <CenteredBoxLayout
      subhead={<FormattedMessage id={translations.pages.forgotPassword.subhead} />}
      title={<FormattedMessage id={translations.pages.forgotPassword.title} />}
    >
      <Form {...{ onSubmit, schema }}>
        {({ submitting, submitError, submitSucceeded, dirtySinceLastSubmit }) => (
          <div className="space-y-6">
            <ValidatedField
              field={InputWithLabel}
              id="email"
              label={<FormattedMessage id={translations.fields.emailAddress} />}
              name="email"
              readOnly={submitting}
              type="email"
            />

            <Button appearance="primary" as={LoadingButton} className="w-full" loading={submitting} type="submit">
              <FormattedMessage id={translations.pages.forgotPassword.cta} />
            </Button>

            <SubmitFeedback
              {...{ submitError, submitSucceeded }}
              show={!submitting && !dirtySinceLastSubmit}
              successMessage={<FormattedMessage id={translations.pages.forgotPassword.submitted} />}
            />

            <div className="mt-4 flex justify-center">
              <Link as={RouterLink} className="text-indigo-600" to="/login">
                <FormattedMessage id={translations.pages.forgotPassword.toLogin} />
              </Link>
            </div>
          </div>
        )}
      </Form>
    </CenteredBoxLayout>
  );
};
