import classnames from 'classnames';
import React from 'react';

type Props = {
  className?: string;
};

export const ModalLayout: React.FC<React.PropsWithChildren<Props>> = ({ className, ...props }) => (
  <div {...props} className={classnames('w-full max-w-lg space-y-6 rounded-lg p-6', className)} />
);
