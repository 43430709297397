import classnames from 'classnames';
import React from 'react';

type Props = React.PropsWithChildren<{ className?: string }>;

export const Table: React.FC<Props> = ({ className, ...props }) => {
  return (
    <div
      {...props}
      className={classnames(
        'table w-full divide-y divide-gray-200 overflow-x-auto rounded-md border-b border-gray-200 shadow',
        className
      )}
    />
  );
};

export const TableHeader: React.FC<Props> = ({ className, ...rest }) => {
  return <div {...rest} className={classnames('table-header-group bg-gray-100', className)} />;
};

export const TableHeaderColumn: React.FC<Props> = ({ className, ...rest }) => {
  return (
    <div
      {...rest}
      className={classnames(
        'table-cell whitespace-nowrap px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-700',
        className
      )}
    />
  );
};

export const TableBody: React.FC<Props> = ({ className, ...rest }) => {
  return <div {...rest} className={classnames('table-row-group divide-y', className)} />;
};

export const TableRow: React.FC<Props> = ({ className, ...rest }) => {
  return <div {...rest} className={classnames('table-row hover:bg-indigo-50', className)} />;
};

export const TableRowColumn: React.FC<React.HTMLProps<HTMLDivElement> & Props> = ({ className, ...rest }) => {
  return (
    <div
      {...rest}
      className={classnames('table-cell px-6 py-3 align-middle text-sm font-normal text-gray-900', className)}
    />
  );
};
