import { MinusCircleIcon } from '@heroicons/react/24/outline';
import { ChevronRightIcon, PhotoIcon } from '@heroicons/react/24/solid';
import classnames from 'classnames';
import React from 'react';
import { FormattedDate, FormattedMessage, useIntl } from 'react-intl';
import { Link as RouterLink, useNavigate, useParams } from 'react-router-dom';
import useSWR from 'swr';
import * as yup from 'yup';

import { apiClient } from '@/services/api';

import { translations } from '@/locales';

import { getDateFromString, uploadFile } from '@/utils/functions';

import { useModal } from '@/hooks/useModal';
import { useRole } from '@/hooks/useRole';

import { Badge } from '@/components/Badge';
import { Button } from '@/components/Button';
import { EmptyList } from '@/components/EmptyList';
import { Form } from '@/components/Form';
import { CurrencyInput } from '@/components/Form/CurrencyInput';
import { convertToDateObject, DateInput } from '@/components/Form/DateInput';
import { DragAndDropFile } from '@/components/Form/DragAndDropFile';
import { getSourceFromPossibleValue, ImageFileInput, ImageValue } from '@/components/Form/ImageFileInput';
import { InputWithLabel } from '@/components/Form/InputWithLabel';
import { MaxLengthIndicator } from '@/components/Form/MaxLengthIndicator';
import { TextArea } from '@/components/Form/TextArea';
import { ValidatedField } from '@/components/Form/ValidatedField';
import { Layout } from '@/components/Layout';
import { Link } from '@/components/Link';
import { LoadingIndicator, LoadingOverlay } from '@/components/Loading';
import { LoadingButton } from '@/components/LoadingButton';
import { Modal } from '@/components/Modal';
import { cancelIcon, ConfirmationModal, deleteIcon } from '@/components/Modal/ConfirmationModal';
import { SelectMembersModal } from '@/components/Modal/SelectMembersModal';
import { Pagination } from '@/components/Pagination';
import { MemberProfile } from '@/components/Profile';
import { SubmitFeedback } from '@/components/SubmitFeedback';
import { Table, TableBody, TableHeader, TableHeaderColumn, TableRow, TableRowColumn } from '@/components/Table/Table';
import { page, size, useQueryParams } from '@/hooks/useQueryParams';

const maxSizeInMB = 10;
const minImageWidth = 2160;
const minImageHeight = 1024;

export const schema = yup.object({
  date: yup.string().required().label(translations.fields.date),
  description: yup.string().trim().max(800).required().label(translations.fields.description),
  image: yup
    .mixed()
    .test({
      exclusive: true,
      message: translations.customValidation.maxFileSize,
      name: 'maxFileSize',
      params: { max: maxSizeInMB },
      test: (value) => typeof value === 'string' || !value?.file || value.file.size <= maxSizeInMB * 1024 * 1024
    })
    .test({
      exclusive: true,
      message: translations.customValidation.minImageResolution,
      name: 'minImageResolution',
      params: { height: minImageHeight, width: minImageWidth },
      test: (value) =>
        typeof value === 'string' ||
        !value?.file ||
        (value.image.width >= minImageWidth && value.image.height >= minImageHeight)
    })
    .required()
    .label(translations.fields.coverImage),
  location: yup.string().trim().required().label(translations.fields.location),
  message: yup.string().trim().max(800).required().label(translations.fields.messageContent),
  name: yup.string().trim().required().label(translations.fields.name),
  options: yup
    .array()
    .of(
      yup
        .object({
          name: yup.string().trim().required().label(translations.fields.pricingOption),
          price: yup.number().min(0).max(10000).required().label(translations.fields.price)
        })
        .required()
    )
    .required(),
  termsAndConditions: yup
    .string()
    .url()
    .matches(/^https:\/\/.*/, translations.customValidation.validHttps)
    .label(translations.fields.link),
  time: yup.string().required().label(translations.fields.time)
});

const config = { page, size };

export const EventDetailsPage: React.FC = () => {
  const { community } = useRole();

  const { eventId } = useParams();

  const navigate = useNavigate();

  const [queryParams, updateQueryParams] = useQueryParams({ config });

  const isNew = !eventId;

  const {
    data: loadedEvent,
    isLoading: loadingEvent,
    error: eventtError,
    mutate: onReload
  } = useSWR(
    isNew ? null : ['events/get-details', eventId],
    () =>
      apiClient.event.getEventDetails({ eventId: eventId! }).then((e) => ({
        ...e,
        options: e.options.map((o) => ({ ...o, price: { ...o.price, amount: o.price.amount / 100 } }))
      })),
    { revalidateIfStale: true, revalidateOnFocus: false, revalidateOnMount: true, revalidateOnReconnect: false }
  );

  const {
    data: loadedMembers,
    isLoading: loadingMembers,
    error: membersError,
    mutate: onMembersReload
  } = useSWR(
    isNew ? null : ['events/find-audience-members', queryParams],
    () =>
      apiClient.event.findEventAudienceMembers({
        eventId: eventId!,
        pageNumber: queryParams.page,
        pageSize: queryParams.size
      }),
    { revalidateIfStale: true, revalidateOnFocus: false, revalidateOnMount: true, revalidateOnReconnect: false }
  );

  const messages = useSWR(
    isNew ? null : 'messages/find-event-messages',
    () => apiClient.message.findMessages({ communityId: community?.id, resource: eventId, resourceType: 'event' }),
    { revalidateIfStale: true, revalidateOnFocus: false, revalidateOnMount: true, revalidateOnReconnect: false }
  );

  const eventImageUpload = React.useCallback(async (image: string | ImageValue, communityId: string) => {
    if (typeof image === 'string') return image;
    else {
      const intent = await apiClient.event.initiateEventImageUpload({
        communityId,
        requestBody: { mimeType: image.file.type }
      });

      await uploadFile(intent.upload.url, intent.upload.headers, image.file);

      return intent.asset;
    }
  }, []);

  const onSubmit = React.useCallback(
    async (values: yup.InferType<typeof schema>) => {
      if (!isNew) return;

      const image = await eventImageUpload(values.image, community!.id);

      const dateToSend = convertToDateObject(values.date);

      dateToSend.setHours(parseInt(values.time.split(':')[0]), parseInt(values.time.split(':')[1]));

      await apiClient.event
        .createEvent({
          communityId: community!.id,
          requestBody: {
            ...values,
            image,
            options: values.options.map((o) => ({ ...o, price: o.price * 100 })),
            time: dateToSend.toISOString()
          }
        })
        .then((event) => {
          navigate(`/events/${event.id}`);
        });
    },
    [community, eventImageUpload, isNew, navigate]
  );

  const confirmationModal = useModal(ConfirmationModal);

  const removeMemberDialogModal = useModal(ConfirmationModal);

  const selectMembersModal = useModal(SelectMembersModal);

  const initialValues: Partial<yup.InferType<typeof schema>> = isNew
    ? { options: [{ name: '', price: undefined as unknown as number }] }
    : {
        ...loadedEvent,
        date: getDateFromString(loadedEvent?.time ?? '') as unknown as string,
        message: messages.data?.items[0]?.content,
        options: loadedEvent?.options.map(({ name, price }) => ({ name, price: price.amount })) ?? [],
        time: getDateFromString(loadedEvent?.time ?? '') as unknown as string
      };

  const members = loadedMembers?.items ?? [];

  const intl = useIntl();

  return (
    <Layout>
      <LoadingOverlay {...{ error: eventtError, loading: loadingEvent }}>
        <div className="flex items-center gap-5">
          <Link as={RouterLink} className="text-sm font-medium text-gray-500" to="/events">
            <FormattedMessage id={translations.pages.events.events} />
          </Link>

          <ChevronRightIcon className="w-5 text-sm text-gray-400" />

          <Link as={RouterLink} className="max-w-[12rem] truncate text-sm font-medium text-gray-500" to="#">
            {initialValues?.name || <FormattedMessage id={translations.pages.events.newEvent} />}
          </Link>
        </div>

        <Form className="mt-5 flex flex-col justify-between space-y-6" {...{ initialValues, onSubmit, schema }}>
          {({ submitting, form, values, dirtySinceLastSubmit, submitError, submitSucceeded }) => (
            <>
              <div>
                <div className="flex items-center justify-between text-2xl font-semibold">
                  {!isNew ? (
                    <div className="flex items-center gap-4">
                      {values?.name?.trim() || initialValues?.name?.trim()}

                      {!!loadedEvent?.canceledAt && (
                        <Badge appearance="inactive" className="text-sm font-medium uppercase">
                          <FormattedMessage id={translations.statuses.cancelled} />
                        </Badge>
                      )}

                      {(!!loadingEvent || !!loadingMembers) && <LoadingIndicator />}
                    </div>
                  ) : (
                    values?.name?.trim() || <FormattedMessage id={translations.pages.events.newEvent} />
                  )}

                  {!isNew && (
                    <Button
                      appearance={loadedEvent?.canceledAt ? 'deleteSecondary' : 'basic'}
                      onClick={() => confirmationModal.open().catch(() => null)}
                      type="button"
                    >
                      <span className="whitespace-nowrap">
                        {loadedEvent?.canceledAt ? (
                          <FormattedMessage id={translations.buttons.deleteEvent} />
                        ) : (
                          <FormattedMessage id={translations.buttons.cancelEvent} />
                        )}
                      </span>
                    </Button>
                  )}
                </div>

                {!isNew && (
                  <div className="text-sm font-normal text-gray-500">
                    <FormattedMessage id={translations.fields.createdOn} />{' '}
                    <FormattedDate day="2-digit" month="2-digit" value={loadedEvent?.createdAt} year="numeric" />
                    {!!loadedEvent?.canceledAt && (
                      <>
                        {' '}
                        • <FormattedMessage id={translations.fields.cancelledOn} />{' '}
                        <FormattedDate day="2-digit" month="2-digit" value={loadedEvent?.canceledAt} year="numeric" />
                      </>
                    )}
                  </div>
                )}
              </div>

              <div className="max-w-xl space-y-10">
                <div className="space-y-6">
                  <div className="text-lg font-medium text-gray-900">
                    {<FormattedMessage id={translations.pages.pageSections.generalInformation} />}
                  </div>

                  <div className="flex gap-3">
                    <ValidatedField
                      accept="image/png,image/jpeg"
                      as={ImageFileInput}
                      disabled={!!submitting || !isNew}
                      field={InputWithLabel}
                      id="image"
                      label={<FormattedMessage id={translations.fields.coverImage} />}
                      name="image"
                    >
                      <DragAndDropFile
                        className={isNew ? 'cursor-pointer' : undefined}
                        onChange={(files) => form.change('image', files)}
                      >
                        {({ dragging }) => (
                          <div
                            className={classnames(
                              'flex h-80 w-[576px] flex-col',
                              'items-center justify-center rounded-md border-dashed border-gray-300',
                              { 'border-2': !values?.image },
                              dragging ? 'bg-gray-100' : 'bg-white'
                            )}
                          >
                            {values.image ? (
                              <img
                                alt="card-preview"
                                className="h-full w-full cursor-pointer rounded-md object-cover"
                                src={getSourceFromPossibleValue(values.image)}
                              />
                            ) : (
                              <>
                                <PhotoIcon className="h-12 w-12 text-gray-400" />

                                <div className="text-indigo-600">
                                  <FormattedMessage id={translations.fields.uploadImage} />
                                </div>

                                <div className="text-xs font-normal leading-4 text-gray-500">
                                  <FormattedMessage
                                    id={translations.pages.community.imageValidation}
                                    values={{ ...{ maxSizeInMB, minImageHeight, minImageWidth } }}
                                  />
                                </div>
                              </>
                            )}
                          </div>
                        )}
                      </DragAndDropFile>
                    </ValidatedField>

                    {!!values.image && isNew && (
                      <Button
                        appearance="basic"
                        className="self-end"
                        onClick={() => {
                          form.change('image', undefined);
                        }}
                        type="button"
                      >
                        <FormattedMessage id={translations.buttons.remove} />
                      </Button>
                    )}
                  </div>

                  <ValidatedField
                    field={InputWithLabel}
                    id="name"
                    label={<FormattedMessage id={translations.pages.events.eventName} />}
                    name="name"
                    readOnly={!!submitting || !isNew}
                  />

                  <MaxLengthIndicator max={800} name="description">
                    <ValidatedField
                      as={TextArea}
                      field={InputWithLabel}
                      fieldClassName="h-36 min-h-[6rem]"
                      id="description"
                      label={<FormattedMessage id={translations.fields.description} />}
                      name="description"
                      readOnly={!!submitting || !isNew}
                    />
                  </MaxLengthIndicator>

                  <ValidatedField
                    field={InputWithLabel}
                    help={<FormattedMessage id={translations.pages.events.locationHelper} />}
                    id="location"
                    label={<FormattedMessage id={translations.fields.location} />}
                    name="location"
                    readOnly={!!submitting || !isNew}
                  />

                  <div className="flex justify-between gap-4">
                    {
                      <ValidatedField
                        as={DateInput}
                        className="w-full"
                        field={InputWithLabel}
                        fieldClassName={classnames(
                          'w-full rounded-md border border-gray-300 py-2 px-2 text-gray-900 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm',
                          (!!submitting || !isNew) && 'bg-gray-100 text-gray-400'
                        )}
                        id="date"
                        label={<FormattedMessage id={translations.fields.date} />}
                        mask="DD/MM/YYYY"
                        name="date"
                        placeholder={intl.formatMessage({ id: translations.placeholders.date })}
                        readOnly={!!submitting || !isNew}
                      />
                    }

                    {
                      <ValidatedField
                        as={DateInput}
                        className="w-full"
                        field={InputWithLabel}
                        fieldClassName={classnames(
                          'w-full rounded-md border border-gray-300 py-2 px-2 text-gray-900 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm',
                          (!!submitting || !isNew) && 'bg-gray-100 text-gray-400'
                        )}
                        label={<FormattedMessage id={translations.fields.time} />}
                        mask="HH:mm"
                        name="time"
                        placeholder={intl.formatMessage({ id: translations.placeholders.time })}
                        readOnly={!!submitting || !isNew}
                      />
                    }
                  </div>

                  <ValidatedField
                    field={InputWithLabel}
                    id="termsAndConditions"
                    label={
                      <>
                        <FormattedMessage id={translations.fields.termsAndConditions} />{' '}
                        <span className="text-sm font-medium text-gray-400">
                          ({intl.formatMessage({ id: translations.customValidation.optional }).toLowerCase()})
                        </span>
                      </>
                    }
                    name="termsAndConditions"
                    placeholder={intl.formatMessage({ id: translations.placeholders.link })}
                    readOnly={!!submitting || !isNew}
                  />
                </div>

                <div className="space-y-6">
                  <div className="text-lg font-medium text-gray-900">
                    {<FormattedMessage id={translations.pages.events.eventPrice} />}
                  </div>

                  <div className="space-y-6">
                    {values.options?.map((_, index) => (
                      <div className="group flex items-center gap-4" key={index}>
                        <ValidatedField
                          className="w-full"
                          field={InputWithLabel}
                          id="options"
                          label={<FormattedMessage id={translations.fields.pricingOption} />}
                          name={`options.${index}.name`}
                          placeholder={intl.formatMessage({ id: translations.placeholders.pricingOption })}
                          readOnly={!!submitting || !isNew}
                        />

                        <ValidatedField
                          as={CurrencyInput}
                          className="w-full"
                          currency={community!.currency}
                          field={InputWithLabel}
                          fieldClassName={classnames(
                            'w-full rounded-md border border-gray-300 py-2 px-2 text-gray-900 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm',
                            (!!submitting || !isNew) && 'bg-gray-100 text-gray-400'
                          )}
                          id="price"
                          label={<FormattedMessage id={translations.fields.price} />}
                          name={`options.${index}.price`}
                          placeholder={intl.formatMessage(
                            { id: translations.placeholders.price },
                            { currency: community!.currency }
                          )}
                          readOnly={!!submitting || !isNew}
                        />

                        {!!isNew && (
                          <Button
                            appearance="basic"
                            className="w-48 self-end opacity-0 group-hover:opacity-100"
                            onClick={() => {
                              form.change(
                                'options',
                                values.options?.filter((_, i) => i !== index)
                              );
                            }}
                            type="button"
                          >
                            <FormattedMessage id={translations.buttons.remove} />
                          </Button>
                        )}
                      </div>
                    ))}
                  </div>

                  {!!isNew && (
                    <Button
                      appearance="secondary"
                      className="mt-2 w-48"
                      onClick={() => {
                        form.change('options', [
                          ...values.options,
                          { name: undefined as unknown as string, price: undefined as unknown as number }
                        ]);
                      }}
                      type="button"
                    >
                      <FormattedMessage id={translations.buttons.addOption} />
                    </Button>
                  )}
                </div>

                <div className="space-y-6">
                  <div className="text-lg font-medium text-gray-900">
                    {<FormattedMessage id={translations.pages.messages.message} />}
                  </div>

                  <MaxLengthIndicator max={800} name="message">
                    <ValidatedField
                      as={TextArea}
                      field={InputWithLabel}
                      fieldClassName="h-36 min-h-[6rem]"
                      help={<FormattedMessage id={translations.pages.events.messageHelper} />}
                      id="message"
                      label={<FormattedMessage id={translations.pages.messages.message} />}
                      name="message"
                      readOnly={!!submitting || !isNew}
                    />
                  </MaxLengthIndicator>
                </div>
              </div>

              {!isNew && (
                <>
                  <LoadingOverlay {...{ error: membersError, loading: loadingMembers }}>
                    <div className="space-y-4">
                      <div className="flex justify-between text-lg">
                        <FormattedMessage id={translations.pages.pageSections.audience} />

                        {members?.length > 0 && (
                          <Button
                            appearance="secondary"
                            className="w-32"
                            onClick={() => {
                              selectMembersModal.open().catch(() => null);
                            }}
                            type="button"
                          >
                            <FormattedMessage id={translations.buttons.addMembers} />
                          </Button>
                        )}
                      </div>

                      {members?.length > 0 ? (
                        <Table>
                          <TableHeader>
                            <TableRow>
                              <TableHeaderColumn>
                                <FormattedMessage id={translations.fields.name} />
                              </TableHeaderColumn>

                              <TableHeaderColumn>
                                <FormattedMessage id={translations.fields.status} />
                              </TableHeaderColumn>

                              <TableHeaderColumn />
                            </TableRow>
                          </TableHeader>

                          <TableBody>
                            {members.map((member, index) => (
                              <TableRow key={index}>
                                <TableRowColumn>
                                  <MemberProfile
                                    email={member.email}
                                    firstName={member.firstName}
                                    lastName={member.lastName}
                                    src={member.image}
                                  />
                                </TableRowColumn>

                                <TableRowColumn>
                                  <Badge appearance={member.enrolled ? 'active' : 'inactive'}>
                                    {member.enrolled ? 'Enrolled' : 'Not Enrolled'}
                                  </Badge>
                                </TableRowColumn>

                                <TableRowColumn className="relative">
                                  <Button
                                    className="absolute right-8 -translate-y-1/2 transform shadow-none"
                                    onClick={() => {
                                      removeMemberDialogModal.open(member).catch(() => null);
                                    }}
                                  >
                                    <MinusCircleIcon className="h-6 w-6" />
                                  </Button>
                                </TableRowColumn>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      ) : (
                        <EmptyList
                          description={<FormattedMessage id={translations.emptyStates.addMembers} />}
                          title={<FormattedMessage id={translations.emptyStates.noMembers} />}
                        >
                          <Button
                            appearance="secondary"
                            className="w-32"
                            onClick={() => {
                              selectMembersModal.open().catch(() => null);
                            }}
                            type="button"
                          >
                            <FormattedMessage id={translations.buttons.addMembers} />
                          </Button>
                        </EmptyList>
                      )}

                      <Modal
                        {...selectMembersModal.props}
                        onInvite={async (groupIds, userIds) => {
                          await apiClient.audience
                            .addAudienceUser({
                              requestBody: { groupIds, userIds },
                              resourceId: loadedEvent!.id
                            })
                            .then(() => {
                              onMembersReload();
                            });
                        }}
                      />
                    </div>
                  </LoadingOverlay>

                  {!!loadedMembers && members.length > 0 && (
                    <Pagination
                      className="mt-9"
                      currentPage={loadedMembers.page.pageNumber}
                      onPageChange={(page: number) => updateQueryParams({ page })}
                      pageSize={loadedMembers.page.pageSize}
                      totalItems={loadedMembers.page.total}
                    />
                  )}

                  <Modal
                    icon={loadedEvent?.canceledAt ? deleteIcon : cancelIcon}
                    {...confirmationModal.props}
                    buttonMessage={
                      <FormattedMessage
                        id={
                          loadedEvent?.canceledAt ? translations.buttons.deleteEvent : translations.buttons.cancelEvent
                        }
                      />
                    }
                    description={
                      <FormattedMessage
                        id={
                          loadedEvent?.canceledAt
                            ? translations.pages.events.deleteEventHelper
                            : translations.pages.events.cancelEventHelper
                        }
                      />
                    }
                    onConfirmation={async () => {
                      loadedEvent?.canceledAt
                        ? await apiClient.event
                            .deleteEvent({ eventId: loadedEvent!.id })
                            .then(() => navigate('/events'))
                        : await apiClient.event.cancelEvent({ eventId: loadedEvent!.id }).then(() => onReload());
                    }}
                    title={
                      <FormattedMessage
                        id={
                          loadedEvent?.canceledAt ? translations.buttons.deleteEvent : translations.buttons.cancelEvent
                        }
                      />
                    }
                  />
                </>
              )}

              {!!isNew && (
                <div className="flex items-center gap-6">
                  <Button appearance="basic" className="mt-6 w-48" onClick={() => form.restart()} type="button">
                    <FormattedMessage id={translations.buttons.cancel} />
                  </Button>

                  <Button
                    appearance="primary"
                    as={LoadingButton}
                    className="mt-6 w-48"
                    loading={submitting}
                    type="submit"
                  >
                    <FormattedMessage id={isNew ? translations.buttons.save : translations.buttons.send} />
                  </Button>

                  <SubmitFeedback {...{ submitError, submitSucceeded }} show={!submitting && !dirtySinceLastSubmit} />
                </div>
              )}
            </>
          )}
        </Form>
      </LoadingOverlay>

      <Modal
        {...removeMemberDialogModal.props}
        buttonMessage={<FormattedMessage id={translations.buttons.removeMember} />}
        description={
          <FormattedMessage
            id={translations.pages.members.removeMemberHelp}
            values={{
              value: removeMemberDialogModal.props.data?.firstName ?? removeMemberDialogModal.props.data?.email
            }}
          />
        }
        icon={<MinusCircleIcon className="h-10 w-10" />}
        onConfirmation={async (memberId) => {
          await apiClient.audience
            .removeAudienceUsers({
              requestBody: { userIds: [memberId!] },
              resourceId: loadedEvent!.id
            })
            .then(() => onMembersReload());
        }}
        title={<FormattedMessage id={translations.buttons.removeMember} />}
      />
    </Layout>
  );
};
