import React from 'react';
import { createRoot } from 'react-dom/client';
import { IntlProvider } from 'react-intl';
import { BrowserRouter } from 'react-router-dom';

import 'tailwindcss/tailwind.css';

import { Language } from '@/constants';
import { getTranslationMessages } from '@/locales';

import { Auth } from '@/containers/use-auth';

import { App } from './App';

const root = createRoot(document.getElementById('app')!);

root.render(
  <IntlProvider
    defaultRichTextElements={{ br: (<br />) as any }}
    locale={Language.English}
    messages={getTranslationMessages(Language.English)}
  >
    <BrowserRouter>
      <Auth.Provider>
        <App />
      </Auth.Provider>
    </BrowserRouter>
  </IntlProvider>
);
