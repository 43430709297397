import { ChevronRightIcon } from '@heroicons/react/24/solid';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import * as yup from 'yup';

import { apiClient } from '@/services/api';

import { translations } from '@/locales';

import { Button } from '@/components/Button';
import { Form } from '@/components/Form';
import { InputWithLabel } from '@/components/Form/InputWithLabel';
import { ValidatedField } from '@/components/Form/ValidatedField';
import { Layout } from '@/components/Layout';
import { Link } from '@/components/Link';
import { LoadingButton } from '@/components/LoadingButton';
import { SubmitFeedback } from '@/components/SubmitFeedback';

export const schema = yup
  .object({
    name: yup.string().required().label(translations.fields.communityName)
  })
  .required();

export const CreateCommunityPage: React.FC = () => {
  const navigate = useNavigate();

  const onSubmit = React.useCallback(
    async (values: yup.InferType<typeof schema>) => {
      const community = await apiClient.community.createCommunity({ requestBody: values });

      navigate(`/communities/${community?.id}`);
    },
    [navigate]
  );

  return (
    <Layout>
      <div className="flex">
        <Link as={RouterLink} className="text-sm font-medium text-gray-500" to="/communities">
          <FormattedMessage id={translations.pages.community.communities} />
        </Link>

        <ChevronRightIcon className="w-5 text-gray-400" />

        <Link as={RouterLink} className="text-sm font-medium text-gray-500" to="#">
          <FormattedMessage id={translations.pages.community.newCommunity} />
        </Link>
      </div>

      <div className="text-2xl font-semibold">
        <FormattedMessage id={translations.pages.community.newCommunity} />
      </div>

      <Form className="h-full" {...{ onSubmit, schema }}>
        {({ submitting, form, submitError, submitSucceeded, dirtySinceLastSubmit }) => {
          return (
            <div className="flex h-full flex-col space-y-10">
              <div className="space-y-10">
                <div className="space-y-4">
                  <div className="text-large font-medium text-gray-900">
                    <FormattedMessage id={translations.pages.pageSections.generalInformation} />
                  </div>

                  <ValidatedField
                    field={InputWithLabel}
                    fieldClassName="w-1/2 min-w-[20rem]"
                    id="name"
                    label={<FormattedMessage id={translations.fields.communityName} />}
                    name="name"
                    readOnly={submitting}
                  />
                </div>
              </div>

              <div className="flex gap-6">
                <Button appearance="basic" className="mt-6 w-48" onClick={() => form.restart()} type="button">
                  <FormattedMessage id={translations.buttons.cancel} />
                </Button>

                <Button
                  appearance="primary"
                  as={LoadingButton}
                  className="mt-6 w-48"
                  loading={submitting}
                  type="submit"
                >
                  <FormattedMessage id={translations.buttons.save} />
                </Button>

                <SubmitFeedback {...{ submitError, submitSucceeded }} show={!submitting && !dirtySinceLastSubmit} />
              </div>
            </div>
          );
        }}
      </Form>
    </Layout>
  );
};
