/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum BulkMembersUploadStatus {
  COMPLETED = 'completed',
  SKIPPED = 'skipped',
  FAILED = 'failed',
}
