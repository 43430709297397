import React from 'react';

import { As, AsComponent } from '@/components/As';

import { Input, Props as InputProps } from './Input';

export type Props<T extends AsComponent> = InputProps<T> & {
  id?: string;
  help?: React.ReactNode;
  optional: boolean;
};

export const InputWithLabel = React.forwardRef(function InputWithLabel<T extends AsComponent>(
  //@ts-ignore
  { as = Input, label, help, className, optional, ...props }: Props<T>, // <-- defaults to Input if no `as` prop was given
  ref: React.Ref<any>
) {
  return (
    <div className="flex flex-col space-y-1">
      <label className="text-sm font-medium leading-5 text-gray-700" htmlFor={props.id}>
        {label}
      </label>

      {!!help && (
        <label className="text-sm font-normal leading-5 text-gray-500" htmlFor={props.id}>
          {help}
        </label>
      )}

      <As {...props} {...{ as, className, ref }} />
    </div>
  );
});
