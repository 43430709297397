import { ChevronRightIcon } from '@heroicons/react/24/solid';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import * as yup from 'yup';

import { apiClient } from '@/services/api';

import { translations } from '@/locales';

import { useRole } from '@/hooks/useRole';

import { Button } from '@/components/Button';
import { Form } from '@/components/Form';
import { InputWithLabel } from '@/components/Form/InputWithLabel';
import { ValidatedField } from '@/components/Form/ValidatedField';
import { Layout } from '@/components/Layout';
import { Link } from '@/components/Link';
import { LoadingButton } from '@/components/LoadingButton';
import { SubmitFeedback } from '@/components/SubmitFeedback';

export const schema = yup
  .object({
    name: yup.string().trim().required().label(translations.fields.groupName)
  })
  .required();

export const CreateGroupPage: React.FC = () => {
  const navigate = useNavigate();
  const { community, isAdmin } = useRole();

  const onSubmit = React.useCallback(
    async (values: yup.InferType<typeof schema>) => {
      await apiClient.group
        .createGroup({
          communityId: isAdmin ? undefined : community?.id,

          //I should not provide any members when the group is created
          requestBody: { members: {}, name: values.name }
        })
        .then((group) => navigate(`/groups/${group.id}`));
    },
    [community?.id, isAdmin, navigate]
  );

  const intl = useIntl();

  return (
    <Layout>
      <div className="flex items-center gap-5">
        <Link as={RouterLink} className="text-sm font-medium text-gray-500" to="/groups">
          <FormattedMessage id={translations.pages.groups.groups} />
        </Link>

        <ChevronRightIcon className="w-5 text-sm text-gray-400" />

        <Link as={RouterLink} className="text-sm font-medium text-gray-500" to="#">
          <FormattedMessage id={translations.pages.groups.newGroup} />
        </Link>
      </div>

      <div className="text-2xl font-semibold">
        <FormattedMessage id={translations.pages.groups.newGroup} />

        <div className="mt-2 text-sm font-normal text-gray-500">
          <FormattedMessage id={translations.pages.groups.createGroupDescription} />
        </div>
      </div>

      <Form className="flex grow flex-col" {...{ onSubmit, schema }}>
        {({ submitting, form, submitSucceeded, submitError, dirtySinceLastSubmit }) => (
          <div className="flex grow flex-col justify-between space-y-10">
            <div className="space-y-10">
              <ValidatedField
                field={InputWithLabel}
                fieldClassName="w-1/2 min-w-[20rem]"
                id="name"
                label={<FormattedMessage id={translations.fields.groupName} />}
                name="name"
                placeholder={intl.formatMessage({ id: translations.pages.groups.createPlaceholder })}
                readOnly={submitting}
              />
            </div>

            <div className="flex gap-6">
              <Button appearance="basic" className="mt-6 w-48" onClick={() => form.restart()} type="button">
                <FormattedMessage id={translations.buttons.cancel} />
              </Button>

              <Button appearance="primary" as={LoadingButton} className="mt-6 w-48" loading={submitting} type="submit">
                <FormattedMessage id={translations.buttons.save} />
              </Button>

              <SubmitFeedback {...{ submitError, submitSucceeded }} show={!submitting && !dirtySinceLastSubmit} />
            </div>
          </div>
        )}
      </Form>
    </Layout>
  );
};
