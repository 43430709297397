/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum MessageAttachmentResourceType {
  FILE = 'file',
  EVENT = 'event',
  SERVICE = 'service',
}
