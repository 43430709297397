/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { SignUpDto } from '../models/SignUpDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class OnboardingService {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Creates a new user.
   * @returns void
   * @throws ApiError
   */
  public signUp({
    requestBody,
  }: {
    requestBody: SignUpDto,
  }): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/onboarding/sign-up',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

}
