import { BulkMembersUploadResultDto } from '@billy/management-api-sdk';
import { CheckCircleIcon, ChevronDownIcon, ChevronUpIcon, ExclamationCircleIcon } from '@heroicons/react/24/outline';
import classnames from 'classnames';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import { translations } from '@/locales';

import { Button } from '@/components/Button';

import { Table, TableBody, TableHeader, TableHeaderColumn, TableRow, TableRowColumn } from './Table';

type Props = {
  members?: BulkMembersUploadResultDto[];
  className?: string;
  failed?: boolean;
  title?: React.ReactNode;
};

export const ImportList: React.FC<Props> = ({ members, failed, title, className }) => {
  const [showLessToggle, setToggle] = React.useState(false);

  return (
    <div>
      <div className="flex items-center justify-between">
        <span className="flex gap-2">
          <span>
            {title}
            {':'}
          </span>

          {members?.length}
        </span>

        <Button className="flex items-center gap-3 px-0 shadow-none" onClick={() => setToggle(!showLessToggle)}>
          {showLessToggle ? (
            <>
              <FormattedMessage id={translations.buttons.showMore} />

              <ChevronDownIcon className="h-5 w-5 rounded-md bg-gray-200 p-1 shadow-sm" />
            </>
          ) : (
            <>
              <FormattedMessage id={translations.buttons.showLess} />

              <ChevronUpIcon className="h-5 w-5 rounded-md bg-gray-200 p-1 shadow-sm" />
            </>
          )}
        </Button>
      </div>

      {!showLessToggle && (
        <Table {...{ className }}>
          <TableHeader>
            <TableRow className="divide-x">
              <TableHeaderColumn className="text-right">
                <FormattedMessage id={translations.fields.row} />
              </TableHeaderColumn>

              <TableHeaderColumn>
                <FormattedMessage id={translations.fields.emailAddress} />
              </TableHeaderColumn>

              <TableHeaderColumn>{failed && <FormattedMessage id={translations.fields.issues} />}</TableHeaderColumn>
            </TableRow>
          </TableHeader>

          <TableBody>
            {members?.map((member, index) => (
              <TableRow className="group divide-x" key={index}>
                <TableRowColumn>
                  <div className="flex min-w-[100px] justify-between">
                    {failed ? (
                      <ExclamationCircleIcon className="h-6 w-6 text-red-600" />
                    ) : (
                      <CheckCircleIcon className="h-6 w-6  text-green-600" />
                    )}

                    <div>{member.row}</div>
                  </div>
                </TableRowColumn>

                <TableRowColumn
                  className={classnames('w-[46%] group-hover:bg-indigo-50', {
                    'bg-red-100  ': !!failed
                  })}
                >
                  {member.email}
                </TableRowColumn>

                <TableRowColumn className="w-[46%]">
                  <div className="max-w-md truncate">{member.description}</div>
                </TableRowColumn>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      )}
    </div>
  );
};
