import * as React from 'react';

export interface Props extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'value' | 'accept' | 'onChange'> {
  multiple?: boolean;
  onChange(files: File[]): void;
  accept: string;
}

export const FileInput = React.forwardRef(
  ({ className, children, accept, onChange, ...rest }: Props, ref: React.Ref<any>) => {
    const onChangeCallback = React.useCallback(
      (event: React.ChangeEvent<HTMLInputElement>) => onChange(Array.from(event.target.files ?? [])),
      [onChange]
    );

    return (
      <label htmlFor={rest.id} {...{ className }}>
        {children}

        <input
          {...rest}
          {...{ accept, ref }}
          className="m-0 block h-0 w-0 opacity-0"
          onChange={onChangeCallback}
          type="file"
          value=""
        />
      </label>
    );
  }
);
