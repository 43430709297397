/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { GroupsAndUserIdsDto } from '../models/GroupsAndUserIdsDto';
import type { UserIdsDto } from '../models/UserIdsDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class AudienceService {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Add users to this audience.
   * @returns void
   * @throws ApiError
   */
  public addAudienceUser({
    resourceId,
    requestBody,
  }: {
    /**
     * The id of the resource that this audience is associated with.
     */
    resourceId: string,
    requestBody: GroupsAndUserIdsDto,
  }): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/audience/add-users',
      query: {
        'resourceId': resourceId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        401: `Credentials are missing or invalid.`,
        404: `Resource not found.`,
      },
    });
  }

  /**
   * Remove users from this audience.
   * @returns void
   * @throws ApiError
   */
  public removeAudienceUsers({
    resourceId,
    requestBody,
  }: {
    /**
     * The id of the resource that this audience is associated with.
     */
    resourceId: string,
    requestBody: UserIdsDto,
  }): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/audience/remove-users',
      query: {
        'resourceId': resourceId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        401: `Credentials are missing or invalid.`,
        404: `Resource not found.`,
      },
    });
  }

}
