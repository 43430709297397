import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import * as yup from 'yup';

import { translations } from '@/locales';

import { Button } from '@/components/Button';
import { Form } from '@/components/Form/Form';
import { InputWithLabel } from '@/components/Form/InputWithLabel';
import { ValidatedField } from '@/components/Form/ValidatedField';
import { LoadingButton } from '@/components/LoadingButton';
import { SubmitFeedback } from '@/components/SubmitFeedback';

import { InstanceProps } from './Modal';
import { ModalHeader } from './ModalHeader';
import { ModalLayout } from './ModalLayout';

interface ModalProps extends InstanceProps<string> {
  title: React.ReactNode;
  description: React.ReactNode;
  successMessage: React.ReactNode;
  schema: yup.ObjectSchema<any>;
  onInvite(email: string): Promise<void>;
}

export const InvitationModal: React.FC<ModalProps> = ({
  onClose,
  title,
  schema,
  onInvite,
  successMessage,
  description,
  className
}) => {
  const intl = useIntl();

  const onSubmit = React.useCallback(
    async (values: yup.InferType<typeof schema>) => {
      await onInvite(values.email);
    },
    [onInvite]
  );

  return (
    <ModalLayout {...{ className }}>
      <ModalHeader>
        {title}

        <p className="text-sm font-normal text-gray-500">{description}</p>
      </ModalHeader>

      <Form {...{ onSubmit, schema }}>
        {({ submitting, dirtySinceLastSubmit, submitError, submitSucceeded }) => (
          <div className="space-y-6">
            <ValidatedField
              field={InputWithLabel}
              id="email"
              label={<FormattedMessage id={translations.fields.emailAddress} />}
              name="email"
              placeholder={intl.formatMessage({ id: translations.fields.emailAddress })}
              readOnly={submitting}
              type="email"
            />

            <div className="flex gap-4">
              <Button appearance="basic" className="w-full" onClick={onClose} type="button">
                <FormattedMessage id={translations.buttons.cancel} />
              </Button>

              <Button appearance="primary" as={LoadingButton} className="w-full" loading={submitting} type="submit">
                <FormattedMessage id={translations.buttons.add} />
              </Button>
            </div>

            <SubmitFeedback
              show={!submitting && !dirtySinceLastSubmit}
              {...{ submitError, submitSucceeded, successMessage }}
            />
          </div>
        )}
      </Form>
    </ModalLayout>
  );
};
