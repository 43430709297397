import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link as RouterLink } from 'react-router-dom';
import * as yup from 'yup';

import { translations } from '@/locales';

import { Auth } from '@/containers/use-auth';

import { Button } from '@/components/Button';
import { CenteredBoxLayout } from '@/components/CenteredBoxLayout';
import { Form } from '@/components/Form';
import { InputWithLabel } from '@/components/Form/InputWithLabel';
import { PasswordInput } from '@/components/Form/PasswordInput';
import { ValidatedField } from '@/components/Form/ValidatedField';
import { Link } from '@/components/Link';
import { LoadingButton } from '@/components/LoadingButton';
import { SubmitFeedback } from '@/components/SubmitFeedback';

export const schema = yup
  .object({
    email: yup.string().email().required().label(translations.fields.emailAddress),
    password: yup.string().trim().required().label(translations.fields.password)
  })
  .required();

export const LoginPage: React.FC = () => {
  const { loginWithPassword } = Auth.useContainer();

  const onSubmit = React.useCallback(
    async ({ email, password }: yup.InferType<typeof schema>) => await loginWithPassword({ email, password }),
    [loginWithPassword]
  );

  return (
    <CenteredBoxLayout title={<FormattedMessage id={translations.pages.login.title} />}>
      <Form {...{ onSubmit, schema }}>
        {({ submitting, dirtySinceLastSubmit, submitError, submitSucceeded }) => (
          <div className="space-y-6">
            <ValidatedField
              field={InputWithLabel}
              id="email"
              label={<FormattedMessage id={translations.fields.emailAddress} />}
              name="email"
              readOnly={submitting}
              type="email"
            />

            <ValidatedField
              as={PasswordInput}
              field={InputWithLabel}
              id="password"
              label={<FormattedMessage id={translations.fields.password} />}
              name="password"
              readOnly={submitting}
              type="password"
            />

            <div className="mt-4">
              <Link as={RouterLink} className="text-indigo-600 hover:text-indigo-500" to="/forgot-password">
                <FormattedMessage id={translations.pages.login.forgot} />
              </Link>
            </div>

            <Button appearance="primary" as={LoadingButton} className="w-full" loading={submitting} type="submit">
              <FormattedMessage id={translations.pages.login.cta} />
            </Button>

            <SubmitFeedback {...{ submitError, submitSucceeded }} show={!submitting && !dirtySinceLastSubmit} />
          </div>
        )}
      </Form>
    </CenteredBoxLayout>
  );
};
