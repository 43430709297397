import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/solid';
import classnames from 'classnames';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import { translations } from '@/locales';

import { Button } from './Button';

export interface Props {
  totalItems: number;
  currentPage: number;
  pageSize: number;
  className?: string;
  onPageChange(page: number): void;
}

type PageButtonProps = {
  onClick?: (page: number) => void;
  className?: string;
  disabled?: boolean;
};

const paginationButtonStyle =
  'flex h-10 w-10 items-center justify-center rounded-none border text-sm text-gray-500 hover:bg-gray-100';

const PageButton: React.FC<React.PropsWithChildren<PageButtonProps>> = ({ children, className, disabled, onClick }) => {
  return (
    <Button className={classnames(paginationButtonStyle, className)} {...{ disabled, onClick }}>
      {children}
    </Button>
  );
};

export const Pagination: React.FC<Props> = ({ totalItems, pageSize, currentPage, onPageChange, className }) => {
  const totalPages = Math.ceil(totalItems / pageSize) || 1;
  const isFirstPage = currentPage === 1;
  const isLastPage = currentPage === totalPages;

  const pageNumbers = Array.from({ length: totalPages }, (_, index) => index + 1);

  return (
    <div className={classnames('flex items-center justify-between', className)}>
      <div className="text-sm font-normal text-gray-700">
        {totalItems ? (
          <FormattedMessage
            id={translations.pages.pageSections.pagination}
            values={{
              pageEnd: currentPage * pageSize < totalItems ? currentPage * pageSize : totalItems,
              pageStart: (currentPage - 1) * pageSize + 1,
              total: totalItems
            }}
          />
        ) : (
          <FormattedMessage id={translations.emptyStates.noItemsToShow} />
        )}
      </div>

      <div className="flex items-center">
        <PageButton
          className={classnames(paginationButtonStyle, 'w-fit rounded-l-md')}
          disabled={isFirstPage}
          onClick={() => onPageChange(currentPage - 1)}
        >
          <ChevronLeftIcon className="h-5 w-5" />
        </PageButton>

        {pageNumbers.map((pageNumber) => (
          <PageButton
            className="disabled:border-indigo-500 disabled:bg-indigo-50"
            disabled={pageNumber === currentPage}
            key={pageNumber}
            onClick={() => onPageChange(pageNumber)}
          >
            {pageNumber}
          </PageButton>
        ))}

        <PageButton
          className={classnames(paginationButtonStyle, 'w-fit rounded-r-md')}
          disabled={isLastPage}
          onClick={() => onPageChange(currentPage + 1)}
        >
          <ChevronRightIcon className="h-5 w-5" />
        </PageButton>
      </div>
    </div>
  );
};
