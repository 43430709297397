import classNames from 'classnames';
import React from 'react';

type ProgressBarProps = {
  progress: number;
  className?: string;
};

export const ProgressBar: React.FC<ProgressBarProps> = ({ progress, className }) => {
  return (
    <div className={classNames('h-2 rounded-full bg-gray-200', className)}>
      <div
        className="h-full rounded-full bg-indigo-500"
        style={{
          transform: `scaleX(${progress / 100})`,
          transformOrigin: 'left',
          transition: 'transform 300ms ease-in-out'
        }}
      />
    </div>
  );
};
