import { debounce } from 'lodash';
import React, { useState } from 'react';

import { As, AsComponent } from '@/components/As';

import { Input, Props as InputProps } from './Input';

type Props<T extends AsComponent> = InputProps<T> & {
  onChange: (value: string) => void;
  delay?: number;
  initialValue: string;
};

export const DebouncedInput = React.forwardRef(function DebouncedInput<T extends AsComponent>(
  //@ts-ignore
  { as = Input, onChange, delay = 1000, initialValue, ...props }: Props<T>,
  ref: React.Ref<any>
) {
  const [inputValue, setInputValue] = useState(initialValue);

  const debouncedOnChange = React.useMemo(() => debounce(onChange, delay), [delay, onChange]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setInputValue(value);

    debouncedOnChange.cancel();
    debouncedOnChange(value);
  };

  return <As {...props} {...{ as, ref }} onChange={handleChange} value={inputValue} />;
});
