import { UserDto } from '@billy/management-api-sdk';
import React from 'react';
import { FormattedDate, FormattedMessage, useIntl } from 'react-intl';

import { translations } from '@/locales';

import { Status } from '@/constants/status';

import { Badge } from '@/components/Badge';
import { EmptyList } from '@/components/EmptyList';
import { NoResultsFound } from '@/components/NoResultsFound';
import { MemberProfile } from '@/components/Profile';

import { Table, TableBody, TableHeader, TableHeaderColumn, TableRow, TableRowColumn } from './Table';

type Props = {
  members?: UserDto[];
  className?: string;
  empty?: boolean;
  noResults?: boolean;
};

export const AdminMembersList: React.FC<Props> = ({ members, empty, noResults, className }) => {
  const intl = useIntl();

  return empty ? (
    <EmptyList
      title={
        <FormattedMessage
          id={translations.emptyStates.noMembers}
          values={{ value: intl.formatMessage({ id: translations.pages.members.members }).toLowerCase() }}
        />
      }
    ></EmptyList>
  ) : (
    <Table {...{ className }}>
      <TableHeader>
        <TableRow>
          <TableHeaderColumn>
            <FormattedMessage id={translations.fields.name} />
          </TableHeaderColumn>

          <TableHeaderColumn>
            <FormattedMessage id={translations.pages.community.community} />
          </TableHeaderColumn>

          <TableHeaderColumn>
            <FormattedMessage id={translations.pages.members.memberId} />
          </TableHeaderColumn>

          <TableHeaderColumn>
            <FormattedMessage id={translations.fields.dateAdded} />
          </TableHeaderColumn>

          <TableHeaderColumn>
            <FormattedMessage id={translations.fields.status} />
          </TableHeaderColumn>
        </TableRow>
      </TableHeader>

      <TableBody>
        {noResults ? (
          <TableRow>
            <td colSpan={5}>
              <NoResultsFound />
            </td>
          </TableRow>
        ) : (
          members?.map((member, index) => (
            <TableRow className="hover:bg-indigo-100" key={index}>
              <TableRowColumn>
                <MemberProfile
                  email={member.email!}
                  firstName={member.firstName}
                  lastName={member.lastName}
                  src={member.image}
                />
              </TableRowColumn>

              <TableRowColumn className="max-w-2xl truncate">{member.communityName}</TableRowColumn>

              <TableRowColumn>{member.id}</TableRowColumn>

              <TableRowColumn>
                <FormattedDate day="2-digit" month="2-digit" value={member.addedAt} year="numeric" />
              </TableRowColumn>

              <TableRowColumn>
                <Badge appearance={(member.status as string) === Status.ACTIVE ? 'active' : 'inactive'}>
                  <FormattedMessage
                    id={translations.statuses.active}
                    values={{ active: (member.status as string) === Status.ACTIVE }}
                  />
                </Badge>
              </TableRowColumn>
            </TableRow>
          ))
        )}
      </TableBody>
    </Table>
  );
};
