import { MemberDto } from '@billy/management-api-sdk';
import { ExclamationTriangleIcon, XCircleIcon } from '@heroicons/react/24/outline';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { translations } from '@/locales';

import { extractErrorMessage } from '@/utils/functions';

import { AsyncButton } from '@/components/AsyncButton';
import { Button } from '@/components/Button';
import { FeedbackMessage } from '@/components/FeedbackMessage';

import { InstanceProps } from './Modal';
import { ModalHeader } from './ModalHeader';
import { ModalLayout } from './ModalLayout';

interface ModalProps extends InstanceProps<undefined, Pick<MemberDto, 'email' | 'firstName' | 'id'>> {
  title: React.ReactNode;
  description: React.ReactNode;
  buttonMessage: React.ReactNode;
  icon: React.ReactNode;
  onConfirmation(resourceId?: string): Promise<void>;
}

export const deleteIcon = (
  <div className="flex h-10 w-10 items-center justify-center rounded-full bg-red-100 p-2">
    <ExclamationTriangleIcon className="h-6 w-6 stroke-2 text-lg text-red-600" />
  </div>
);

export const cancelIcon = (
  <div className="flex h-10 w-10 items-center justify-center rounded-full bg-[#EEEEED] p-2">
    <XCircleIcon className="h-6 w-6 stroke-2 text-lg text-gray-500" />
  </div>
);

export const ConfirmationModal: React.FC<ModalProps> = ({
  onClose,
  title,
  buttonMessage,
  onConfirmation,
  description,
  icon,
  className,
  data
}) => {
  const [error, setError] = React.useState<Error | undefined>();

  const intl = useIntl();

  return (
    <ModalLayout {...{ className }}>
      <div className="flex gap-4">
        <div className="flex-shrink-0">{icon}</div>

        <div>
          <ModalHeader>
            <span className="capitalize">{title}</span>

            <p className="text-sm font-normal text-gray-500">{description}</p>
          </ModalHeader>

          <div className="mt-9 flex justify-end gap-4">
            <Button appearance="basic" onClick={onClose} type="button">
              <FormattedMessage id={translations.buttons.neverMind} />
            </Button>

            <AsyncButton
              appearance="delete"
              onClick={async () => {
                try {
                  await onConfirmation(data?.id);
                  onClose();
                } catch (err) {
                  setError(err as Error);
                }
              }}
            >
              {buttonMessage}
            </AsyncButton>
          </div>

          {!!error && (
            <FeedbackMessage className="mt-4">
              {extractErrorMessage(error, intl.formatMessage({ id: translations.errors.errorOccurred }))}
            </FeedbackMessage>
          )}
        </div>
      </div>
    </ModalLayout>
  );
};
