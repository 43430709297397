import { ChevronRightIcon } from '@heroicons/react/24/solid';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link as RouterLink, useParams } from 'react-router-dom';
import useSWR from 'swr';
import * as yup from 'yup';

import { apiClient } from '@/services/api';

import { translations } from '@/locales';

import { useModal } from '@/hooks/useModal';

import { Button } from '@/components/Button';
import { EmptyList } from '@/components/EmptyList';
import { Layout } from '@/components/Layout';
import { Link } from '@/components/Link';
import { LoadingOverlay } from '@/components/Loading';
import { Modal } from '@/components/Modal';
import { InvitationModal } from '@/components/Modal/InvitationModal';
import { ManagersList } from '@/components/Table/ManagersList';

import { CommunityPage } from './CommunityPage';

export const emailSchema = yup
  .object({
    email: yup.string().email().required().label(translations.fields.emailAddress)
  })
  .required();

export const CommunityPageDetails: React.FC<{ isAdmin?: boolean }> = ({ isAdmin }) => {
  const { communityId } = useParams();

  const {
    data: community,
    isLoading: loading,
    error,
    mutate: onReload
  } = useSWR(
    ['get-community', communityId],
    () => apiClient.community.getCommunityDetails({ communityId: communityId! }),
    {
      revalidateIfStale: true,
      revalidateOnFocus: true,
      revalidateOnMount: true,
      revalidateOnReconnect: true
    }
  );

  // so the ManagersList calling on reload won't trigger CommunityPage re-render
  const onReloadCallback = React.useCallback(() => onReload(), [onReload]);

  const invitationModal = useModal(InvitationModal);

  return (
    <Layout>
      <LoadingOverlay {...{ error, loading }}>
        {!!isAdmin && (
          <div className="mb-6 flex gap-5">
            <Link as={RouterLink} className="text-sm font-medium text-gray-500" to="/communities">
              <FormattedMessage id={translations.pages.community.communities} />
            </Link>

            <ChevronRightIcon className="w-5 text-sm text-gray-400" />

            <Link as={RouterLink} className="max-w-[12rem] truncate text-sm font-medium text-gray-500" to="#">
              {community?.name || <FormattedMessage id={translations.pages.community.newCommunity} />}
            </Link>
          </div>
        )}

        <CommunityPage {...{ community }} />

        {!!isAdmin && (
          <div className="py-6">
            <div className="space-y-12">
              <div className="space-y-4">
                <div className="flex justify-between">
                  <FormattedMessage id={translations.pages.community.communityManagers} />

                  {!!community?.managers && (
                    <Button
                      appearance="secondary"
                      onClick={() => {
                        invitationModal
                          .open()
                          .then()
                          .catch(() => null);
                      }}
                      type="button"
                    >
                      <FormattedMessage id={translations.buttons.addManager} />
                    </Button>
                  )}
                </div>

                {community?.managers ? (
                  <ManagersList
                    communityId={community.id}
                    managers={community.managers}
                    {...{ onReload: onReloadCallback }}
                  />
                ) : (
                  <EmptyList
                    description={<FormattedMessage id={translations.emptyStates.addManager} />}
                    title={<FormattedMessage id={translations.emptyStates.noManagers} />}
                  >
                    <Button
                      appearance="secondary"
                      onClick={() => {
                        invitationModal
                          .open()
                          .then()
                          .catch(() => null);
                      }}
                      type="button"
                    >
                      <FormattedMessage id={translations.buttons.addManager} />
                    </Button>
                  </EmptyList>
                )}
              </div>

              <div className="space-y-2">
                <div className="text-lg font-medium">
                  <FormattedMessage id={translations.pages.community.communityMembers} />
                </div>

                <div className="text-sm font-normal">
                  <FormattedMessage
                    id={translations.pages.community.membersCount}
                    values={{
                      b: (children) => <span className="font-medium">{children}</span>,
                      count: community?.memberCount
                    }}
                  />
                </div>

                <div>
                  <Link as={RouterLink} to={`/members?community=${community?.id}`}>
                    <Button appearance="secondary">
                      <FormattedMessage id={translations.pages.community.seeMembers} />
                    </Button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        )}

        <Modal
          onInvite={async (email) => {
            await apiClient.community
              .addCommunityManagers({
                communityId: community!.id,
                requestBody: { emails: [email] }
              })
              .then(() => onReload());
          }}
          schema={emailSchema}
          {...invitationModal.props}
          description={<FormattedMessage id={translations.pages.community.inviteManager} />}
          successMessage={<FormattedMessage id={translations.pages.community.invitedManager} />}
          title={<FormattedMessage id={translations.buttons.addManager} />}
        />
      </LoadingOverlay>
    </Layout>
  );
};
