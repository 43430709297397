import { CommunityPreviewDto } from '@billy/management-api-sdk';
import classnames from 'classnames';
import React from 'react';
import { FormattedDate, FormattedMessage } from 'react-intl';
import { Link as RouterLink } from 'react-router-dom';

import { apiClient } from '@/services/api';

import { Status } from '@/constants/status';
import { translations } from '@/locales';

import { AsyncButton } from '@/components/AsyncButton';
import { Badge } from '@/components/Badge';
import { Button } from '@/components/Button';
import { EmptyList } from '@/components/EmptyList';
import { NoResultsFound } from '@/components/NoResultsFound';

import { Table, TableBody, TableHeader, TableHeaderColumn, TableRow, TableRowColumn } from './Table';

export type Community = {
  id: string;
  name: string;
  createdAt: Date;
  active: boolean;
};

type Props = {
  communities?: CommunityPreviewDto[];
  className?: string;
  empty?: boolean;
  noResults?: boolean;
  onReload(): void;
};

export const CommunitiesList: React.FC<Props> = ({ communities, empty, onReload, noResults, className }) => {
  return empty ? (
    <EmptyList
      description={<FormattedMessage id={translations.emptyStates.createCommunity} />}
      title={<FormattedMessage id={translations.emptyStates.noCommunities} />}
    >
      <Button appearance="primary" as={RouterLink} className="flex w-48 justify-center" to="/communities/new">
        <FormattedMessage id={translations.buttons.createCommunity} />
      </Button>
    </EmptyList>
  ) : (
    <Table {...{ className }}>
      <TableHeader>
        <TableRow>
          <TableHeaderColumn>
            <FormattedMessage id={translations.fields.name} />
          </TableHeaderColumn>

          <TableHeaderColumn>
            <FormattedMessage id={translations.fields.dateAdded} />
          </TableHeaderColumn>

          <TableHeaderColumn>
            <FormattedMessage id={translations.fields.status} />
          </TableHeaderColumn>

          <TableHeaderColumn />
        </TableRow>
      </TableHeader>

      <TableBody>
        {noResults ? (
          <TableRow>
            <td colSpan={4}>
              <NoResultsFound />
            </td>
          </TableRow>
        ) : (
          communities?.map((community, index) => (
            <RouterLink
              className={classnames('table-row hover:bg-indigo-50', {
                'cursor-default': (community.status as string) !== Status.ACTIVE
              })}
              key={index}
              to={(community.status as string) === Status.ACTIVE ? `/communities/${community.id}` : {}}
            >
              <TableRowColumn className="max-w-4xl truncate">{community.name}</TableRowColumn>

              <TableRowColumn>
                <FormattedDate day="2-digit" month="2-digit" value={community.createdAt} year="numeric" />
              </TableRowColumn>

              <TableRowColumn>
                <Badge appearance={community.status}>
                  <FormattedMessage
                    id={translations.statuses.active}
                    values={{ active: (community.status as string) === Status.ACTIVE }}
                  />
                </Badge>
              </TableRowColumn>

              <TableRowColumn>
                <AsyncButton
                  appearance="basic"
                  className={classnames({ 'text-indigo-400': (community.status as string) !== Status.ACTIVE })}
                  onClick={() =>
                    community.status === 'active'
                      ? apiClient.community.suspendCommunity({ communityId: community.id }).then(onReload)
                      : apiClient.community.reactivateCommunity({ communityId: community.id }).then(onReload)
                  }
                >
                  <FormattedMessage
                    id={translations.buttons.suspend}
                    values={{ active: (community.status as string) === Status.ACTIVE }}
                  />
                </AsyncButton>
              </TableRowColumn>
            </RouterLink>
          ))
        )}
      </TableBody>
    </Table>
  );
};
