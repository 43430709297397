import { EventPreviewDto } from '@billy/management-api-sdk';
import React from 'react';
import { FormattedDate, FormattedMessage } from 'react-intl';
import { Link as RouterLink } from 'react-router-dom';

import { translations } from '@/locales';

import { Badge } from '@/components/Badge';
import { Button } from '@/components/Button';
import { EmptyList } from '@/components/EmptyList';
import { NoResultsFound } from '@/components/NoResultsFound';

import { Table, TableBody, TableHeader, TableHeaderColumn, TableRow, TableRowColumn } from './Table';

type Props = {
  events?: EventPreviewDto[];
  className?: string;
  empty?: boolean;
  noResults?: boolean;
};

export const EventsList: React.FC<Props> = ({ events, empty, noResults, className }) => {
  return empty ? (
    <EmptyList
      description={<FormattedMessage id={translations.emptyStates.createEvent} />}
      title={<FormattedMessage id={translations.emptyStates.noEvents} />}
    >
      <Button appearance="primary" as={RouterLink} className="flex w-48 justify-center" to="/events/new">
        <FormattedMessage id={translations.buttons.createEvent} />
      </Button>
    </EmptyList>
  ) : (
    <Table {...{ className }}>
      <TableHeader>
        <TableRow>
          <TableHeaderColumn>
            <FormattedMessage id={translations.pages.events.event} />
          </TableHeaderColumn>

          <TableHeaderColumn>
            <FormattedMessage id={translations.pages.events.locationAndTime} />
          </TableHeaderColumn>

          <TableHeaderColumn>
            <FormattedMessage id={translations.pages.events.enrolled} />
          </TableHeaderColumn>

          <TableHeaderColumn>
            <FormattedMessage id={translations.fields.dateCreated} />
          </TableHeaderColumn>
        </TableRow>
      </TableHeader>

      <TableBody>
        {noResults ? (
          <TableRow>
            <td colSpan={4}>
              <NoResultsFound />
            </td>
          </TableRow>
        ) : (
          events?.map((event, index) => (
            <RouterLink className="table-row hover:bg-indigo-50" key={index} to={`/events/${event.id}`}>
              <TableRowColumn className="whitespace-nowrap">
                <div className="flex gap-2">
                  <span className="max-w-xs truncate">{event.name}</span>

                  {!!event.canceledAt && (
                    <Badge appearance="inactive" className="uppercase">
                      <FormattedMessage id={translations.statuses.cancelled} />
                    </Badge>
                  )}
                </div>
              </TableRowColumn>

              <TableRowColumn className="whitespace-nowrap">
                <div className="flex flex-col gap-1">
                  <span>{event.location}</span>

                  <span className="uppercase">
                    <FormattedDate
                      day="2-digit"
                      hour="numeric"
                      hour12={true}
                      minute="numeric"
                      month="2-digit"
                      value={event.time}
                      year="numeric"
                    />
                  </span>
                </div>
              </TableRowColumn>

              <TableRowColumn className="whitespace-nowrap">
                <div className="flex gap-1">
                  <span className="text-sm text-gray-900">
                    {event.enrolledCount} / {event.memberCount}
                  </span>
                  <span className="text-sm font-normal lowercase text-gray-500">
                    <FormattedMessage id={translations.pages.members.members} />
                  </span>
                </div>

                <span className="text-sm font-normal lowercase text-gray-500">
                  {!Number.isNaN(event.enrolledCount / event.memberCount) ? (
                    Math.round((event.enrolledCount / event.memberCount) * 100) + '%'
                  ) : (
                    <FormattedMessage id={translations.emptyStates.noMembers} />
                  )}
                </span>
              </TableRowColumn>

              <TableRowColumn>
                <FormattedDate day="2-digit" month="2-digit" value={event.createdAt} year="numeric" />
              </TableRowColumn>
            </RouterLink>
          ))
        )}
      </TableBody>
    </Table>
  );
};
