import * as React from 'react';

interface RenderProps {
  dragging: boolean;
}

interface Props {
  className?: string;
  children(props: RenderProps): React.ReactNode;
  onChange(files: File[]): void;
}

export const DragAndDropFile: React.FC<Props> = ({ className, children, onChange }) => {
  const [dragging, setDragging] = React.useState(false);

  const onDrop = React.useCallback(
    (event: React.DragEvent<HTMLDivElement>) => {
      event.preventDefault();
      onChange(Array.from(event.dataTransfer.files));
      setDragging(false);
    },
    [setDragging, onChange]
  );

  const onDragOver = React.useCallback(
    (event: React.DragEvent<HTMLDivElement>) => {
      event.preventDefault();
      setDragging(true);
    },
    [setDragging]
  );

  const onDragLeave = React.useCallback(
    (event: React.DragEvent<HTMLDivElement>) => {
      event.preventDefault();
      setDragging(false);
    },
    [setDragging]
  );

  return <div {...{ className, onDragLeave, onDragOver, onDrop }}>{children({ dragging })}</div>;
};
