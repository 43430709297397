import { LoginWithPasswordPayload, SessionDto } from '@billy/auth-api-sdk';
import { CancelablePromise } from '@billy/management-api-sdk';
import { ApiRequestOptions } from '@billy/management-api-sdk/src/core/ApiRequestOptions';
import React from 'react';
import { useLocalStorage } from 'react-use';
import useSWR from 'swr';
import { createContainer } from 'unstated-next';

import { apiClient, updateAuthorization } from '@/services/api';
import { authApiClient } from '@/services/auth-api';

const apiClientRequest = apiClient.request.request.bind(apiClient.request);

interface Session extends Pick<SessionDto, 'accessToken' | 'refreshToken'> {}

const useAuth = () => {
  const [session, setSession, clearSession] = useLocalStorage<Session | undefined>('session', undefined, {
    deserializer: (value) => {
      try {
        const parsed = JSON.parse(value) as Partial<Session>;
        return parsed.accessToken && parsed.refreshToken ? (parsed as Session) : undefined;
      } catch {
        return undefined;
      }
    },
    raw: false,
    serializer: (session) => {
      if (!session) return JSON.stringify(session);

      return JSON.stringify({ accessToken: session.accessToken, refreshToken: session.refreshToken });
    }
  });

  // const ref = React.useRef<Promise<void> | null>(null);

  updateAuthorization(session?.accessToken);

  apiClient.request.request = <T>(options: ApiRequestOptions): CancelablePromise<T> => {
    return new CancelablePromise<T>((resolve, reject, onCancel) => {
      const promise = apiClientRequest<T>(options);

      onCancel(promise.cancel);

      return promise
        .then((result) => resolve(result))
        .catch((error) => {
          if (error?.status !== 401 || !session?.refreshToken) {
            // Unauthorized errors require special treatment, the rest can be thrown
            reject(error);
            return;
          }

          // Attempt to renew the token
          authApiClient.authentication
            .renew({ requestBody: { refreshToken: session.refreshToken } })
            .then((renewedSession) => {
              updateAuthorization(renewedSession.accessToken);

              setSession(renewedSession);

              // Retry the original request with the new token
              return apiClientRequest<T>(options);
            })
            .then((retryResult) => resolve(retryResult))
            .catch((renewError) => {
              if (renewError?.status === 401) {
                // Clear the session if token renewal fails with a 401 status.
                clearSession();
              }

              // reject(renewError);
            });
        });
    });
  };

  const {
    data: context,
    isLoading,
    isValidating,
    mutate: reload
  } = useSWR(
    session ? { accessToken: session.accessToken, key: `useAuth` } : null,
    () => apiClient.profile.getProfileDetails(),
    {
      revalidateIfStale: true,
      revalidateOnFocus: false,
      revalidateOnMount: true,
      revalidateOnReconnect: false
    }
  );

  const authenticated = !!session;
  const loading = isLoading || isValidating;

  return React.useMemo(
    () => ({
      authenticated,
      context,
      loading,
      loginWithPassword: (requestBody: LoginWithPasswordPayload) =>
        authApiClient.authentication.loginWithPassword({ requestBody }).then(setSession),
      logout: () => clearSession(),
      reload
    }),
    [authenticated, context, loading, reload, setSession, clearSession]
  );
};

export const Auth = createContainer(useAuth);
