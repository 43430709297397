import React from 'react';

import { Auth } from '@/containers/use-auth';

export const useRole = () => {
  const { context } = Auth.useContainer();

  const isAdmin = !!context?.role?.isAdmin;
  const community = context?.role?.communityManager?.communities?.[0];

  return React.useMemo(() => ({ community, isAdmin }), [community, isAdmin]);
};
