import classnames from 'classnames';
import React from 'react';

import { As, AsComponent, AsProps } from './As';

export type Appearance = 'primary' | 'secondary' | 'basic' | 'delete' | 'deleteSecondary';

const styles: Record<Appearance, string> = {
  basic: 'relative border-grey-300 border text-gray-700 hover:bg-gray-50 disabled:bg-gray-200',
  delete: 'relative bg-red-600  hover:bg-red-500 text-white disabled:bg-gray-200',
  deleteSecondary: 'relative border-grey-300 border text-[#FF231C] hover:bg-gray-50 disabled:bg-gray-200',
  primary: 'relative bg-indigo-600  text-white hover:bg-indigo-500 disabled:bg-indigo-300',
  secondary: 'relative bg-indigo-50 hover:bg-indigo-100 text-indigo-700 disabled:bg-gray-300'
};

export interface Props {
  appearance?: Appearance;
}

export const Button = React.forwardRef(
  <T extends AsComponent>(
    {
      // @ts-ignore
      as = 'button',
      appearance,
      disabled,
      className,
      children,
      onClick,
      ...rest
    }: Props & AsProps<T>,
    ref: React.Ref<HTMLElement>
  ) => (
    <As
      {...{ as, disabled, ref }}
      className={classnames(
        className,
        'rounded-md py-2 px-4 text-sm font-medium leading-5 shadow-sm',
        !!appearance && styles[appearance]
      )}
      onClick={disabled ? null : onClick}
      {...rest}
    >
      {children}
    </As>
  )
);
