import { MinusCircleIcon } from '@heroicons/react/24/outline';
import { ChevronRightIcon } from '@heroicons/react/24/solid';
import classnames from 'classnames';
import React from 'react';
import { FormattedDate, FormattedMessage, useIntl } from 'react-intl';
import { Link as RouterLink, useNavigate, useParams } from 'react-router-dom';
import useSWR from 'swr';
import * as yup from 'yup';

import { apiClient } from '@/services/api';

import { useModal } from '@/hooks/useModal';
import { page, size, useQueryParams } from '@/hooks/useQueryParams';
import { useRole } from '@/hooks/useRole';

import { Status } from '@/constants/status';

import { translations } from '@/locales';

import { Badge } from '@/components/Badge';
import { Button } from '@/components/Button';
import { EmptyList } from '@/components/EmptyList';
import { Form } from '@/components/Form';
import { CurrencyInput } from '@/components/Form/CurrencyInput';
import { InputWithLabel } from '@/components/Form/InputWithLabel';
import { MaxLengthIndicator } from '@/components/Form/MaxLengthIndicator';
import { TextArea } from '@/components/Form/TextArea';
import { ValidatedField } from '@/components/Form/ValidatedField';
import { Layout } from '@/components/Layout';
import { Link } from '@/components/Link';
import { LoadingOverlay } from '@/components/Loading';
import { LoadingButton } from '@/components/LoadingButton';
import { Modal } from '@/components/Modal';
import { ConfirmationModal, deleteIcon } from '@/components/Modal/ConfirmationModal';
import { SelectMembersModal } from '@/components/Modal/SelectMembersModal';
import { Pagination } from '@/components/Pagination';
import { MemberProfile } from '@/components/Profile';
import { SubmitFeedback } from '@/components/SubmitFeedback';
import { Table, TableBody, TableHeader, TableHeaderColumn, TableRow, TableRowColumn } from '@/components/Table/Table';

export const schema = yup
  .object({
    message: yup.string().trim().max(800).required().label(translations.fields.messageContent),
    name: yup.string().trim().required().label(translations.fields.name),
    price: yup.number().min(0).max(100000).required().label(translations.fields.price)
  })
  .required();

const config = { page, size };

export const ServiceDetailsPage: React.FC = () => {
  const { serviceId } = useParams();

  const [queryParams, updateQueryParams] = useQueryParams({ config });

  const { community } = useRole();

  const isNew = !serviceId;

  const {
    data: loadedService,
    isLoading: loading,
    error
  } = useSWR(
    isNew ? null : ['services/get-details', serviceId],
    () =>
      apiClient.service
        .getServiceDetails({ serviceId: serviceId! })
        .then((s) => ({ ...s, price: { ...s.price, amount: s.price.amount / 100 } })),
    { revalidateIfStale: true, revalidateOnFocus: false, revalidateOnMount: true, revalidateOnReconnect: false }
  );

  const {
    data: loadedMembers,
    isLoading: loadingMembers,
    error: membersError,
    mutate: onMembersReload
  } = useSWR(
    isNew ? null : ['services/find-audience-members', loadedService, queryParams],
    () =>
      apiClient.service.findServiceAudienceMembers({
        pageNumber: queryParams.page,
        pageSize: queryParams.size,
        serviceId: loadedService!.id
      }),
    { revalidateIfStale: true, revalidateOnFocus: false, revalidateOnMount: true, revalidateOnReconnect: false }
  );

  // const messages = useSWR(
  //   isNew ? null : 'messages/find-event-messages',
  //   () => apiClient.message.findMessages({ communityId: community?.id, resource: serviceId, resourceType: 'service' }),
  //   { revalidateIfStale: true, revalidateOnFocus: false, revalidateOnMount: true, revalidateOnReconnect: false }
  // );

  const navigate = useNavigate();

  const onSubmit = React.useCallback(
    async (values: yup.InferType<typeof schema>) => {
      if (!isNew) return;

      const service = await apiClient.service.createService({
        communityId: community!.id,
        requestBody: { ...values, price: values.price * 100 }
      });

      navigate(`/services/${service.id}`);
    },
    [community, isNew, navigate]
  );

  const initialValues = isNew
    ? undefined
    : {
        ...loadedService,
        // message: messages.data?.items[0]?.content,
        price: loadedService?.price.amount
      };

  const members = loadedMembers?.items ?? [];

  const selectMembersModal = useModal(SelectMembersModal);

  const removeMemberDialogModal = useModal(ConfirmationModal);

  const intl = useIntl();

  const confirmationModal = useModal(ConfirmationModal);

  return (
    <Layout>
      <LoadingOverlay {...{ error, loading }}>
        <div className="flex items-center gap-5">
          <Link as={RouterLink} className="text-sm font-medium text-gray-500" to="/services">
            <FormattedMessage id={translations.pages.services.services} />
          </Link>

          <ChevronRightIcon className="w-5 text-sm text-gray-400" />

          <Link as={RouterLink} className="max-w-[12rem] truncate text-sm font-medium text-gray-500" to="#">
            {initialValues?.name || <FormattedMessage id={translations.pages.services.newService} />}
          </Link>
        </div>

        <Form className="mt-5 flex grow" {...{ initialValues, onSubmit, schema }}>
          {({ submitting, form, values, dirtySinceLastSubmit, submitError, submitSucceeded }) => (
            <div className="flex grow flex-col justify-between">
              <div className="space-y-6">
                <div>
                  <div className="flex items-center justify-between text-2xl font-semibold">
                    {values?.name?.trim() || initialValues?.name?.trim() || (
                      <FormattedMessage id={translations.pages.services.newService} />
                    )}

                    {!isNew && (
                      <Button
                        appearance="deleteSecondary"
                        onClick={() => {
                          confirmationModal.open().catch(() => null);
                        }}
                        type="button"
                      >
                        <span className="whitespace-nowrap">
                          <FormattedMessage id={translations.buttons.deleteService} />
                        </span>
                      </Button>
                    )}
                  </div>

                  {!isNew && (
                    <div className="text-sm font-normal text-gray-500">
                      <FormattedMessage id={translations.fields.createdOn} />{' '}
                      <FormattedDate day="2-digit" month="2-digit" value={initialValues?.createdAt} year="numeric" />
                    </div>
                  )}
                </div>

                <div className="max-w-xl space-y-10">
                  <ValidatedField
                    field={InputWithLabel}
                    id="name"
                    label={<FormattedMessage id={translations.pages.services.serviceName} />}
                    name="name"
                    readOnly={!!submitting || !isNew}
                  />

                  <ValidatedField
                    as={CurrencyInput}
                    className="w-full"
                    currency={community!.currency}
                    field={InputWithLabel}
                    fieldClassName={classnames(
                      'w-full rounded-md border border-gray-300 py-2 px-2 text-gray-900 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm',
                      (!!submitting || !isNew) && 'bg-gray-100 text-gray-400'
                    )}
                    id="price"
                    label={<FormattedMessage id={translations.fields.price} />}
                    name="price"
                    placeholder={intl.formatMessage(
                      { id: translations.placeholders.price },
                      { currency: community!.currency }
                    )}
                  />

                  <MaxLengthIndicator max={800} name="message">
                    <ValidatedField
                      as={TextArea}
                      field={InputWithLabel}
                      fieldClassName="h-20 min-h-[6rem]"
                      help={<FormattedMessage id={translations.pages.services.messageHelp} />}
                      id="message"
                      label={<FormattedMessage id={translations.pages.messages.message} />}
                      name="message"
                      readOnly={!!submitting || !isNew}
                    />
                  </MaxLengthIndicator>
                </div>
              </div>

              {!isNew && (
                <LoadingOverlay {...{ error: membersError, loading: loadingMembers }}>
                  <div className="space-y-4">
                    <div className="flex justify-between text-lg">
                      <FormattedMessage id={translations.pages.pageSections.audience} />

                      {members.length > 0 && (
                        <Button
                          appearance="secondary"
                          className="w-32"
                          onClick={() => {
                            selectMembersModal.open().catch(() => null);
                          }}
                          type="button"
                        >
                          <FormattedMessage id={translations.buttons.addMembers} />
                        </Button>
                      )}
                    </div>

                    {members.length > 0 ? (
                      <Table>
                        <TableHeader>
                          <TableRow>
                            <TableHeaderColumn>
                              <FormattedMessage id={translations.fields.name} />
                            </TableHeaderColumn>

                            <TableHeaderColumn>
                              <FormattedMessage id={translations.fields.dateAdded} />
                            </TableHeaderColumn>

                            <TableHeaderColumn>
                              <FormattedMessage id={translations.fields.status} />
                            </TableHeaderColumn>

                            <TableHeaderColumn />
                          </TableRow>
                        </TableHeader>

                        <TableBody>
                          {members.map((member, index) => (
                            <TableRow key={index}>
                              <TableRowColumn>
                                <MemberProfile
                                  email={member.email}
                                  firstName={member.firstName}
                                  lastName={member.lastName}
                                  src={member.image}
                                />
                              </TableRowColumn>

                              <TableRowColumn>
                                <FormattedDate day="2-digit" month="2-digit" value={member.addedAt} year="numeric" />
                              </TableRowColumn>

                              <TableRowColumn>
                                <Badge appearance={(member.status as string) === Status.PAID ? 'active' : 'inactive'}>
                                  <FormattedMessage
                                    id={translations.statuses.paid}
                                    values={{ paid: (member.status as string) === Status.PAID }}
                                  />
                                </Badge>
                              </TableRowColumn>

                              <TableRowColumn className="relative">
                                <Button
                                  className="absolute right-8 -translate-y-1/2 transform shadow-none"
                                  onClick={() => {
                                    removeMemberDialogModal.open(member).catch(() => null);
                                  }}
                                >
                                  <MinusCircleIcon className="h-6 w-6" />
                                </Button>
                              </TableRowColumn>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    ) : (
                      <EmptyList
                        description={<FormattedMessage id={translations.emptyStates.addMembers} />}
                        title={<FormattedMessage id={translations.emptyStates.noMembers} />}
                      >
                        <Button
                          appearance="secondary"
                          className="w-32"
                          onClick={() => {
                            selectMembersModal.open().catch(() => null);
                          }}
                          type="button"
                        >
                          <FormattedMessage id={translations.buttons.addMembers} />
                        </Button>
                      </EmptyList>
                    )}
                  </div>

                  {!!loadedMembers && members.length > 0 && (
                    <Pagination
                      className="mt-9"
                      currentPage={loadedMembers.page.pageNumber}
                      onPageChange={(page: number) => updateQueryParams({ page })}
                      pageSize={loadedMembers.page.pageSize}
                      totalItems={loadedMembers.page.total}
                    />
                  )}

                  <Modal
                    {...selectMembersModal.props}
                    onInvite={async (groupIds, userIds) => {
                      await apiClient.audience
                        .addAudienceUser({
                          requestBody: { groupIds, userIds },
                          resourceId: loadedService!.id
                        })
                        .then(() => onMembersReload());
                    }}
                  />
                </LoadingOverlay>
              )}

              {!!isNew && (
                <div className="mt-6 flex items-center gap-4">
                  <Button appearance="basic" className="w-48" onClick={() => form.restart()} type="button">
                    <FormattedMessage id={translations.buttons.cancel} />
                  </Button>

                  <Button appearance="primary" as={LoadingButton} className="w-48" loading={submitting} type="submit">
                    <FormattedMessage id={isNew ? translations.buttons.save : translations.buttons.send} />
                  </Button>

                  <SubmitFeedback {...{ submitError, submitSucceeded }} show={!submitting && !dirtySinceLastSubmit} />
                </div>
              )}
            </div>
          )}
        </Form>

        <Modal
          {...removeMemberDialogModal.props}
          buttonMessage={<FormattedMessage id={translations.buttons.removeMember} />}
          description={
            <FormattedMessage
              id={translations.pages.members.removeMemberHelp}
              values={{
                value: removeMemberDialogModal.props.data?.firstName ?? removeMemberDialogModal.props.data?.email
              }}
            />
          }
          icon={<MinusCircleIcon className="h-10 w-10" />}
          onConfirmation={async (memberId) => {
            await apiClient.audience
              .removeAudienceUsers({
                requestBody: { userIds: [memberId!] },
                resourceId: loadedService!.id
              })
              .then(() => onMembersReload());
          }}
          title={<FormattedMessage id={translations.buttons.removeMember} />}
        />

        <Modal
          icon={deleteIcon}
          {...confirmationModal.props}
          buttonMessage={<FormattedMessage id={translations.buttons.deleteService} />}
          description={<FormattedMessage id={translations.pages.services.deleteServiceHelp} />}
          onConfirmation={async () =>
            await apiClient.service.deleteService({ serviceId: serviceId! }).then(() => navigate('/services'))
          }
          title={<FormattedMessage id={translations.buttons.deleteService} />}
        />
      </LoadingOverlay>
    </Layout>
  );
};
