import { QuestionPreviewDto } from '@billy/management-api-sdk';
import React from 'react';
import { FormattedDate, FormattedMessage } from 'react-intl';
import { Link as RouterLink } from 'react-router-dom';

import { translations } from '@/locales';

import { Button } from '@/components/Button';
import { EmptyList } from '@/components/EmptyList';
import { NoResultsFound } from '@/components/NoResultsFound';

import { Table, TableBody, TableHeader, TableHeaderColumn, TableRow, TableRowColumn } from './Table';

type Props = {
  questions?: QuestionPreviewDto[];
  className?: string;
  empty?: boolean;
  noResults?: boolean;
};

export const QuestionsList: React.FC<Props> = ({ questions, empty, noResults, className }) => {
  return empty ? (
    <EmptyList
      description={<FormattedMessage id={translations.emptyStates.addQuestion} />}
      title={<FormattedMessage id={translations.emptyStates.noQuestions} />}
    >
      <Button appearance="primary" as={RouterLink} className="flex w-48 justify-center" to="/questions/new">
        <FormattedMessage id={translations.buttons.addQuestion} />
      </Button>
    </EmptyList>
  ) : (
    <Table {...{ className }}>
      <TableHeader>
        <TableRow>
          <TableHeaderColumn>
            <FormattedMessage id={translations.pages.questions.question} />
          </TableHeaderColumn>

          <TableHeaderColumn>
            <FormattedMessage id={translations.fields.answers} />
          </TableHeaderColumn>

          <TableHeaderColumn>
            <FormattedMessage id={translations.fields.dateAdded} />
          </TableHeaderColumn>
        </TableRow>
      </TableHeader>

      <TableBody>
        {noResults ? (
          <TableRow>
            <td colSpan={3}>
              <NoResultsFound />
            </td>
          </TableRow>
        ) : (
          questions?.map((question, index) => (
            <RouterLink className="table-row hover:bg-indigo-50" key={index} to={`/questions/${question.id}`}>
              <TableRowColumn className="max-w-4xl truncate">{question.text}</TableRowColumn>

              <TableRowColumn className="whitespace-nowrap">
                <div className="flex gap-1">
                  <span className="text-sm text-gray-900">
                    {question.answerCount} / {question.memberCount}
                  </span>

                  <span className="text-sm font-normal lowercase text-gray-500">
                    <FormattedMessage id={translations.pages.members.members} />
                  </span>
                </div>

                <span className="text-sm font-normal text-gray-500">
                  {!Number.isNaN(question.answerCount / question.memberCount) ? (
                    Math.round((question.answerCount / question.memberCount) * 100) + '%'
                  ) : (
                    <FormattedMessage id={translations.emptyStates.noMembers} />
                  )}
                </span>
              </TableRowColumn>

              <TableRowColumn>
                <FormattedDate day="2-digit" month="2-digit" value={question.createdAt} year="numeric" />
              </TableRowColumn>
            </RouterLink>
          ))
        )}
      </TableBody>
    </Table>
  );
};
