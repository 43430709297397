import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { translations } from '@/locales';

import { extractErrorMessage } from '@/utils/functions';

import classnames from 'classnames';
import { FeedbackMessage } from './FeedbackMessage';

type Props = {
  show: boolean;
  submitError?: Error;
  submitSucceeded?: boolean;
  className?: string;
  successMessage?: React.ReactNode;
};

export const SubmitFeedback: React.FC<Props> = ({ show, submitSucceeded, submitError, className, successMessage }) => {
  const intl = useIntl();

  const [visible, setVisible] = React.useState(show);

  React.useEffect(() => {
    if (show) {
      setVisible(true);
      const timer = setTimeout(() => {
        setVisible(false);
      }, 10000);

      return () => clearTimeout(timer);
    }
  }, [show]);

  if (!show) return null;

  return (
    <div className={classnames(className, visible ? 'block' : 'hidden')}>
      {submitSucceeded ? (
        <FeedbackMessage success>
          {successMessage ?? <FormattedMessage id={translations.statuses.success} />}
        </FeedbackMessage>
      ) : (
        !!submitError && (
          <FeedbackMessage>
            {extractErrorMessage(submitError, intl.formatMessage({ id: translations.errors.errorOccurred }))}
          </FeedbackMessage>
        )
      )}
    </div>
  );
};
