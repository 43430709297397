import { MinusCircleIcon } from '@heroicons/react/24/outline';
import { ChevronRightIcon } from '@heroicons/react/24/solid';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link as RouterLink, useNavigate, useParams } from 'react-router-dom';
import useSWR from 'swr';
import * as yup from 'yup';

import { apiClient } from '@/services/api';

import { translations } from '@/locales';

import { useModal } from '@/hooks/useModal';
import { page, size, useQueryParams } from '@/hooks/useQueryParams';

import { Button } from '@/components/Button';
import { EmptyList } from '@/components/EmptyList';
import { Form } from '@/components/Form';
import { InputWithLabel } from '@/components/Form/InputWithLabel';
import { ValidatedField } from '@/components/Form/ValidatedField';
import { Layout } from '@/components/Layout';
import { Link } from '@/components/Link';
import { LoadingOverlay } from '@/components/Loading';
import { LoadingButton } from '@/components/LoadingButton';
import { Modal } from '@/components/Modal';
import { ConfirmationModal, deleteIcon } from '@/components/Modal/ConfirmationModal';
import { SelectMembersModal } from '@/components/Modal/SelectMembersModal';
import { Pagination } from '@/components/Pagination';
import { MemberProfile } from '@/components/Profile';
import { SubmitFeedback } from '@/components/SubmitFeedback';
import { Table, TableBody, TableHeader, TableHeaderColumn, TableRow, TableRowColumn } from '@/components/Table/Table';

const config = {
  page,
  size
};

export const schema = yup
  .object({
    name: yup.string().trim().required().label(translations.fields.groupName)
  })
  .required();

export const GroupDetailsPage: React.FC = () => {
  const { groupId } = useParams();

  const [queryParams, updateQueryParams] = useQueryParams({ config });

  const navigate = useNavigate();

  const isNew = !groupId;

  const {
    data: loadedGroup,
    isLoading: loading,
    error,
    mutate: onReload
  } = useSWR(isNew ? null : 'groups/get-details', () => apiClient.group.getGroupDetails({ groupId: groupId! }), {
    revalidateIfStale: true,
    revalidateOnFocus: false,
    revalidateOnMount: true,
    revalidateOnReconnect: false
  });

  const {
    data: members,
    isLoading: membersLoading,
    error: membersError,
    mutate: onMembersReload
  } = useSWR(
    isNew ? null : ['groups/find-members', queryParams],
    () =>
      apiClient.group.findGroupMembers({ groupId: groupId!, pageNumber: queryParams.page, pageSize: queryParams.size }),
    {
      revalidateIfStale: true,
      revalidateOnFocus: false,
      revalidateOnMount: true,
      revalidateOnReconnect: false
    }
  );

  const onSubmit = React.useCallback(
    async (values: yup.InferType<typeof schema>) => {
      await apiClient.group
        .updateGroupDetails({ groupId: loadedGroup!.id, requestBody: values })
        .then(() => onReload());
    },
    [loadedGroup, onReload]
  );

  const intl = useIntl();

  const selectMembersModal = useModal(SelectMembersModal);

  const confirmationModal = useModal(ConfirmationModal);

  const removeMemberDialogModal = useModal(ConfirmationModal);

  const hasMembers = !!members && members.items.length > 0;

  const initialValues = loadedGroup;

  return (
    <Layout>
      <LoadingOverlay {...{ error, loading }}>
        <div className="flex items-center gap-5">
          <Link as={RouterLink} className="text-sm font-medium text-gray-500" to="/groups">
            <FormattedMessage id={translations.pages.groups.groups} />
          </Link>

          <ChevronRightIcon className="w-5 text-sm text-gray-400" />

          <Link as={RouterLink} className="text-sm font-medium text-gray-500" to="#">
            {loadedGroup?.name}
          </Link>
        </div>

        <Form {...{ initialValues, onSubmit, schema }}>
          {({ submitting, values, submitSucceeded, submitError, dirtySinceLastSubmit }) => (
            <div className="mt-5 space-y-6">
              <div className="flex items-center justify-between text-2xl font-semibold">
                <LoadingOverlay {...{ loading }} displayLoader>
                  {values?.name?.trim() || initialValues?.name?.trim()}
                </LoadingOverlay>

                <Button
                  appearance="deleteSecondary"
                  onClick={() => {
                    confirmationModal.open().catch(() => null);
                  }}
                  type="button"
                >
                  <span className="whitespace-nowrap">
                    <FormattedMessage id={translations.buttons.deleteGroup} />
                  </span>
                </Button>
              </div>

              <ValidatedField
                field={InputWithLabel}
                fieldClassName="w-1/2 min-w-[20rem]"
                id="name"
                label={<FormattedMessage id={translations.fields.groupName} />}
                name="name"
                placeholder={intl.formatMessage({ id: translations.pages.groups.createPlaceholder })}
                readOnly={submitting}
              />

              <div className="flex items-center gap-6">
                <Button appearance="primary" as={LoadingButton} className="w-48" loading={submitting} type="submit">
                  <FormattedMessage id={translations.buttons.save} />
                </Button>

                <SubmitFeedback {...{ submitError, submitSucceeded }} show={!submitting && !dirtySinceLastSubmit} />
              </div>
            </div>
          )}
        </Form>

        <LoadingOverlay displayLoader error={membersError} loading={membersLoading}>
          <div className="mt-10 space-y-4">
            <div className="flex justify-between text-lg">
              <FormattedMessage id={translations.pages.members.members} />

              {!!hasMembers && (
                <Button
                  appearance="secondary"
                  className="w-32"
                  onClick={() => {
                    selectMembersModal
                      .open()
                      .then()
                      .catch(() => null);
                  }}
                  type="button"
                >
                  <FormattedMessage id={translations.buttons.addMembers} />
                </Button>
              )}
            </div>

            {hasMembers ? (
              <Table>
                <TableHeader>
                  <TableRow>
                    <TableHeaderColumn>
                      <FormattedMessage id={translations.fields.name} />
                    </TableHeaderColumn>

                    <TableHeaderColumn />
                  </TableRow>
                </TableHeader>

                <TableBody>
                  {!!members &&
                    members?.items.map((member, index) => (
                      <>
                        <TableRow key={index}>
                          <TableRowColumn>
                            <MemberProfile
                              email={member.email}
                              firstName={member.firstName}
                              lastName={member.lastName}
                              src={member.image}
                            />
                          </TableRowColumn>

                          <TableRowColumn className="relative">
                            <Button
                              className="absolute right-8 -translate-y-1/2 transform shadow-none"
                              onClick={() => {
                                removeMemberDialogModal.open(member).catch(() => null);
                              }}
                            >
                              <MinusCircleIcon className="h-6 w-6" />
                            </Button>
                          </TableRowColumn>
                        </TableRow>
                      </>
                    ))}
                </TableBody>
              </Table>
            ) : (
              <EmptyList
                description={<FormattedMessage id={translations.emptyStates.addMembers} />}
                title={
                  <FormattedMessage
                    id={translations.emptyStates.noMembers}
                    values={{ value: intl.formatMessage({ id: translations.pages.members.members }).toLowerCase() }}
                  />
                }
              >
                <Button
                  appearance="secondary"
                  className="w-32"
                  onClick={() => {
                    selectMembersModal.open().catch(() => null);
                  }}
                  type="button"
                >
                  <FormattedMessage id={translations.buttons.addMembers} />
                </Button>
              </EmptyList>
            )}
          </div>

          {!!members && members.items.length > 0 && (
            <Pagination
              className="mt-9"
              currentPage={members.page.pageNumber}
              onPageChange={(page: number) => updateQueryParams({ page })}
              pageSize={members.page.pageSize}
              totalItems={members.page.total}
            />
          )}
        </LoadingOverlay>

        <Modal
          {...selectMembersModal.props}
          onInvite={async (groupIds, membersIds) => {
            await apiClient.group
              .addGroupMembers({
                groupId: loadedGroup!.id,
                requestBody: { groupIds, userIds: membersIds }
              })
              .then(() => onMembersReload());
          }}
        />

        <Modal
          {...removeMemberDialogModal.props}
          buttonMessage={<FormattedMessage id={translations.buttons.removeMember} />}
          description={
            <FormattedMessage
              id={translations.pages.members.removeMemberHelp}
              values={{
                value: removeMemberDialogModal.props.data?.firstName ?? removeMemberDialogModal.props.data?.email
              }}
            />
          }
          icon={<MinusCircleIcon className="h-10 w-10" />}
          onConfirmation={async (memberId) => {
            await apiClient.group
              .removeMembersFromGroup({
                groupId: loadedGroup!.id,
                requestBody: { userIds: [memberId!] }
              })
              .then(() => onMembersReload());
          }}
          title={<FormattedMessage id={translations.buttons.removeMember} />}
        />

        <Modal
          icon={deleteIcon}
          {...confirmationModal.props}
          buttonMessage={<FormattedMessage id={translations.buttons.deleteGroup} />}
          description={<FormattedMessage id={translations.pages.groups.deleteGroupHelp} />}
          onConfirmation={async () =>
            await apiClient.group.deleteGroup({ groupId: groupId! }).then(() => navigate('/groups'))
          }
          title={<FormattedMessage id={translations.buttons.deleteGroup} />}
        />
      </LoadingOverlay>
    </Layout>
  );
};
