import { MemberDto, MemberPageDto, UserPageDto } from '@billy/management-api-sdk';
import { ChevronDownIcon } from '@heroicons/react/24/outline';

import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import useSWR from 'swr';

import { apiClient } from '@/services/api';

import { translations } from '@/locales';

import { useModal } from '@/hooks/useModal';
import { page, search, size, status, useQueryParams } from '@/hooks/useQueryParams';
import { useRole } from '@/hooks/useRole';

import { Button } from '@/components/Button';
import { Dropdown } from '@/components/Dropdown';
import { DebouncedInput } from '@/components/Form/DebouncedInput';
import { SearchInput } from '@/components/Form/SearchInput';
import { Select } from '@/components/Form/Select';
import { Layout } from '@/components/Layout';
import { LoadingIndicator, LoadingOverlay } from '@/components/Loading';
import { Modal } from '@/components/Modal';
import { InvitationModal } from '@/components/Modal/InvitationModal';
import { Pagination } from '@/components/Pagination';
import { AdminMembersList } from '@/components/Table/AdminMembersList';
import { MembersList } from '@/components/Table/MembersList';
import { emailSchema } from '@/pages/CommunityDetailPage';

const config = { page, search, size, status };

export const MembersPage: React.FC = () => {
  const { community, isAdmin } = useRole();

  const location = useLocation();

  const params = new URLSearchParams(location.search);

  const communityId = params.get('community');

  const [queryParams, updateQueryParams] = useQueryParams({ config });

  const {
    isLoading: loading,
    error,
    data,
    mutate: onReload
  } = useSWR(['communities/find-members', queryParams], async () => {
    let result: MemberPageDto | UserPageDto;

    if (isAdmin) {
      if (communityId) {
        result = await apiClient.community.findCommunityMembers({
          communityId,
          pageNumber: queryParams.page,
          pageSize: queryParams.size,
          searchQuery: queryParams.search,
          status: queryParams.status
        });
      } else {
        result = await apiClient.user.findUsers({
          pageNumber: queryParams.page,
          pageSize: queryParams.size,
          searchQuery: queryParams.search,
          status: queryParams.status
        });
      }
    } else {
      result = await apiClient.community.findCommunityMembers({
        communityId: community!.id,
        pageNumber: queryParams.page,
        pageSize: queryParams.size,
        searchQuery: queryParams.search,
        status: queryParams.status
      });
    }

    return result;
  });

  const intl = useIntl();

  const isFilterActive = !!queryParams.status || !!queryParams.search;

  const hasMembers = data?.page.total !== 0 || isFilterActive;

  const noResultsFound = isFilterActive && data?.page.total === 0;

  const invitationModal = useModal(InvitationModal);

  return (
    <Layout>
      <div className="flex justify-between gap-4">
        <div className="flex items-center gap-3 text-2xl font-semibold">
          <FormattedMessage id={translations.pages.members.members} />

          {!!loading && <LoadingIndicator className="h-6 w-6" />}
        </div>

        {hasMembers && (
          <div className="flex items-center gap-6">
            <DebouncedInput
              as={SearchInput}
              className="w-72"
              initialValue={queryParams.search}
              onChange={(search: string) => updateQueryParams({ page: 1, search })}
              placeholder={intl.formatMessage({ id: translations.pages.members.searchMembers })}
            />

            <Select
              className="w-44"
              getSelectedDisplayName={({ selectedItems }) =>
                selectedItems[0]?.name ?? intl.formatMessage({ id: translations.pages.members.allMembers })
              }
              items={[
                { name: intl.formatMessage({ id: translations.pages.members.allMembers }), value: undefined },
                {
                  name: intl.formatMessage({ id: translations.pages.members.activeMembers }),
                  value: 'active'
                },
                { name: intl.formatMessage({ id: translations.pages.members.inactiveMembers }), value: 'inactive' }
              ]}
              onChange={(value: 'active' | 'inactive' | undefined) => updateQueryParams({ page: 1, status: value })}
              placeholder={intl.formatMessage({ id: translations.placeholders.selectStatus })}
              searchable
              value={queryParams.status}
            />

            {!isAdmin && (
              <Dropdown
                buttonText={
                  <span className="flex gap-3">
                    <FormattedMessage id={translations.buttons.addMembers} />

                    <ChevronDownIcon className="h-5 w-5 stroke-2" />
                  </span>
                }
              >
                <Button
                  className="w-full rounded-b-none py-2 px-4 text-sm hover:bg-gray-200"
                  onClick={() => {
                    invitationModal.open().catch(() => null);
                  }}
                >
                  <FormattedMessage id={translations.pages.members.addAMember} />
                </Button>

                <Button
                  as={RouterLink}
                  className="flex justify-center rounded-md rounded-t-none text-sm hover:bg-gray-200"
                  to="/import-members"
                >
                  <FormattedMessage id={translations.pages.members.importMembers} />
                </Button>
              </Dropdown>
            )}
          </div>
        )}
      </div>

      <div className="space-y-9">
        <LoadingOverlay {...{ error, loading }} className={error ? 'mt-24' : undefined}>
          {isAdmin && !communityId ? (
            <AdminMembersList className="mt-4" empty={!hasMembers} members={data?.items} noResults={noResultsFound} />
          ) : (
            <MembersList
              className="mt-4"
              empty={!hasMembers}
              members={data?.items as MemberDto[]}
              noResults={noResultsFound}
              onAction={() => {
                invitationModal.open().catch(() => null);
              }}
              {...{ onReload }}
              communityId={community?.id || (communityId as string | undefined)}
            />
          )}
        </LoadingOverlay>

        {!!data && !!hasMembers && (
          <Pagination
            className="mt-9"
            currentPage={data.page.pageNumber}
            onPageChange={(page: number) => updateQueryParams({ page })}
            pageSize={data.page.pageSize}
            totalItems={data.page.total}
          />
        )}
      </div>

      <Modal
        onInvite={async (email) => {
          await apiClient.community
            .addCommunityMembers({
              communityId: community!.id,
              requestBody: { emails: [email] }
            })
            .then(() => onReload());
        }}
        schema={emailSchema}
        successMessage={<FormattedMessage id={translations.pages.members.invitedMember} />}
        {...invitationModal.props}
        description={<FormattedMessage id={translations.pages.members.inviteMember} />}
        title={<FormattedMessage id={translations.buttons.addMembers} />}
      />
    </Layout>
  );
};
