import classnames from 'classnames';
import React from 'react';

import logo from '@/assets/univerz-app-icon.png';

export type Props = {
  className?: string;
  size?: 'sm' | 'md' | 'lg' | 'xl' | 'xxl';
};

export const Logo: React.FC<Props> = ({ className, size = 'xxl' }) => {
  const sizeClass = {
    lg: 'w-12 h-12',
    md: 'w-8 h-10',
    sm: 'w-8 h-8',
    xl: 'w-16 h-16',
    xxl: 'w-24 h-24'
  }[size];

  return <img alt="Univerz logo" className={classnames('rounded-md', className, sizeClass)} src={logo} />;
};
