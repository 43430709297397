import React from 'react';

import { useClickOutside } from '@/hooks/useClickOutside';

import { Button } from './Button';

type Props = {
  buttonText: React.ReactNode;
};

export const Dropdown: React.FC<React.PropsWithChildren<Props>> = ({ children, buttonText }) => {
  const { isOpen, setIsOpen, ref } = useClickOutside<HTMLDivElement>(false);

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="relative" ref={ref}>
      <Button appearance="primary" onClick={handleToggle}>
        {buttonText}
      </Button>

      {isOpen && <div className="absolute mt-2 w-full rounded-md bg-white shadow-lg">{children}</div>}
    </div>
  );
};
