/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BaseHttpRequest } from './core/BaseHttpRequest';
import type { OpenAPIConfig } from './core/OpenAPI';
import { AxiosHttpRequest } from './core/AxiosHttpRequest';

import { AudienceService } from './services/AudienceService';
import { BenefitService } from './services/BenefitService';
import { CommunityService } from './services/CommunityService';
import { EventService } from './services/EventService';
import { GroupService } from './services/GroupService';
import { MessageService } from './services/MessageService';
import { OnboardingService } from './services/OnboardingService';
import { ProfileService } from './services/ProfileService';
import { QuestionService } from './services/QuestionService';
import { ServiceService } from './services/ServiceService';
import { UserService } from './services/UserService';

type HttpRequestConstructor = new (config: OpenAPIConfig) => BaseHttpRequest;

export class ApiClient {

  public readonly audience: AudienceService;
  public readonly benefit: BenefitService;
  public readonly community: CommunityService;
  public readonly event: EventService;
  public readonly group: GroupService;
  public readonly message: MessageService;
  public readonly onboarding: OnboardingService;
  public readonly profile: ProfileService;
  public readonly question: QuestionService;
  public readonly service: ServiceService;
  public readonly user: UserService;

  public readonly request: BaseHttpRequest;

  constructor(config?: Partial<OpenAPIConfig>, HttpRequest: HttpRequestConstructor = AxiosHttpRequest) {
    this.request = new HttpRequest({
      BASE: config?.BASE ?? 'http://localhost:4000',
      VERSION: config?.VERSION ?? '1.0.0',
      WITH_CREDENTIALS: config?.WITH_CREDENTIALS ?? false,
      CREDENTIALS: config?.CREDENTIALS ?? 'include',
      TOKEN: config?.TOKEN,
      USERNAME: config?.USERNAME,
      PASSWORD: config?.PASSWORD,
      HEADERS: config?.HEADERS,
      ENCODE_PATH: config?.ENCODE_PATH,
    });

    this.audience = new AudienceService(this.request);
    this.benefit = new BenefitService(this.request);
    this.community = new CommunityService(this.request);
    this.event = new EventService(this.request);
    this.group = new GroupService(this.request);
    this.message = new MessageService(this.request);
    this.onboarding = new OnboardingService(this.request);
    this.profile = new ProfileService(this.request);
    this.question = new QuestionService(this.request);
    this.service = new ServiceService(this.request);
    this.user = new UserService(this.request);
  }
}

