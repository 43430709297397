import { translations } from '@/locales';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link as RouterLink } from 'react-router-dom';
import useSWR from 'swr';

import { apiClient } from '@/services/api';

import { page, search, size, status, useQueryParams } from '@/hooks/useQueryParams';
import { useRole } from '@/hooks/useRole';

import { Button } from '@/components/Button';
import { DebouncedInput } from '@/components/Form/DebouncedInput';
import { SearchInput } from '@/components/Form/SearchInput';
import { Layout } from '@/components/Layout';
import { LoadingIndicator, LoadingOverlay } from '@/components/Loading';
import { Pagination } from '@/components/Pagination';
import { MessagesList } from '@/components/Table/MessagesList';

const config = { page, search, size, status };

export const MessagesPage: React.FC = () => {
  const [queryParams, updateQueryParams] = useQueryParams({ config });

  const { community, isAdmin } = useRole();

  const {
    data,
    isLoading: loading,
    error
  } = useSWR(
    ['messages/find', queryParams],
    () =>
      apiClient.message.findMessages({
        communityId: isAdmin ? undefined : community?.id,
        pageNumber: queryParams.page,
        pageSize: queryParams.size,
        searchQuery: queryParams.search
      }),
    {
      revalidateIfStale: true,
      revalidateOnFocus: false,
      revalidateOnMount: true,
      revalidateOnReconnect: false
    }
  );

  const intl = useIntl();

  const isFilterActive = !!queryParams.search;

  const hasMessages = data?.page.total !== 0 || isFilterActive;

  const noResultsFound = isFilterActive && data?.page.total === 0;

  return (
    <Layout>
      <div className="flex justify-between">
        <div className="mr-4 flex items-center gap-3 text-2xl font-semibold">
          <FormattedMessage id={translations.pages.messages.messages} />

          {!!loading && <LoadingIndicator className="h-6 w-6" />}
        </div>

        {!!hasMessages && (
          <div className="flex items-center gap-6">
            <DebouncedInput
              as={SearchInput}
              className="w-72"
              initialValue={queryParams.search}
              onChange={(search: string) => updateQueryParams({ page: 1, search })}
              placeholder={intl.formatMessage({ id: translations.pages.messages.searchMessages })}
            />

            <Button appearance="primary" as={RouterLink} className="flex w-44 justify-center" to="/messages/new">
              <FormattedMessage id={translations.buttons.sendNewMessage} />
            </Button>
          </div>
        )}
      </div>

      <div className="space-y-9">
        <LoadingOverlay {...{ error, loading }} className={error ? 'mt-24' : undefined}>
          <MessagesList
            className="max-w-screen-3xl mt-4"
            empty={!hasMessages}
            messages={data?.items}
            noResults={noResultsFound}
          />
        </LoadingOverlay>

        {!!data && !!hasMessages && (
          <Pagination
            className="mt-9"
            currentPage={data.page.pageNumber}
            onPageChange={(page: number) => updateQueryParams({ page })}
            pageSize={data.page.pageSize}
            totalItems={data.page.total}
          />
        )}
      </div>
    </Layout>
  );
};
