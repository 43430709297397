import { CommunityDto } from '@billy/management-api-sdk';
import { PhotoIcon } from '@heroicons/react/24/outline';
import classnames from 'classnames';
import React from 'react';
import { FormattedDate, FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router';
import * as yup from 'yup';

import { apiClient } from '@/services/api';

import { translations } from '@/locales';
import { uploadFile } from '@/utils/functions';

import { useRole } from '@/hooks/useRole';

import { AsyncButton } from '@/components/AsyncButton';
import { Avatar } from '@/components/Avatar';
import { Button } from '@/components/Button';
import { Form } from '@/components/Form';
import { DragAndDropFile } from '@/components/Form/DragAndDropFile';
import { getSourceFromPossibleValue, ImageFileInput, ImageValue } from '@/components/Form/ImageFileInput';
import { InputWithLabel } from '@/components/Form/InputWithLabel';
import { TextArea } from '@/components/Form/TextArea';
import { ValidatedField } from '@/components/Form/ValidatedField';
import { LoadingButton } from '@/components/LoadingButton';
import { SubmitFeedback } from '@/components/SubmitFeedback';

const maxSizeInMB = 10;
const minImageWidth = 1024;
const minImageHeight = 1024;

export const minLogoWidth = 512;
export const minLogoHeight = 512;

export const schema = yup
  .object({
    contacts: yup
      .array()
      .of(
        yup
          .object({
            email: yup.string().email().required().label(translations.fields.emailAddress),
            name: yup.string().trim().required().label(translations.fields.fullName),
            phone: yup.string().trim().required().label(translations.fields.phone)
          })
          .required()
      )
      .required(),
    description: yup.string().trim().required().label(translations.fields.communityDescription.label),
    image: yup
      .mixed<ImageValue | string>()
      .test({
        exclusive: true,
        message: translations.customValidation.maxFileSize,
        name: 'maxFileSize',
        params: { max: maxSizeInMB },
        test: (value) => typeof value === 'string' || !value?.file || value.file.size <= maxSizeInMB * 1024 * 1024
      })
      .test({
        exclusive: true,
        message: translations.customValidation.minImageResolution,
        name: 'minImageResolution',
        params: { height: minLogoHeight, width: minLogoWidth },
        test: (value) =>
          typeof value === 'string' ||
          !value?.file ||
          (value.image.width >= minLogoWidth && value.image.height >= minLogoHeight)
      })
      .label(translations.fields.logo)
      .required(),
    membershipCardImage: yup
      .mixed()
      .test({
        exclusive: true,
        message: translations.customValidation.maxFileSize,
        name: 'maxFileSize',
        params: { max: maxSizeInMB },
        test: (value) => typeof value === 'string' || !value?.file || value.file.size <= maxSizeInMB * 1024 * 1024
      })
      .test({
        exclusive: true,
        message: translations.customValidation.minImageResolution,
        name: 'minImageResolution',
        params: { height: minImageHeight, width: minImageWidth },
        test: (value) =>
          typeof value === 'string' ||
          !value?.file ||
          (value.image.width >= minImageWidth && value.image.height >= minImageHeight)
      })
      .required()
      .label(translations.fields.membershipCard),
    name: yup.string().trim().required().label(translations.fields.communityName)
  })
  .required();

export const CommunityPage: React.FC<{ community?: CommunityDto }> = ({ community }) => {
  const { isAdmin } = useRole();

  const navigate = useNavigate();

  const initialValues = community;

  const logoUpload = async (image: string | ImageValue, communityId: string) => {
    if (typeof image === 'string') return image;
    else {
      const intent = await apiClient.community.initiateCommunityLogoUpload({
        communityId,
        requestBody: { mimeType: image.file.type }
      });

      await uploadFile(intent.upload.url, intent.upload.headers, image.file);

      return intent.asset;
    }
  };

  const membershipCardUpload = async (membershipCard: string | ImageValue, communityId: string) => {
    if (typeof membershipCard === 'string') return membershipCard;
    else {
      const intent = await apiClient.community.initiateCommunityMembershipCardUpload({
        communityId,
        requestBody: { mimeType: membershipCard.file.type }
      });

      await uploadFile(intent.upload.url, intent.upload.headers, membershipCard.file);

      return intent.asset;
    }
  };

  const onSubmit = React.useCallback(
    async (values: yup.InferType<typeof schema>) => {
      const image = await logoUpload(values.image, community!.id);
      const membership = await membershipCardUpload(values.membershipCardImage, community!.id);

      const requestBody = {
        ...values,
        image,
        membership
      };

      await apiClient.community.updateCommunityDetails({ communityId: community!.id, requestBody });
    },
    [community]
  );

  return (
    <Form {...{ initialValues, onSubmit, schema }}>
      {({ values, submitting, submitError, form, submitSucceeded, dirtySinceLastSubmit }) => (
        <div className="space-y-6">
          <div className="text-2xl font-semibold text-gray-900">
            <div className="flex items-center justify-between">
              {values?.name?.trim() || initialValues?.name?.trim()}

              {!!isAdmin && (
                <AsyncButton
                  appearance="basic"
                  onClick={async () => {
                    await apiClient.community.suspendCommunity({ communityId: community!.id });

                    navigate('/communities');
                  }}
                >
                  <FormattedMessage id={translations.buttons.suspend} values={{ active: community?.active }} />
                </AsyncButton>
              )}
            </div>

            <div className="text-sm font-normal text-gray-500">
              <FormattedMessage id={translations.fields.createdOn} />{' '}
              <FormattedDate day="2-digit" month="2-digit" value={initialValues?.createdAt} year="numeric" />
            </div>
          </div>

          <div className="max-w-3xl">
            <div className="mb-4 text-lg font-medium text-gray-900">
              <FormattedMessage id={translations.pages.pageSections.generalInformation} />
            </div>

            <div className="space-y-6">
              <ValidatedField
                accept="image/png,image/jpeg"
                disabled={!!submitting || !!isAdmin}
                field={ImageFileInput}
                fieldClassName="inline-flex"
                id="image"
                name="image"
              >
                <div className="flex cursor-pointer items-center gap-4">
                  <Avatar alt="presentation" size="xl" src={getSourceFromPossibleValue(values?.image)} />

                  <Button appearance="basic" as="button" className="pointer-events-none" type="button">
                    <FormattedMessage
                      id={values.image ? translations.buttons.changeLogo : translations.buttons.addLogo}
                    />
                  </Button>
                </div>
              </ValidatedField>

              <ValidatedField
                field={InputWithLabel}
                id="name"
                label={<FormattedMessage id={translations.fields.communityName} />}
                name="name"
                readOnly={!!submitting || !!isAdmin}
              />

              <ValidatedField
                as={TextArea}
                field={InputWithLabel}
                fieldClassName="h-36 min-h-[6rem]"
                help={<FormattedMessage id={translations.fields.communityDescription.helper} />}
                id="description"
                label={<FormattedMessage id={translations.fields.communityDescription.label} />}
                name="description"
                readOnly={!!submitting || !!isAdmin}
              />

              <ValidatedField
                accept="image/png,image/jpeg"
                as={ImageFileInput}
                disabled={!!submitting || !!isAdmin}
                field={InputWithLabel}
                fieldClassName="inline-flex w-fit"
                id="membershipCardImage"
                label={<FormattedMessage id={translations.fields.membershipCard} />}
                name="membershipCardImage"
              >
                <DragAndDropFile onChange={(files) => form.change('membershipCardImage', files)}>
                  {({ dragging }) => (
                    <div className="flex gap-3">
                      <div
                        className={classnames(
                          'flex h-40 w-80 flex-col',
                          'items-center justify-center rounded-md border-dashed border-gray-300',
                          { 'border-2': !values.membershipCardImage },
                          dragging ? 'bg-gray-100' : 'bg-white'
                        )}
                      >
                        {values.membershipCardImage ? (
                          <img
                            alt="card-preview"
                            className="h-full w-full rounded-md object-cover"
                            src={getSourceFromPossibleValue(values?.membershipCardImage)}
                          />
                        ) : (
                          <>
                            <PhotoIcon className="h-12 w-12 text-gray-400" />

                            <div className="text-indigo-600">
                              <FormattedMessage id={translations.fields.uploadImage} />
                            </div>

                            <div className="text-xs font-normal leading-4 text-gray-500">
                              <FormattedMessage
                                id={translations.pages.community.imageValidation}
                                values={{ ...{ maxSizeInMB, minImageHeight, minImageWidth } }}
                              />
                            </div>
                          </>
                        )}
                      </div>

                      {!!values.membershipCardImage && (
                        <Button
                          appearance="basic"
                          className=" pointer-events-none cursor-pointer self-end"
                          type="button"
                        >
                          <FormattedMessage id={translations.buttons.change} />
                        </Button>
                      )}
                    </div>
                  )}
                </DragAndDropFile>
              </ValidatedField>
            </div>
          </div>

          <div className="max-w-3xl space-y-6">
            <div className="text-lg font-medium text-gray-900">
              {<FormattedMessage id={translations.pages.community.contactPersons} />}
            </div>

            <div className="space-y-6">
              {values.contacts?.map((_, index) => (
                <div className="group flex items-center gap-4" key={index}>
                  <ValidatedField
                    className="w-full"
                    field={InputWithLabel}
                    id="name"
                    label={<FormattedMessage id={translations.fields.fullName} />}
                    name={`contacts.${index}.name`}
                    readOnly={submitting || !!isAdmin}
                  />

                  <ValidatedField
                    className="w-full"
                    field={InputWithLabel}
                    id="email"
                    label={<FormattedMessage id={translations.fields.emailAddress} />}
                    name={`contacts.${index}.email`}
                    readOnly={submitting || !!isAdmin}
                  />

                  <ValidatedField
                    className="w-full"
                    field={InputWithLabel}
                    id="phone"
                    label={<FormattedMessage id={translations.fields.phone} />}
                    name={`contacts.${index}.phone`}
                    readOnly={submitting || !!isAdmin}
                  />

                  {!isAdmin && (
                    <Button
                      appearance="basic"
                      className="w-48 self-end opacity-0 group-hover:opacity-100"
                      onClick={() => {
                        form.change(
                          'contacts',
                          values.contacts?.filter((_, i) => i !== index)
                        );
                      }}
                      type="button"
                    >
                      <FormattedMessage id={translations.buttons.remove} />
                    </Button>
                  )}
                </div>
              ))}

              {!isAdmin && (
                <Button
                  appearance="secondary"
                  className="mt-2 w-48"
                  onClick={() => {
                    form.change('contacts', [...values.contacts, { email: '', name: '', phone: '' }]);
                  }}
                  type="button"
                >
                  <FormattedMessage id={translations.buttons.addOption} />
                </Button>
              )}
            </div>
          </div>

          {!isAdmin && (
            <div className="mt-14 flex items-center gap-4">
              <Button appearance="primary" as={LoadingButton} className="w-48" loading={submitting} type="submit">
                <FormattedMessage id={translations.buttons.save} />
              </Button>

              <SubmitFeedback {...{ submitError, submitSucceeded }} show={!submitting && !dirtySinceLastSubmit} />
            </div>
          )}
        </div>
      )}
    </Form>
  );
};
