import { MagnifyingGlassIcon } from '@heroicons/react/24/outline';
import classnames from 'classnames';
import React from 'react';

import { AsComponent } from '@/components/As';

import { Input, Props } from './Input';

export const SearchInput = React.forwardRef(function SearchInput<T extends AsComponent>(
  props: Props<T>,
  ref: React.Ref<any>
) {
  return (
    <label className={classnames('relative', props.className)} htmlFor={props.id}>
      <Input {...props} {...{ ref }} className="pl-10" type="text" />

      <MagnifyingGlassIcon className="pointer-events-none absolute left-3 top-1/2 h-5 w-5 -translate-y-1/2 text-gray-400" />
    </label>
  );
});
