/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type ServiceMemberDto = {
  id: string;
  email: string;
  firstName?: string;
  lastName?: string;
  image?: string;
  addedAt: string;
  status: ServiceMemberDto.status;
};

export namespace ServiceMemberDto {

  export enum status {
    UNPAID = 'unpaid',
    PAID = 'paid',
  }


}

