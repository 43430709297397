import React from 'react';
import { Avatar } from './Avatar';

export type Props = {
  firstName?: string;
  lastName?: string;
  email: string;
  src?: string;
};

export const MemberProfile: React.FC<Props> = ({ firstName, lastName, email, src }) => {
  return (
    <div className="inline-flex items-center">
      <Avatar {...{ src }} alt="profile-image" size="lg" />

      <div className="flex flex-col px-4">
        <span className="font-medium text-neutral-800">
          {firstName} {lastName}
        </span>

        <span className="text-neutral-500">{email}</span>
      </div>
    </div>
  );
};
