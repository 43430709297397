import { translations } from '@/locales';
import { BenefitCategoriesDto } from '@billy/management-api-sdk';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link as RouterLink } from 'react-router-dom';
import useSWR from 'swr';

import { apiClient } from '@/services/api';

import { category, page, search, size, useQueryParams } from '@/hooks/useQueryParams';
import { useRole } from '@/hooks/useRole';

import { Button } from '@/components/Button';
import { DebouncedInput } from '@/components/Form/DebouncedInput';
import { SearchInput } from '@/components/Form/SearchInput';
import { Select } from '@/components/Form/Select';
import { Layout } from '@/components/Layout';
import { LoadingIndicator, LoadingOverlay } from '@/components/Loading';
import { Pagination } from '@/components/Pagination';
import { BenefitsList } from '@/components/Table/BenefitsList';

const config = { category, page, search, size };

export const BenefitsPage: React.FC = () => {
  const { community } = useRole();

  const [queryParams, updateQueryParams] = useQueryParams({ config });

  const resolveCategoryIdToName = React.useCallback((categoryId: string, categories: BenefitCategoriesDto) => {
    const category = categories?.find((category) => category.id === categoryId);
    return category?.name;
  }, []);

  const {
    isLoading: loading,
    error,
    data
  } = useSWR(['benefits/find', queryParams], () =>
    apiClient.benefit.findBenefits({
      categoryId: queryParams.category,
      communityId: community?.id,
      pageNumber: queryParams.page,
      pageSize: queryParams.size,
      searchQuery: queryParams.search
    })
  );

  const {
    data: loadedCategories,
    isLoading: loadingCategories,
    error: categoriesError
  } = useSWR(
    'get-categories',
    () =>
      apiClient.benefit.getBenefitCategories({
        communityId: community!.id
      }),
    {
      revalidateIfStale: true,
      revalidateOnFocus: false,
      revalidateOnMount: true,
      revalidateOnReconnect: false
    }
  );

  const categories = React.useMemo(
    () =>
      loadedCategories?.map((category) => {
        return { name: category.name, value: category.id };
      }) ?? [],
    [loadedCategories]
  );

  const benefitsListData = React.useMemo(
    () =>
      data?.items?.map((benefit) => {
        return { ...benefit, category: resolveCategoryIdToName(benefit.categoryId, loadedCategories!) };
      }) ?? [],
    [data?.items, loadedCategories, resolveCategoryIdToName]
  );

  const intl = useIntl();

  const isFilterActive = !!queryParams.search || queryParams.category;

  const hasBenefits = data?.page.total !== 0 || isFilterActive;

  const noResultsFound = isFilterActive && data?.page.total === 0;

  return (
    <Layout>
      <div className="flex items-center justify-between">
        <div className="mr-4 flex items-center gap-3 text-2xl font-semibold">
          <FormattedMessage id={translations.pages.benefits.benefits} />

          {(!!loading || loadingCategories) && <LoadingIndicator className="w-6" />}
        </div>

        {!!hasBenefits && (
          <div className="flex items-center gap-6">
            <DebouncedInput
              as={SearchInput}
              className="w-72"
              initialValue={queryParams.search}
              onChange={(search: string) => updateQueryParams({ page: 1, search })}
              placeholder={intl.formatMessage({ id: translations.pages.benefits.searchBenefits })}
            />

            <LoadingOverlay error={categoriesError} loading={loadingCategories}>
              <Select
                className="w-48"
                getSelectedDisplayName={({ selectedItems }) =>
                  selectedItems[0]?.name ?? intl.formatMessage({ id: translations.pages.benefits.allCategories })
                }
                items={[
                  { name: intl.formatMessage({ id: translations.pages.benefits.allCategories }), value: undefined },
                  ...categories
                ]}
                onChange={(value: string | undefined) => updateQueryParams({ category: value, page: 1 })}
                placeholder={intl.formatMessage({ id: translations.pages.benefits.selectCategory })}
                searchable
                value={queryParams.category}
              />
            </LoadingOverlay>

            <Button appearance="primary" as={RouterLink} className="flex w-44 justify-center" to="/benefits/new">
              <FormattedMessage id={translations.buttons.addBenefit} />
            </Button>
          </div>
        )}
      </div>

      <div className="space-y-9">
        <LoadingOverlay {...{ error, loading }} className={error ? 'mt-24' : undefined}>
          <BenefitsList
            benefits={benefitsListData}
            className="mt-4"
            empty={!hasBenefits}
            noResults={!!noResultsFound}
          />
        </LoadingOverlay>

        {!!data && !!hasBenefits && (
          <Pagination
            className="mt-9"
            currentPage={data.page.pageNumber}
            onPageChange={(page: number) => updateQueryParams({ page })}
            pageSize={data.page.pageSize}
            totalItems={data.page.total}
          />
        )}
      </div>
    </Layout>
  );
};
