import { translations } from '@/locales';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link as RouterLink } from 'react-router-dom';
import useSWR from 'swr';

import { apiClient } from '@/services/api';

import { page, search, size, status, useQueryParams } from '@/hooks/useQueryParams';
import { useRole } from '@/hooks/useRole';

import { Button } from '@/components/Button';
import { DebouncedInput } from '@/components/Form/DebouncedInput';
import { SearchInput } from '@/components/Form/SearchInput';
import { Layout } from '@/components/Layout';
import { LoadingIndicator, LoadingOverlay } from '@/components/Loading';
import { Pagination } from '@/components/Pagination';
import { GroupsList } from '@/components/Table/GroupsList';

const config = { page, search, size, status };

export const GroupsPage: React.FC = () => {
  const { community, isAdmin } = useRole();

  const [queryParams, updateQueryParams] = useQueryParams({ config });

  const {
    isLoading: loading,
    error,
    data
  } = useSWR(['groups/find', queryParams], () =>
    apiClient.group.findGroups({
      communityId: isAdmin ? undefined : community?.id,
      pageNumber: queryParams.page,
      pageSize: queryParams.size,
      searchQuery: queryParams.search
    })
  );

  const intl = useIntl();

  const isFilterActive = !!queryParams.search;

  const hasGroups = data?.page.total !== 0 || isFilterActive;

  const noResults = isFilterActive && data?.page.total === 0;

  return (
    <Layout>
      <div className="flex justify-between">
        <div className="mr-4 flex items-center gap-3 text-2xl font-semibold">
          <FormattedMessage id={translations.pages.groups.groups} />

          {!!loading && <LoadingIndicator className="h-6 w-6" />}
        </div>

        {!!hasGroups && (
          <div className="flex items-center gap-6">
            <DebouncedInput
              as={SearchInput}
              className="w-72"
              initialValue={queryParams.search}
              onChange={(search: string) => updateQueryParams({ page: 1, search })}
              placeholder={intl.formatMessage({ id: translations.pages.groups.searchGroups })}
            />

            <Button appearance="primary" as={RouterLink} className="flex w-44 justify-center" to="/groups/new">
              <FormattedMessage id={translations.buttons.createGroup} />
            </Button>
          </div>
        )}
      </div>

      <div className="space-y-9">
        <LoadingOverlay {...{ error, loading }} className={error ? 'mt-24' : undefined}>
          <GroupsList className="mt-4" groups={data?.items} {...{ empty: !hasGroups, noResults }} />
        </LoadingOverlay>

        {!!data && !!hasGroups && (
          <Pagination
            className="mt-9"
            currentPage={data.page.pageNumber}
            onPageChange={(page: number) => updateQueryParams({ page })}
            pageSize={data.page.pageSize}
            totalItems={data.page.total}
          />
        )}
      </div>
    </Layout>
  );
};
