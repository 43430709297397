import { translations } from '@/locales';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link as RouterLink } from 'react-router-dom';
import useSWR from 'swr';

import { apiClient } from '@/services/api';

import { page, search, size, status, useQueryParams } from '@/hooks/useQueryParams';

import { Button } from '@/components/Button';
import { DebouncedInput } from '@/components/Form/DebouncedInput';
import { SearchInput } from '@/components/Form/SearchInput';
import { Layout } from '@/components/Layout';
import { LoadingIndicator, LoadingOverlay } from '@/components/Loading';
import { Pagination } from '@/components/Pagination';
import { QuestionsList } from '@/components/Table/QuestionsList';

const config = { page, search, size, status };

export const QuestionsPage: React.FC = () => {
  const [queryParams, updateQueryParams] = useQueryParams({ config });

  const {
    isLoading: loading,
    error,
    data
  } = useSWR(['questions/find', queryParams], () =>
    apiClient.question.findQuestions({
      pageNumber: queryParams.page,
      pageSize: queryParams.size,
      searchQuery: queryParams.search
    })
  );

  const intl = useIntl();

  const isFilterActive = !!queryParams.search;

  const hasQuestions = data?.page.total !== 0 || isFilterActive;

  const noResultsFound = isFilterActive && data?.page.total === 0;

  return (
    <Layout>
      <div className="flex justify-between gap-4">
        <div className="flex items-center gap-3 text-2xl font-semibold">
          <FormattedMessage id={translations.pages.questions.questions} />

          {!!loading && <LoadingIndicator className="h-6 w-6" />}
        </div>

        {!!hasQuestions && (
          <div className="flex items-center gap-6">
            <DebouncedInput
              as={SearchInput}
              className="w-72"
              initialValue={queryParams.search}
              onChange={(search: string) => updateQueryParams({ page: 1, search })}
              placeholder={intl.formatMessage({ id: translations.pages.questions.searchQuestions })}
            />

            <Button appearance="primary" as={RouterLink} className="flex w-44 justify-center" to="/questions/new">
              <FormattedMessage id={translations.buttons.addQuestion} />
            </Button>
          </div>
        )}
      </div>

      <div className="space-y-9">
        <LoadingOverlay {...{ error, loading }} className={error ? 'mt-24' : undefined}>
          <QuestionsList className="mt-4" empty={!hasQuestions} noResults={noResultsFound} questions={data?.items} />
        </LoadingOverlay>

        {!!data && hasQuestions && (
          <Pagination
            className="mt-9"
            currentPage={data.page.pageNumber}
            onPageChange={(page: number) => updateQueryParams({ page })}
            pageSize={data.page.pageSize}
            totalItems={data.page.total}
          />
        )}
      </div>
    </Layout>
  );
};
