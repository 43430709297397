import classnames from 'classnames';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

import { As, AsComponent, AsProps } from './As';

export const Link = React.forwardRef(
  <T extends AsComponent>(
    {
      // @ts-ignore
      as = { RouterLink },
      className,
      children,
      ...rest
    }: AsProps<T>,
    ref: React.Ref<HTMLElement>
  ) => (
    <As {...{ as, ref }} className={classnames('text-sm font-medium', className)} {...rest}>
      {children}
    </As>
  )
);
