import { CheckIcon } from '@heroicons/react/24/solid';
import classnames from 'classnames';
import React from 'react';

export interface Props {
  active?: boolean;
  isSelected?: boolean;
  disabled?: boolean;
  className?: string;
  onClick?(): void;
}

export const SelectItem = React.forwardRef<HTMLLIElement, React.PropsWithChildren<Props>>(
  ({ active, isSelected, disabled, className, onClick, children, ...rest }, ref) => (
    <li
      {...rest}
      {...{ ref }}
      className={classnames(
        `relative select-none py-2 pl-4 pr-4`,
        active && 'bg-gray-200',
        disabled ? `cursor-default` : `cursor-pointer`,
        className,
        'group'
      )}
      onClick={disabled ? undefined : onClick}
    >
      <span className="block truncate">{children}</span>

      {isSelected && (
        <span className="absolute inset-y-0 right-0 flex items-center pr-4 text-indigo-600">
          <CheckIcon aria-hidden="true" className="h-5 w-5 font-extrabold" />
        </span>
      )}
    </li>
  )
);
