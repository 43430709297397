/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateQuestionDto } from '../models/CreateQuestionDto';
import type { QuestionDto } from '../models/QuestionDto';
import type { QuestionMemberPageDto } from '../models/QuestionMemberPageDto';
import type { QuestionPageDto } from '../models/QuestionPageDto';
import type { UploadIntentDto } from '../models/UploadIntentDto';
import type { UploadRequestBody } from '../models/UploadRequestBody';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class QuestionService {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Return a page of questions.
   * @returns QuestionPageDto Returns a page of questions.
   * @throws ApiError
   */
  public findQuestions({
    searchQuery,
    pageSize,
    pageNumber,
  }: {
    /**
     * text to search by.
     */
    searchQuery?: string,
    pageSize?: number,
    pageNumber?: number,
  }): CancelablePromise<QuestionPageDto> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/questions/find',
      query: {
        'searchQuery': searchQuery,
        'pageSize': pageSize,
        'pageNumber': pageNumber,
      },
      errors: {
        401: `Credentials are missing or invalid.`,
      },
    });
  }

  /**
   * Create a question.
   * @returns QuestionDto Returns the created question.
   * @throws ApiError
   */
  public createQuestion({
    requestBody,
  }: {
    requestBody: CreateQuestionDto,
  }): CancelablePromise<QuestionDto> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/questions/create',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        401: `Credentials are missing or invalid.`,
      },
    });
  }

  /**
   * Initiate question image upload.
   * @returns UploadIntentDto Returns an upload intent.
   * @throws ApiError
   */
  public initiateQuestionUpload({
    requestBody,
  }: {
    /**
     * Only `image/jpeg`, `image/png` are accepted as mimeType.
     */
    requestBody: UploadRequestBody,
  }): CancelablePromise<UploadIntentDto> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/questions/initiate-image-upload',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        401: `Credentials are missing or invalid.`,
        403: `The action is not allowed.`,
      },
    });
  }

  /**
   * Return a question
   * @returns QuestionDto Returns the question.
   * @throws ApiError
   */
  public getQuestionDetails({
    questionId,
  }: {
    /**
     * The id of the question.
     */
    questionId: string,
  }): CancelablePromise<QuestionDto> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/questions/get-details',
      query: {
        'questionId': questionId,
      },
      errors: {
        401: `Credentials are missing or invalid.`,
      },
    });
  }

  /**
   * Deletes a question
   * @returns void
   * @throws ApiError
   */
  public deleteQuestion({
    questionId,
  }: {
    /**
     * The id of the question.
     */
    questionId: string,
  }): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/questions/delete',
      query: {
        'questionId': questionId,
      },
      errors: {
        401: `Credentials are missing or invalid.`,
      },
    });
  }

  /**
   * Returns the members of this audience.
   * @returns QuestionMemberPageDto Returns a page of members part of this audience.
   * @throws ApiError
   */
  public findQuestionAudienceMembers({
    questionId,
    pageSize,
    pageNumber,
  }: {
    /**
     * The id of the question.
     */
    questionId: string,
    pageSize?: number,
    pageNumber?: number,
  }): CancelablePromise<QuestionMemberPageDto> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/questions/find-audience-members',
      query: {
        'questionId': questionId,
        'pageSize': pageSize,
        'pageNumber': pageNumber,
      },
      errors: {
        401: `Credentials are missing or invalid.`,
        404: `Resource not found.`,
      },
    });
  }

}
