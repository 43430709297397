import { ServicePreviewDto } from '@billy/management-api-sdk';
import React from 'react';
import { FormattedDate, FormattedMessage, FormattedNumber } from 'react-intl';
import { Link as RouterLink } from 'react-router-dom';

import { translations } from '@/locales';

import { Button } from '@/components/Button';
import { EmptyList } from '@/components/EmptyList';
import { NoResultsFound } from '@/components/NoResultsFound';

import { Table, TableBody, TableHeader, TableHeaderColumn, TableRow, TableRowColumn } from './Table';

type Props = {
  services?: ServicePreviewDto[];
  className?: string;
  empty?: boolean;
  noResults?: boolean;
};

export const ServicesList: React.FC<Props> = ({ services, empty, noResults, className }) => {
  return empty ? (
    <EmptyList
      description={<FormattedMessage id={translations.emptyStates.createService} />}
      title={<FormattedMessage id={translations.emptyStates.noServices} />}
    >
      <Button appearance="primary" as={RouterLink} className="flex w-48 justify-center" to="/services/new">
        <FormattedMessage id={translations.buttons.createService} />
      </Button>
    </EmptyList>
  ) : (
    <Table {...{ className }}>
      <TableHeader>
        <TableRow>
          <TableHeaderColumn>
            <FormattedMessage id={translations.pages.services.service} />
          </TableHeaderColumn>

          <TableHeaderColumn>
            <FormattedMessage id={translations.fields.paid} />
          </TableHeaderColumn>

          <TableHeaderColumn>
            <FormattedMessage id={translations.fields.price} />
          </TableHeaderColumn>

          <TableHeaderColumn>
            <FormattedMessage id={translations.fields.dateCreated} />
          </TableHeaderColumn>
        </TableRow>
      </TableHeader>

      <TableBody>
        {noResults ? (
          <TableRow>
            <td colSpan={4}>
              <NoResultsFound />
            </td>
          </TableRow>
        ) : (
          services?.map((service, index) => (
            <RouterLink className="table-row hover:bg-indigo-50" key={index} to={`/services/${service.id}`}>
              <TableRowColumn className="whitespace-nowrap">{service.name}</TableRowColumn>

              <TableRowColumn className="whitespace-nowrap">
                <div className="flex gap-1">
                  <span className="text-sm text-gray-900">
                    {service.paidCount} / {service.memberCount}
                  </span>

                  <span className="text-sm font-normal lowercase text-gray-500">
                    <FormattedMessage id={translations.pages.members.members} />
                  </span>
                </div>

                <span className="text-sm font-normal lowercase text-gray-500">
                  {!Number.isNaN(service.paidCount / service.memberCount) ? (
                    Math.round((service.paidCount / service.memberCount) * 100) + '%'
                  ) : (
                    <FormattedMessage id={translations.emptyStates.noMembers} />
                  )}
                </span>
              </TableRowColumn>

              <TableRowColumn>
                <FormattedNumber
                  currency={service.price.currency}
                  maximumFractionDigits={2}
                  minimumFractionDigits={2}
                  style="currency"
                  value={service.price.amount / 100}
                />
              </TableRowColumn>

              <TableRowColumn>
                <FormattedDate day="2-digit" month="2-digit" value={service.createdAt} year="numeric" />
              </TableRowColumn>
            </RouterLink>
          ))
        )}
      </TableBody>
    </Table>
  );
};
