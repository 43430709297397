import classnames from 'classnames';
import React from 'react';
import { FormattedDate, FormattedMessage } from 'react-intl';

import { CommunityManagerDto, CommunityManagersDto, StatusDto } from '@billy/management-api-sdk';

import { apiClient } from '@/services/api';

import { Status } from '@/constants/status';
import { translations } from '@/locales';

import { AsyncButton } from '@/components/AsyncButton';
import { Badge } from '@/components/Badge';

import { Table, TableBody, TableHeader, TableHeaderColumn, TableRow, TableRowColumn } from './Table';

type Props = {
  managers: CommunityManagersDto;
  communityId: string;
  className?: string;
  onReload(): void;
};

export const ManagersList: React.FC<Props> = ({ managers, onReload, communityId, className }) => {
  return (
    <Table {...{ className }}>
      <TableHeader>
        <TableRow>
          <TableHeaderColumn>
            <FormattedMessage id={translations.fields.name} />
          </TableHeaderColumn>

          <TableHeaderColumn>
            <FormattedMessage id={translations.fields.emailAddress} />
          </TableHeaderColumn>

          <TableHeaderColumn>
            <FormattedMessage id={translations.fields.dateAdded} />
          </TableHeaderColumn>

          <TableHeaderColumn>
            <FormattedMessage id={translations.fields.status} />
          </TableHeaderColumn>

          <TableHeaderColumn />
        </TableRow>
      </TableHeader>

      <TableBody>
        {managers.map((manager: CommunityManagerDto, index: number) => (
          <TableRow key={index}>
            <TableRowColumn>
              {manager.firstName} {manager.lastName}
            </TableRowColumn>

            <TableRowColumn>{manager.email}</TableRowColumn>

            <TableRowColumn>
              <FormattedDate day="2-digit" month="2-digit" value={manager.createdAt} year="numeric" />
            </TableRowColumn>

            <TableRowColumn>
              <Badge appearance={manager.status as StatusDto}>
                <FormattedMessage
                  id={translations.statuses.active}
                  values={{ active: manager.status === Status.ACTIVE }}
                />
              </Badge>
            </TableRowColumn>

            <TableRowColumn>
              <AsyncButton
                appearance="basic"
                className={classnames({ 'text-indigo-400': !(manager.status === Status.ACTIVE) })}
                onClick={async () => {
                  manager.status === Status.ACTIVE
                    ? await apiClient.community
                        .suspendCommunityManagers({
                          communityId,
                          requestBody: { userIds: [manager.id] }
                        })
                        .then(onReload)
                    : await apiClient.community
                        .addCommunityManagers({
                          communityId,
                          requestBody: { emails: [manager.email] }
                        })
                        .then(onReload);
                }}
              >
                <FormattedMessage
                  id={translations.buttons.suspend}
                  values={{ active: manager.status === Status.ACTIVE }}
                />
              </AsyncButton>
            </TableRowColumn>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};
