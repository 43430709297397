/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type CommunityPreviewDto = {
  id: string;
  name: string;
  createdAt: string;
  status: CommunityPreviewDto.status;
};

export namespace CommunityPreviewDto {

  export enum status {
    ACTIVE = 'active',
    INACTIVE = 'inactive',
  }


}

