import React from 'react';
import { FormattedMessage } from 'react-intl';
import * as yup from 'yup';

import { apiClient } from '@/services/api';

import { translations } from '@/locales';

import { Auth } from '@/containers/use-auth';

import { Button } from '@/components/Button';
import { Form } from '@/components/Form';
import { InputWithLabel } from '@/components/Form/InputWithLabel';
import { ValidatedField } from '@/components/Form/ValidatedField';
import { Layout } from '@/components/Layout';
import { LoadingButton } from '@/components/LoadingButton';
import { SubmitFeedback } from '@/components/SubmitFeedback';

export const schema = yup
  .object({
    email: yup.string().trim().email().required().label(translations.fields.emailAddress),
    firstName: yup.string().trim().required().label(translations.fields.firstName),
    lastName: yup.string().trim().required().label(translations.fields.lastName)
  })
  .required();

export const MyProfilePage: React.FC = () => {
  const { context, logout, reload } = Auth.useContainer();

  const onSubmit = React.useCallback(
    async (values: yup.InferType<typeof schema>) => {
      await apiClient.profile.updateProfileDetails({ requestBody: values }).then(() => reload());
    },
    [reload]
  );

  const initialValues = { email: context?.email, firstName: context?.firstName, lastName: context?.lastName };

  return (
    <Layout>
      <div className="text-2xl font-semibold">
        <FormattedMessage id={translations.pages.profile.myProfile} />
      </div>

      <Form className="min-w-[200px] max-w-xl" {...{ initialValues, onSubmit, schema }}>
        {({ submitting, submitError, dirtySinceLastSubmit, submitSucceeded }) => (
          <div className="space-y-6">
            <ValidatedField
              field={InputWithLabel}
              label={<FormattedMessage id={translations.fields.emailAddress} />}
              name="email"
              type="email"
            />

            <div className="flex gap-4">
              <ValidatedField
                className="w-full"
                field={InputWithLabel}
                id="firstName"
                label={<FormattedMessage id={translations.fields.firstName} />}
                name="firstName"
                readOnly={submitting}
                type="text"
              />

              <ValidatedField
                className="w-full"
                field={InputWithLabel}
                id="lastName"
                label={<FormattedMessage id={translations.fields.lastName} />}
                name="lastName"
                readOnly={submitting}
                type="text"
              />
            </div>

            <div className="flex items-center gap-4">
              <Button appearance="primary" as={LoadingButton} className="w-[49%]" loading={submitting} type="submit">
                <FormattedMessage id={translations.buttons.save} />
              </Button>

              <SubmitFeedback {...{ submitError, submitSucceeded }} show={!submitting && !dirtySinceLastSubmit} />
            </div>
          </div>
        )}
      </Form>

      <Button appearance="secondary" className="w-36" onClick={() => logout()}>
        <FormattedMessage id={translations.buttons.logout} />
      </Button>
    </Layout>
  );
};
