import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useLocation } from 'react-router';
import * as yup from 'yup';

import { authApiClient } from '@/services/auth-api';

import { translations } from '@/locales';

import { Auth } from '@/containers/use-auth';

import { Button } from '@/components/Button';
import { CenteredBoxLayout } from '@/components/CenteredBoxLayout';
import { Form } from '@/components/Form';
import { InputWithLabel } from '@/components/Form/InputWithLabel';
import { PasswordInput } from '@/components/Form/PasswordInput';
import { ValidatedField } from '@/components/Form/ValidatedField';
import { LoadingButton } from '@/components/LoadingButton';
import { SubmitFeedback } from '@/components/SubmitFeedback';

export const schema = yup
  .object({
    password: yup.string().trim().required().label(translations.fields.password),
    repeatPassword: yup
      .string()
      .trim()
      .oneOf([yup.ref('password')], translations.customValidation.matchPasswords)
      .required()
      .label(translations.fields.repeatPassword)
  })
  .required();

export const ResetPasswordPage: React.FC = () => {
  const { loginWithPassword } = Auth.useContainer();
  const location = useLocation();

  const params = new URLSearchParams(location.search);

  const [token, email] = [params.get('token'), params.get('email')];

  const onSubmit = React.useCallback(
    async (values: yup.InferType<typeof schema>) => {
      await authApiClient.authentication
        .resetPassword({
          requestBody: { password: values.password, token: token! }
        })
        .then(async () => await loginWithPassword({ email: email!, password: values.password }));
    },
    [email, loginWithPassword, token]
  );

  return (
    <CenteredBoxLayout
      subhead={<FormattedMessage id={translations.pages.resetPassword.subhead} />}
      title={<FormattedMessage id={translations.pages.resetPassword.title} />}
    >
      <Form {...{ onSubmit, schema }}>
        {({ submitting, submitError, submitSucceeded, dirtySinceLastSubmit }) => (
          <div className="space-y-6">
            <ValidatedField
              as={PasswordInput}
              field={InputWithLabel}
              id="password"
              label={<FormattedMessage id={translations.fields.newPassword} />}
              name="password"
              readOnly={submitting}
              type="password"
            />

            <ValidatedField
              as={PasswordInput}
              field={InputWithLabel}
              id="repeatPassword"
              label={<FormattedMessage id={translations.fields.repeatPassword} />}
              name="repeatPassword"
              readOnly={submitting}
              type="password"
            />

            <Button appearance="primary" as={LoadingButton} className="w-full" loading={submitting} type="submit">
              <FormattedMessage id={translations.pages.resetPassword.cta} />
            </Button>

            <SubmitFeedback {...{ submitError, submitSucceeded }} show={!submitting && !dirtySinceLastSubmit} />
          </div>
        )}
      </Form>
    </CenteredBoxLayout>
  );
};
