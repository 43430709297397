import { GroupMemberDto, MemberPageDto, UserPageDto } from '@billy/management-api-sdk';
import { FormApi } from 'final-form';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import useSWR from 'swr';
import * as yup from 'yup';

import { apiClient } from '@/services/api';

import { translations } from '@/locales';

import { useRole } from '@/hooks/useRole';

import { Badge } from '@/components/Badge';
import { Button } from '@/components/Button';
import { FeedbackMessage } from '@/components/FeedbackMessage';
import { Form } from '@/components/Form';
import { InputWithLabel } from '@/components/Form/InputWithLabel';
import { RenderItemProps, Select } from '@/components/Form/Select';
import { SelectItem } from '@/components/Form/SelectItem';
import { ValidatedField } from '@/components/Form/ValidatedField';
import { LoadingOverlay } from '@/components/Loading';
import { LoadingButton } from '@/components/LoadingButton';

import { InstanceProps } from './Modal';
import { ModalHeader } from './ModalHeader';
import { ModalLayout } from './ModalLayout';

interface ModalProps extends InstanceProps<object | null> {
  onInvite(groupsIds: string[], membersIds: string[]): Promise<void>;
}

type GroupItemType = {
  name: string;
  value: string;
};

type MemberItemType = {
  name: string;
  value: GroupMemberDto;
};

const schema = yup
  .object()
  .shape(
    {
      groupsIds: yup
        .array()
        .of(yup.string().required().label(translations.fields.id))
        .when('members', {
          is: (value: unknown[]) => !!value && value.length > 0,
          otherwise: yup.array().min(1, translations.customValidation.membersSelect).required()
        }),
      members: yup
        .array()
        .of(
          yup.object({
            email: yup.string().email().required().label(translations.fields.emailAddress),
            firstName: yup.string().label(translations.fields.firstName),
            id: yup.string().required().label(translations.fields.id),
            lastName: yup.string().label(translations.fields.lastName)
          })
        )
        .when('groupsIds', {
          is: (value: unknown[]) => !!value && value.length > 0,
          otherwise: yup.array().min(1, translations.customValidation.membersSelect).required()
        })
    },
    [['groupsIds', 'members']]
  )
  .required();

export const SelectMembersModal: React.FC<ModalProps> = ({ onClose, onInvite, className }) => {
  const { community, isAdmin } = useRole();

  const {
    data: members,
    isLoading: membersLoading,
    error: membersError
  } = useSWR(
    'get-members',
    async () => {
      let result: MemberPageDto | UserPageDto;

      if (isAdmin) result = await apiClient.user.findUsers({});
      else
        result = await apiClient.community.findCommunityMembers({
          communityId: community!.id
        });

      return result;
    },
    {
      revalidateIfStale: true,
      revalidateOnFocus: false,
      revalidateOnMount: true,
      revalidateOnReconnect: false
    }
  );

  const {
    data: groups,
    isLoading: groupsLoading,
    error: groupsError
  } = useSWR('get-groups', () => apiClient.group.findGroups({ communityId: isAdmin ? undefined : community!.id }), {
    revalidateIfStale: true,
    revalidateOnFocus: false,
    revalidateOnMount: true,
    revalidateOnReconnect: false
  });

  const intl = useIntl();

  const membersItems = members?.items?.map((member) => {
    return {
      name: `${member.firstName ?? ''} ${member.lastName ?? ''}`,
      value: member
    };
  });

  const groupsItems = groups?.items?.map((group) => {
    return {
      name: group.name,
      value: group.id
    };
  });

  const initialValues = { groupsIds: [], members: [] };

  const onSubmit = React.useCallback(
    async (values: yup.InferType<typeof schema>, form: FormApi) => {
      await onInvite(
        values.groupsIds,
        values.members.map((member: GroupMemberDto) => member.id)
      );
      form.restart();
      onClose();
    },
    [onClose, onInvite]
  );

  return (
    <ModalLayout {...{ className }}>
      <LoadingOverlay error={membersError || groupsError} loading={membersLoading || groupsLoading}>
        <ModalHeader>
          <FormattedMessage id={translations.buttons.addMembers} />
        </ModalHeader>

        <Form {...{ initialValues, onSubmit, schema }}>
          {({ submitting, submitError, dirtySinceLastSubmit }) => (
            <div className="space-y-80">
              <div className="space-y-6">
                <ValidatedField
                  as={Select}
                  field={InputWithLabel}
                  getSelectedDisplayName={({ selectedItems }: { selectedItems: GroupItemType[] }) =>
                    selectedItems!.length > 0
                      ? intl.formatMessage({ id: translations.select.itemsSelected }, { count: selectedItems.length })
                      : intl.formatMessage({ id: translations.select.groupsPlaceholder })
                  }
                  help={<FormattedMessage id={translations.select.groupsHelp} />}
                  id="groupsIds"
                  items={groupsItems ?? []}
                  label={<FormattedMessage id={translations.pages.groups.groups} />}
                  multiple
                  name="groupsIds"
                  placeholder={intl.formatMessage({ id: translations.select.groupsPlaceholder })}
                  renderItem={({ props: { key, ...props }, item }: RenderItemProps<GroupItemType>) => (
                    <SelectItem key={key} {...props}>
                      <Badge appearance="group">{item.name}</Badge>
                    </SelectItem>
                  )}
                  searchable
                />

                <ValidatedField
                  as={Select}
                  field={InputWithLabel}
                  getSelectedDisplayName={({ selectedItems }: { selectedItems: MemberItemType[] }) =>
                    selectedItems.length > 0
                      ? intl.formatMessage({ id: translations.select.itemsSelected }, { count: selectedItems.length })
                      : intl.formatMessage({ id: translations.select.membersPlaceholder })
                  }
                  help={<FormattedMessage id={translations.select.membersHelp} />}
                  id="members"
                  items={membersItems ?? []}
                  label={<FormattedMessage id={translations.pages.members.members} />}
                  multiple
                  name="members"
                  placeholder={intl.formatMessage({ id: translations.select.membersPlaceholder })}
                  renderItem={({ props: { key, ...props }, item }: RenderItemProps<MemberItemType>) => (
                    <SelectItem key={key} {...props}>
                      {item.name} <span className="text-gray-400">{item.value.email}</span>
                    </SelectItem>
                  )}
                  searchable
                />
              </div>

              <div className="flex gap-4">
                <Button appearance="basic" className="w-full" onClick={onClose} type="button">
                  <FormattedMessage id={translations.buttons.cancel} />
                </Button>

                <Button appearance="primary" as={LoadingButton} className="w-full" loading={submitting} type="submit">
                  <FormattedMessage id={translations.buttons.add} />
                </Button>
              </div>

              {!dirtySinceLastSubmit && !!submitError && !submitting && (
                <FeedbackMessage>{submitError.body.message}</FeedbackMessage>
              )}
            </div>
          )}
        </Form>
      </LoadingOverlay>
    </ModalLayout>
  );
};
