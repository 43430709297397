import React, { useState } from 'react';

import { Props as ButtonProps } from './Button';
import { LoadingButton } from './LoadingButton';

export type Props = ButtonProps & {
  onClick(): Promise<void>;
  className?: string;
  type?: string;
};

export const AsyncButton: React.FC<React.PropsWithChildren<Props>> = ({ children, type = 'button', ...props }) => {
  const [loading, setLoading] = useState(false);

  const handleClick = async (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();

    setLoading(true);

    props
      .onClick()
      .then(() => setLoading(false))
      .catch(() => {
        setLoading(false);
      });
  };

  return (
    <LoadingButton {...props} {...{ loading, type }} onClick={handleClick}>
      {children}
    </LoadingButton>
  );
};
