import React from 'react';
import { Logo } from './Logo';

export type Props = {
  title: React.ReactNode;
  subhead?: React.ReactNode;
};

export const CenteredBoxLayout = ({ title, children, subhead }: React.PropsWithChildren<Props>) => (
  <div className="flex h-screen flex-col items-center bg-gray-50">
    <Logo className="mt-14" />

    <div className="mt-16 text-center text-3xl font-extrabold text-gray-900">{title}</div>

    {!!subhead && <div className="mt-1 text-center text-base font-normal text-gray-900">{subhead}</div>}

    <div className="mt-8 w-full max-w-md rounded-lg bg-white px-6 py-8 shadow-md sm:px-10">{children}</div>
  </div>
);
