import { MessageAttachmentResourceType } from '@billy/management-api-sdk';
import { MinusCircleIcon } from '@heroicons/react/24/outline';
import { ChevronRightIcon, PaperClipIcon } from '@heroicons/react/24/solid';
import React from 'react';
import { FormattedDate, FormattedMessage, useIntl } from 'react-intl';
import { Link as RouterLink, useNavigate, useParams } from 'react-router-dom';
import useSWR from 'swr';
import * as yup from 'yup';

import { apiClient } from '@/services/api';

import { MessageResource } from '@/constants';

import { uploadFile } from '@/utils/functions';

import { translations } from '@/locales';

import { useModal } from '@/hooks/useModal';
import { page, size, useQueryParams } from '@/hooks/useQueryParams';
import { useRole } from '@/hooks/useRole';

import { AsyncButton } from '@/components/AsyncButton';
import { Button } from '@/components/Button';
import { EmptyList } from '@/components/EmptyList';
import { Form } from '@/components/Form';
import { Checkbox } from '@/components/Form/Checkbox';
import { ImageValue } from '@/components/Form/ImageFileInput';
import { InputWithLabel } from '@/components/Form/InputWithLabel';
import { MaxLengthIndicator } from '@/components/Form/MaxLengthIndicator';
import { isImage, isPDF, MixedFileInput } from '@/components/Form/MixedFileInput';
import { TextArea } from '@/components/Form/TextArea';
import { ValidatedField } from '@/components/Form/ValidatedField';
import { Layout } from '@/components/Layout';
import { Link } from '@/components/Link';
import { LoadingOverlay } from '@/components/Loading';
import { LoadingButton } from '@/components/LoadingButton';
import { Modal } from '@/components/Modal';
import { ConfirmationModal, deleteIcon } from '@/components/Modal/ConfirmationModal';
import { SelectMembersModal } from '@/components/Modal/SelectMembersModal';
import { Pagination } from '@/components/Pagination';
import { MemberProfile } from '@/components/Profile';
import { SubmitFeedback } from '@/components/SubmitFeedback';
import { Table, TableBody, TableHeader, TableHeaderColumn, TableRow, TableRowColumn } from '@/components/Table/Table';

const maxSizeInMB = 10;
const minImageWidth = 1024;
const minImageHeight = 1024;

export const schema = yup.object({
  attachment: yup
    .mixed()
    .test({
      exclusive: true,
      message: translations.customValidation.maxFileSize,
      name: 'maxFileSize',
      params: { max: maxSizeInMB },
      test: (value) =>
        typeof value === 'string' ||
        !value ||
        (isImage(value) && value.file.size <= maxSizeInMB * 1024 * 1024) ||
        (isPDF(value) && value.size <= maxSizeInMB * 1024 * 1024)
    })
    .test({
      exclusive: true,
      message: translations.customValidation.minImageResolution,
      name: 'minImageResolution',
      params: { height: minImageHeight, width: minImageWidth },
      test: (value) =>
        typeof value === 'string' ||
        !value?.file ||
        (isImage(value) && value.image.width >= minImageWidth && value.image.height >= minImageHeight)
    })
    .label(translations.fields.attachment),
  content: yup.string().trim().max(800).required().label(translations.fields.messageContent),
  isBroadcast: yup.boolean().label(translations.fields.broadcast)
});

const config = { page, size };

const SUCCESS = 'success';

export const MessageDetailsPage: React.FC = () => {
  const [sendResult, setSendResult] = React.useState<string | Error>();

  const { messageId } = useParams();

  const { community, isAdmin } = useRole();

  const selectMembersModal = useModal(SelectMembersModal);

  const confirmationModal = useModal(ConfirmationModal);

  const removeMemberDialogModal = useModal(ConfirmationModal);

  const intl = useIntl();

  const [queryParams, updateQueryParams] = useQueryParams({ config });

  const isNew = !messageId;

  const {
    data: loadedMessage,
    isLoading: loading,
    error,
    mutate: onReload
  } = useSWR(
    isNew ? null : ['messages/get-details', messageId],
    () => apiClient.message.getMessageDetails({ messageId: messageId! }),
    {
      revalidateIfStale: true,
      revalidateOnFocus: false,
      revalidateOnMount: true,
      revalidateOnReconnect: false
    }
  );

  const {
    data: loadedMembers,
    isLoading: loadingMembers,
    error: membersError,
    mutate: onMembersReload
  } = useSWR(
    isNew ? null : ['messages/find-recipients', loadedMessage, queryParams, messageId],
    () =>
      apiClient.message.findMessageRecipients({
        messageId: loadedMessage!.id,
        pageNumber: queryParams.page,
        pageSize: queryParams.size
      }),
    {
      revalidateIfStale: true,
      revalidateOnFocus: false,
      revalidateOnMount: true,
      revalidateOnReconnect: false
    }
  );

  const members = loadedMembers?.items ?? [];

  const initialValues = loadedMessage;

  const navigate = useNavigate();

  const attachmentUpload = React.useCallback(async (communityId: string, attachment?: string | File | ImageValue) => {
    if (!attachment) return;

    const attachmentFile = isImage(attachment) ? attachment.file : attachment;

    if (typeof attachmentFile === 'string') return attachmentFile;
    else {
      const intent = await apiClient.message.initiateMessageAttachmentUpload({
        communityId,
        requestBody: { mimeType: attachmentFile.type }
      });

      await uploadFile(intent.upload.url, intent.upload.headers, attachmentFile);

      return intent.asset;
    }
  }, []);

  const onSubmit = React.useCallback(
    async (values: yup.InferType<typeof schema>) => {
      isNew
        ? await attachmentUpload(community!.id, values.attachment).then(
            async (attachment) =>
              await apiClient.message
                .createMessage({
                  communityId: isAdmin ? undefined : community?.id,
                  requestBody: {
                    ...values,
                    attachment
                  }
                })

                .then((message) => navigate(`/messages/${message.id}`))
          )
        : await attachmentUpload(community!.id, values.attachment).then(
            async (attachment) =>
              await apiClient.message.updateMessage({
                messageId,
                requestBody: {
                  ...values,
                  attachment
                }
              })
          );
    },

    [attachmentUpload, community, isAdmin, isNew, messageId, navigate]
  );

  return (
    <Layout>
      <LoadingOverlay {...{ error, loading }}>
        <div className="mb-5 flex gap-5">
          <Link as={RouterLink} className="text-sm font-medium text-gray-500" to="/messages">
            <FormattedMessage id={translations.pages.messages.messages} />
          </Link>

          <ChevronRightIcon className="w-5 text-sm text-gray-400" />

          <Link as={RouterLink} className="max-w-[12rem] truncate text-sm font-medium text-gray-500" to={{}}>
            {initialValues?.content || <FormattedMessage id={translations.pages.messages.newMessage} />}
          </Link>
        </div>

        <Form className="flex flex-col justify-between space-y-10" {...{ initialValues, onSubmit, schema }}>
          {({ submitting, form, values, submitError, dirtySinceLastSubmit, submitSucceeded }) => {
            const showCheckbox = !!isAdmin;

            return (
              <>
                <div>
                  <div className="flex justify-between">
                    <LoadingOverlay displayLoader error={membersError || error} loading={loading || loadingMembers}>
                      <div className="max-w-lg truncate text-2xl font-semibold">
                        {values?.content?.trim() || initialValues?.content?.trim() || (
                          <FormattedMessage id={translations.pages.messages.newMessage} />
                        )}
                      </div>
                    </LoadingOverlay>

                    {!isNew && (
                      <Button
                        appearance="deleteSecondary"
                        onClick={() => {
                          confirmationModal.open().catch(() => null);
                        }}
                        type="button"
                      >
                        <span className="whitespace-nowrap">
                          <FormattedMessage id={translations.buttons.deleteMessage} />
                        </span>
                      </Button>
                    )}
                  </div>

                  {!!initialValues?.sentAt && (
                    <div className="text-sm font-normal text-gray-500">
                      <FormattedMessage id={translations.fields.sentAt} />{' '}
                      <span className="uppercase">
                        <FormattedDate
                          day="2-digit"
                          hour="numeric"
                          hour12={true}
                          minute="numeric"
                          month="2-digit"
                          value={initialValues?.sentAt}
                          year="numeric"
                        />
                      </span>
                    </div>
                  )}
                </div>

                <MaxLengthIndicator className="w-3/5 min-w-[576px]" max={800} name="content">
                  <ValidatedField
                    as={TextArea}
                    field={InputWithLabel}
                    fieldClassName="h-36 min-h-[6rem]"
                    id="content"
                    label={<FormattedMessage id={translations.pages.messages.message} />}
                    name="content"
                    readOnly={!!submitting}
                  />
                </MaxLengthIndicator>

                <ValidatedField
                  accept="image/png,image/jpeg,application/pdf"
                  as={MixedFileInput}
                  disabled={
                    !!submitting || (!isNew && initialValues?.resourceType && initialValues?.resourceType !== 'file')
                  }
                  field={InputWithLabel}
                  help={!!isNew && <FormattedMessage id={translations.pages.messages.attachmentHelp} />}
                  id="attachment"
                  label={
                    <>
                      <FormattedMessage id={translations.fields.attachment} />{' '}
                      <span className="text-sm font-medium text-gray-400">
                        ({intl.formatMessage({ id: translations.customValidation.optional }).toLowerCase()})
                      </span>
                    </>
                  }
                  name="attachment"
                  onRemove={() => form.change('attachment', undefined)}
                >
                  {!values.attachment
                    ? (!!isNew ||
                        (initialValues?.resourceType !== MessageAttachmentResourceType.SERVICE &&
                          initialValues?.resourceType !== MessageAttachmentResourceType.EVENT)) && (
                        <div className="w-fit cursor-pointer">
                          <Button
                            appearance="basic"
                            className="pointer-events-none mt-4 flex cursor-pointer gap-2"
                            type="button"
                          >
                            <PaperClipIcon className="h-5 w-5 text-gray-500" />
                            <FormattedMessage id={translations.buttons.uploadFile} />
                          </Button>
                          <div className="mt-2 text-xs font-normal leading-4 text-gray-500">
                            <FormattedMessage
                              id={translations.pages.messages.attachmentValidation}
                              values={{ ...{ maxSizeInMB, minImageHeight, minImageWidth } }}
                            />
                          </div>
                        </div>
                      )
                    : !isAdmin &&
                      initialValues?.resourceType !== 'file' &&
                      typeof initialValues?.attachment === 'string' && (
                        <div className="mt-2">
                          <Button
                            appearance="secondary"
                            as={RouterLink}
                            to={
                              initialValues?.resourceType === (MessageResource.SERVICE as string)
                                ? `/services/${initialValues?.attachment}`
                                : `/events/${initialValues?.attachment}`
                            }
                          >
                            <FormattedMessage id={translations.buttons.open} /> {initialValues?.resourceType}
                          </Button>
                        </div>
                      )}
                </ValidatedField>

                {!!showCheckbox && (
                  <ValidatedField
                    disabled={!!submitting || !isNew}
                    field={Checkbox}
                    id="isBroadcast"
                    label={<FormattedMessage id={translations.fields.broadcast} />}
                    name="isBroadcast"
                    onChange={() => form.change('isBroadcast', !values.isBroadcast)}
                    type="checkbox"
                  />
                )}

                {!isNew && !values.isBroadcast && (
                  <>
                    <div className="space-y-4">
                      <div className="flex justify-between text-lg">
                        <FormattedMessage id={translations.pages.pageSections.audience} />

                        {members.length > 0 && (
                          <Button
                            appearance="secondary"
                            className="w-32"
                            onClick={() => {
                              selectMembersModal.open().catch(() => null);
                            }}
                            type="button"
                          >
                            <FormattedMessage id={translations.buttons.addMembers} />
                          </Button>
                        )}
                      </div>

                      {members.length > 0 ? (
                        <Table>
                          <TableHeader>
                            <TableRow>
                              <TableHeaderColumn>
                                <FormattedMessage id={translations.fields.name} />
                              </TableHeaderColumn>

                              <TableHeaderColumn />
                            </TableRow>
                          </TableHeader>

                          <TableBody>
                            {members.map((member, index) => (
                              <TableRow key={index}>
                                <TableRowColumn>
                                  <MemberProfile
                                    email={member.email}
                                    firstName={member.firstName}
                                    lastName={member.lastName}
                                    src={member.image}
                                  />
                                </TableRowColumn>

                                <TableRowColumn className="relative">
                                  <Button
                                    className="absolute right-8 -translate-y-1/2 transform shadow-none"
                                    onClick={() => {
                                      removeMemberDialogModal.open(member).catch(() => null);
                                    }}
                                    type="button"
                                  >
                                    <MinusCircleIcon className="h-6 w-6" />
                                  </Button>
                                </TableRowColumn>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      ) : (
                        <EmptyList
                          description={<FormattedMessage id={translations.emptyStates.addMembers} />}
                          title={<FormattedMessage id={translations.emptyStates.noMembers} />}
                        >
                          <Button
                            appearance="secondary"
                            className="w-32"
                            onClick={() => {
                              selectMembersModal.open().catch(() => null);
                            }}
                            type="button"
                          >
                            <FormattedMessage id={translations.buttons.addMembers} />
                          </Button>
                        </EmptyList>
                      )}
                    </div>

                    {!!loadedMembers && members.length > 0 && (
                      <Pagination
                        className="mt-9"
                        currentPage={loadedMembers.page.pageNumber}
                        onPageChange={(page: number) => updateQueryParams({ page })}
                        pageSize={loadedMembers.page.pageSize}
                        totalItems={loadedMembers.page.total}
                      />
                    )}

                    <Modal
                      {...selectMembersModal.props}
                      onInvite={async (groupIds, userIds) => {
                        await apiClient.message
                          .addMessageRecipients({
                            messageId: loadedMessage!.id,
                            requestBody: { groupIds, userIds }
                          })
                          .then(() => onMembersReload());
                      }}
                    />
                  </>
                )}

                <div className="mt-6 max-w-fit space-y-4">
                  <div className="flex gap-6">
                    <Button appearance="basic" className="w-48" onClick={() => form.restart()} type="button">
                      <FormattedMessage id={translations.buttons.cancel} />
                    </Button>

                    <Button appearance="primary" as={LoadingButton} className="w-48" loading={submitting} type="submit">
                      <FormattedMessage id={translations.buttons.save} />
                    </Button>
                  </div>

                  {!initialValues?.sentAt && !isNew && !sendResult && (
                    <AsyncButton
                      appearance="secondary"
                      className="w-full"
                      onClick={async () => {
                        await apiClient.message
                          .sendMessage({ messageId: loadedMessage!.id })
                          .then(() => {
                            onReload();
                            setSendResult(SUCCESS);
                          })
                          .catch((error) => setSendResult(error));
                      }}
                    >
                      <FormattedMessage id={translations.buttons.send} />
                    </AsyncButton>
                  )}

                  <SubmitFeedback show={!submitting && !dirtySinceLastSubmit} {...{ submitError, submitSucceeded }} />

                  <SubmitFeedback
                    show={!!sendResult}
                    submitSucceeded={sendResult === SUCCESS}
                    successMessage={<FormattedMessage id={translations.pages.messages.sentSuccessfully} />}
                  />
                </div>
              </>
            );
          }}
        </Form>

        <Modal
          {...removeMemberDialogModal.props}
          buttonMessage={<FormattedMessage id={translations.buttons.removeMember} />}
          description={
            <FormattedMessage
              id={translations.pages.members.removeMemberHelp}
              values={{
                value: removeMemberDialogModal.props.data?.firstName ?? removeMemberDialogModal.props.data?.email
              }}
            />
          }
          icon={<MinusCircleIcon className="h-10 w-10" />}
          onConfirmation={async (memberId) => {
            await apiClient.message
              .removeRecipientsFromMessage({
                messageId: loadedMessage!.id,
                requestBody: { userIds: [memberId!] }
              })
              .then(() => onMembersReload());
          }}
          title={<FormattedMessage id={translations.buttons.removeMember} />}
        />
      </LoadingOverlay>

      <Modal
        icon={deleteIcon}
        {...confirmationModal.props}
        buttonMessage={<FormattedMessage id={translations.buttons.deleteMessage} />}
        description={<FormattedMessage id={translations.pages.messages.deleteMessageHelp} />}
        onConfirmation={async () =>
          await apiClient.message.deleteMessage({ messageId: loadedMessage!.id }).then(() => navigate('/messages'))
        }
        title={<FormattedMessage id={translations.buttons.deleteMessage} />}
      />
    </Layout>
  );
};
