import dayjs from 'dayjs';
import * as React from 'react';
import { createAutoCorrectedDatePipe } from 'text-mask-addons';

import { getValueFromEvent, MaskedInput } from './MaskedInput';

type PotentialDate = string | Date | null | undefined;

export interface Props {
  value?: PotentialDate;
  mask: string;
  onChange(value?: PotentialDate): void;
}
export const parseDateString = (dateString: string, format: 'DD/MM/YYYY' | 'MM/DD/YYYY') => dayjs(dateString, format);

export const convertToDateObject = (dateString: string) => {
  const [day, month, year] = dateString.split('/');
  const formattedDate = `${year}-${month}-${day}`;
  return dayjs(formattedDate).toDate();
};

const parsePotentialDate = <T extends PotentialDate>(
  value: PotentialDate,
  format: string,
  callback: (date: dayjs.Dayjs) => T
) => {
  if (!value) return value;
  if (typeof value === 'string') return value;

  const date = dayjs(value, format, true);

  if (!date.isValid()) return value;

  return callback(date);
};

// text-mask is using dd/mm/yyyy HH:MM while dayjs is using DD/MM/YYYY HH:mm
const flipCasing = (mask: string) => {
  return mask.replace(/[a-zA-Z]/g, (letter) => {
    if (letter === 'H') return letter;

    const lowercased = letter.toLowerCase();

    if (lowercased !== letter) return lowercased;

    return letter.toUpperCase();
  });
};

export const DATE_FORMAT = 'DD/MM/YYYY';
export const TIME_FORMAT = 'HH:mm';

export const DateInput = React.forwardRef(function DateInput(props: Props, ref: React.Ref<any>) {
  const { mask, pipe } = React.useMemo(() => {
    const pipe = createAutoCorrectedDatePipe(flipCasing(props.mask));
    const mask = props.mask.split('').map((letter) => (/[a-zA-Z]/.test(letter) ? /\d/ : letter));

    return { mask, pipe };
  }, [props]);

  const value = React.useMemo(
    () => parsePotentialDate(props.value, props.mask, (date) => date.format(props.mask)),
    [props.value, props.mask]
  );

  const onChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const value = getValueFromEvent(event);

      return props.onChange?.(
        parsePotentialDate(value, props.mask, (date) => {
          return date.format(props.mask);
        })
      );
    },
    [props]
  );

  return <MaskedInput {...props} {...{ mask, onChange, pipe, ref, value }} />;
});
