import { CheckCircleIcon } from '@heroicons/react/24/outline';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import { translations } from '@/locales';

import { deleteIcon } from './Modal/ConfirmationModal';

export const successIcon = (
  <div className="flex h-10 w-10 items-center justify-center rounded-full bg-green-100 p-2">
    <CheckCircleIcon className="h-6 w-6 stroke-2 text-lg text-green-600" />
  </div>
);

export type ProcessResultProps = {
  result: 'succcess' | 'failed' | 'wrongFormat';
  membersImported?: number;
  errorCount?: number;
};

export const ProcessResult: React.FC<ProcessResultProps> = ({ result, membersImported, errorCount }) => {
  return result === 'succcess' ? (
    <div className="flex gap-4">
      {successIcon}

      <div>
        <FormattedMessage id={translations.pages.members.successfullyProcessed} />

        <div className="text-sm font-normal text-gray-500">
          <FormattedMessage id={translations.pages.members.membersAdded} values={{ count: membersImported }} />
        </div>
      </div>
    </div>
  ) : result === 'failed' ? (
    <div className="flex gap-4">
      {deleteIcon}

      <div>
        <FormattedMessage id={translations.pages.members.errorsFound} values={{ count: errorCount }} />

        <div className="text-sm font-normal text-gray-500">
          <FormattedMessage id={translations.pages.members.fixIssues} values={{ count: membersImported }} />
        </div>
      </div>
    </div>
  ) : (
    <div className="flex gap-4">
      {deleteIcon}

      <div>
        <FormattedMessage id={translations.pages.members.couldNotReadFile} />

        <div className="text-sm font-normal text-gray-500">
          <FormattedMessage id={translations.pages.members.checkFormat} />
        </div>
      </div>
    </div>
  );
};
