import {
  CalendarIcon,
  ChatBubbleLeftEllipsisIcon,
  ChevronRightIcon,
  HashtagIcon,
  InboxIcon,
  QuestionMarkCircleIcon,
  Squares2X2Icon,
  TicketIcon,
  UserIcon,
  UsersIcon
} from '@heroicons/react/24/outline';
import classnames from 'classnames';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link as RouterLink, useLocation } from 'react-router-dom';

import { translations } from '@/locales';

import { useRole } from '@/hooks/useRole';

import { Avatar } from './Avatar';
import { Button } from './Button';
import { Link } from './Link';
import { Logo } from './Logo';

interface NavigationItem {
  name: React.ReactNode;
  to: string;
  icon: React.ReactNode;
}

const navigation: NavigationItem[] = [
  {
    icon: <Squares2X2Icon className="h-6 w-6 stroke-2 text-gray-400" />,
    name: <FormattedMessage id={translations.pages.community.communities} />,
    to: '/communities'
  },
  {
    icon: <UsersIcon className="h-6 w-6 stroke-2 text-gray-400" />,
    name: <FormattedMessage id={translations.pages.members.members} />,
    to: '/members'
  },
  {
    icon: <TicketIcon className="h-6 w-6 stroke-2 text-gray-400" />,
    name: <FormattedMessage id={translations.pages.benefits.benefits} />,
    to: '/benefits'
  },
  {
    icon: <HashtagIcon className="h-6 w-6 stroke-2 text-gray-400" />,
    name: <FormattedMessage id={translations.pages.groups.groups} />,
    to: '/groups'
  },
  {
    icon: <ChatBubbleLeftEllipsisIcon className="h-6 w-6 stroke-2 text-gray-400" />,
    name: <FormattedMessage id={translations.pages.messages.messages} />,
    to: '/messages'
  },
  {
    icon: <CalendarIcon className="h-6 w-6 stroke-2 text-gray-400" />,
    name: <FormattedMessage id={translations.pages.events.events} />,
    to: '/events'
  },
  {
    icon: <InboxIcon className="h-6 w-6 stroke-2 text-gray-400" />,
    name: <FormattedMessage id={translations.pages.services.services} />,
    to: '/services'
  },
  {
    icon: <QuestionMarkCircleIcon className="h-6 w-6 stroke-2 text-gray-400" />,
    name: <FormattedMessage id={translations.pages.questions.questions} />,
    to: '/questions'
  }
];

export const Sidebar: React.FC = () => {
  const { isAdmin, community } = useRole();

  const location = useLocation();

  const currentUrl = location.pathname;

  return (
    <div className="flex w-1/6 min-w-[160px] max-w-sm flex-col justify-between border-r border-gray-200">
      <div>
        <Logo className="mx-4 my-6" size="lg" />

        {!isAdmin && (
          <Button
            as={RouterLink}
            className="mx-2 flex h-14 items-center rounded-2xl bg-gray-100 p-2"
            to={`/communities/${community?.id}`}
          >
            <div className="flex h-full w-full items-center gap-2">
              <Avatar alt="community-logo" size="lg" src={community?.image} />

              <span className="max-h-full max-w-full overflow-hidden text-ellipsis font-medium">{community?.name}</span>
            </div>

            <ChevronRightIcon className="h-5 w-5 justify-self-end text-neutral-500" />
          </Button>
        )}

        <nav className="px-2 py-4">
          {navigation.map((item) => (
            <Link
              as={RouterLink}
              className={classnames(
                !isAdmin && (item.to === '/questions' || item.to === '/communities') && 'hidden',
                isAdmin && (item.to === '/benefits' || item.to === '/services' || item.to === '/events') && 'hidden',
                currentUrl.includes(item.to) ? 'bg-gray-100 text-gray-900' : 'text-gray-600 hover:bg-gray-100',
                'my-1 flex items-center rounded-md px-2 py-2 text-sm font-medium hover:text-inherit '
              )}
              key={item.to}
              to={item.to}
            >
              <span className="mr-3 text-gray-600 ">{item.icon}</span>

              <span>{item.name}</span>
            </Link>
          ))}
        </nav>
      </div>

      <div className="border-t p-4">
        <Link as={RouterLink} className="inline-flex items-center gap-4 self-start" to="/profile">
          <UserIcon className="h-6 w-6 stroke-2 text-gray-400" />

          <span className="text-sm font-medium text-gray-600">
            <FormattedMessage id={translations.pages.profile.myProfile} />
          </span>
        </Link>
      </div>
    </div>
  );
};
