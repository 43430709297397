import { XMarkIcon } from '@heroicons/react/24/outline';
import classnames from 'classnames';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import { translations } from '@/locales';

interface Props {
  centered?: boolean;
  onClose?(): void;
}

export const ModalHeader: React.FC<React.PropsWithChildren<Props>> = ({ centered, children, onClose }) => (
  <header className="relative">
    <h1
      className={classnames('text-lg font-medium text-gray-900', {
        'pr-10': !centered && onClose,
        'text-center': centered
      })}
    >
      {children}
    </h1>

    {!!onClose && (
      <button className="absolute top-1/2 right-0 -translate-y-1/2" onClick={onClose} type="button">
        <span className="sr-only">
          <FormattedMessage id={translations.buttons.close} />
        </span>
        <XMarkIcon aria-hidden="true" className="h-6 w-6" />
      </button>
    )}
  </header>
);
