import { translations } from '@/locales';
import classnames from 'classnames';
import * as React from 'react';
import { useField } from 'react-final-form';
import { FormattedMessage } from 'react-intl';

interface Props {
  className?: string;
  max: number;
  name: string;
}

export const MaxLengthIndicator: React.FC<React.PropsWithChildren<Props>> = ({ className, children, max, name }) => {
  const field = useField(name);

  return (
    <div className={classnames('flex flex-col', className)}>
      <div>{children}</div>

      <span
        className={classnames('mt-1 self-end text-xs text-gray-500', field.input.value.length > max && 'text-red-500')}
      >
        ({field.input.value.length} /{' '}
        <FormattedMessage id={translations.customValidation.maxCharacters} values={{ value: max }} />)
      </span>
    </div>
  );
};
