/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateGroupDto } from '../models/CreateGroupDto';
import type { GroupDto } from '../models/GroupDto';
import type { GroupMemberPageDto } from '../models/GroupMemberPageDto';
import type { GroupPageDto } from '../models/GroupPageDto';
import type { GroupsAndUserIdsDto } from '../models/GroupsAndUserIdsDto';
import type { UpdateGroupDto } from '../models/UpdateGroupDto';
import type { UserIdsDto } from '../models/UserIdsDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class GroupService {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Return a page of groups.
   * @returns GroupPageDto Returns a page of messages.
   * @throws ApiError
   */
  public findGroups({
    communityId,
    searchQuery,
    pageSize,
    pageNumber,
  }: {
    /**
     * The id of the community.
     */
    communityId?: string,
    /**
     * text to search by.
     */
    searchQuery?: string,
    pageSize?: number,
    pageNumber?: number,
  }): CancelablePromise<GroupPageDto> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/groups/find',
      query: {
        'communityId': communityId,
        'searchQuery': searchQuery,
        'pageSize': pageSize,
        'pageNumber': pageNumber,
      },
      errors: {
        401: `Credentials are missing or invalid.`,
      },
    });
  }

  /**
   * Create a group.
   * @returns GroupDto Returns the created group.
   * @throws ApiError
   */
  public createGroup({
    requestBody,
    communityId,
  }: {
    requestBody: CreateGroupDto,
    /**
     * The id of the community this group is associated with.
     */
    communityId?: string,
  }): CancelablePromise<GroupDto> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/groups/create',
      query: {
        'communityId': communityId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        401: `Credentials are missing or invalid.`,
      },
    });
  }

  /**
   * Return group details.
   * @returns GroupDto Returns the group.
   * @throws ApiError
   */
  public getGroupDetails({
    groupId,
  }: {
    /**
     * The id of the group.
     */
    groupId: string,
  }): CancelablePromise<GroupDto> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/groups/get-details',
      query: {
        'groupId': groupId,
      },
      errors: {
        401: `Credentials are missing or invalid.`,
      },
    });
  }

  /**
   * Update group details.
   * @returns GroupDto Returns the created group.
   * @throws ApiError
   */
  public updateGroupDetails({
    groupId,
    requestBody,
  }: {
    /**
     * The id of the group.
     */
    groupId: string,
    requestBody: UpdateGroupDto,
  }): CancelablePromise<GroupDto> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/groups/update-details',
      query: {
        'groupId': groupId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        401: `Credentials are missing or invalid.`,
        404: `Resource not found.`,
      },
    });
  }

  /**
   * Delete a group.
   * @returns void
   * @throws ApiError
   */
  public deleteGroup({
    groupId,
  }: {
    /**
     * The id of the group.
     */
    groupId: string,
  }): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/groups/delete',
      query: {
        'groupId': groupId,
      },
      errors: {
        401: `Credentials are missing or invalid.`,
        404: `Resource not found.`,
      },
    });
  }

  /**
   * Return a page of members part of this group.
   * @returns GroupMemberPageDto Returns a page of members part of this group.
   * @throws ApiError
   */
  public findGroupMembers({
    groupId,
    pageSize,
    pageNumber,
  }: {
    /**
     * The id of the group.
     */
    groupId: string,
    pageSize?: number,
    pageNumber?: number,
  }): CancelablePromise<GroupMemberPageDto> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/groups/find-members',
      query: {
        'groupId': groupId,
        'pageSize': pageSize,
        'pageNumber': pageNumber,
      },
      errors: {
        401: `Credentials are missing or invalid.`,
        404: `Resource not found.`,
      },
    });
  }

  /**
   * Add members to this group.
   * @returns void
   * @throws ApiError
   */
  public addGroupMembers({
    groupId,
    requestBody,
  }: {
    /**
     * The id of the group.
     */
    groupId: string,
    requestBody: GroupsAndUserIdsDto,
  }): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/groups/add-members',
      query: {
        'groupId': groupId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        401: `Credentials are missing or invalid.`,
        404: `Resource not found.`,
      },
    });
  }

  /**
   * Remove members from group.
   * @returns void
   * @throws ApiError
   */
  public removeMembersFromGroup({
    groupId,
    requestBody,
  }: {
    /**
     * The id of the group.
     */
    groupId: string,
    requestBody: UserIdsDto,
  }): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/groups/remove-members',
      query: {
        'groupId': groupId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        401: `Credentials are missing or invalid.`,
        404: `Resource not found.`,
      },
    });
  }

}
