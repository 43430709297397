import { GroupPreviewDto } from '@billy/management-api-sdk';
import React from 'react';
import { FormattedDate, FormattedMessage } from 'react-intl';
import { Link as RouterLink } from 'react-router-dom';

import { translations } from '@/locales';

import { Badge } from '@/components/Badge';
import { Button } from '@/components/Button';
import { EmptyList } from '@/components/EmptyList';
import { NoResultsFound } from '@/components/NoResultsFound';

import { Table, TableBody, TableHeader, TableHeaderColumn, TableRow, TableRowColumn } from './Table';

type Props = {
  groups?: GroupPreviewDto[];
  className?: string;
  empty?: boolean;
  noResults?: boolean;
};

export const GroupsList: React.FC<Props> = ({ groups, className, noResults, empty }) => {
  return empty ? (
    <EmptyList
      description={<FormattedMessage id={translations.emptyStates.createGroup} />}
      title={<FormattedMessage id={translations.emptyStates.noGroups} />}
    >
      <Button appearance="primary" as={RouterLink} className="flex w-48 justify-center" to="/groups/new">
        <FormattedMessage id={translations.buttons.createGroup} />
      </Button>
    </EmptyList>
  ) : (
    <Table {...{ className }}>
      <TableHeader>
        <TableRow>
          <TableHeaderColumn>
            <FormattedMessage id={translations.pages.groups.group} />
          </TableHeaderColumn>

          <TableHeaderColumn>
            <FormattedMessage id={translations.pages.members.members} />
          </TableHeaderColumn>

          <TableHeaderColumn>
            <FormattedMessage id={translations.fields.dateCreated} />
          </TableHeaderColumn>
        </TableRow>
      </TableHeader>

      <TableBody>
        {noResults ? (
          <TableRow>
            <td colSpan={4}>
              <NoResultsFound />
            </td>
          </TableRow>
        ) : (
          groups?.map((group, index) => (
            <RouterLink className="table-row hover:bg-indigo-50" key={index} to={`/groups/${group.id}`}>
              <TableRowColumn>
                <Badge appearance="group">{group.name}</Badge>
              </TableRowColumn>

              <TableRowColumn>
                {group.memberCount} <FormattedMessage id={translations.pages.members.members} />
              </TableRowColumn>

              <TableRowColumn>
                <FormattedDate day="2-digit" month="2-digit" value={group.createdAt} year="numeric" />
              </TableRowColumn>
            </RouterLink>
          ))
        )}
      </TableBody>
    </Table>
  );
};
