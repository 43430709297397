import { CheckCircleIcon, ExclamationTriangleIcon } from '@heroicons/react/24/solid';
import classnames from 'classnames';
import React from 'react';

type Props = {
  success?: boolean;
  className?: string;
};

export const FeedbackMessage = React.forwardRef<HTMLDivElement, React.PropsWithChildren<Props>>(
  ({ className, success, children }, ref) => {
    return (
      <div
        {...{ ref }}
        className={classnames(
          'flex items-center justify-center gap-2 p-2 text-sm font-medium',
          success ? 'bg-green-50 text-green-800 ' : 'rounded-lg border border-red-700 bg-red-50 text-red-700',
          className
        )}
      >
        {success ? (
          <CheckCircleIcon className="w-8 text-green-500" />
        ) : (
          <ExclamationTriangleIcon className="w-8 text-red-500" />
        )}

        {children}
      </div>
    );
  }
);
