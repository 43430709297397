import * as React from 'react';
import TextMaskInput from 'react-text-mask';

import { AsComponent } from '@/components/As';

import { Props as InputProps } from './Input';

type MaskFormat = Array<RegExp> | string;

const generateMaskFromFormat = (format: MaskFormat) => {
  if (Array.isArray(format)) return format;
  if (typeof format === 'function') return format;

  return format.split('').map((key) => {
    if (key === '0') return /\d/;
    return key;
  });
};

export type Props<T extends AsComponent> = InputProps<T> & {
  mask: MaskFormat;
};

export const getValueFromEvent = (event: React.ChangeEvent<HTMLInputElement>) => {
  if (typeof event === 'string') return event;

  return event.target.value;
};

export const MaskedInput = React.forwardRef(function MaskedInput<T extends AsComponent>(
  { as, mask: format, ...props }: Props<T>,
  ref: React.Ref<TextMaskInput>
) {
  const mask = React.useMemo(() => generateMaskFromFormat(format), [format]);

  return <TextMaskInput {...props} guide={false} keepCharPositions {...{ mask, ref }} />;
});
