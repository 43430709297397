/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BulkMembersUploadProgressDto } from '../models/BulkMembersUploadProgressDto';
import type { CommunityDto } from '../models/CommunityDto';
import type { CommunityManagersDto } from '../models/CommunityManagersDto';
import type { CommunityPageDto } from '../models/CommunityPageDto';
import type { CreateCommunityDto } from '../models/CreateCommunityDto';
import type { EmailListDto } from '../models/EmailListDto';
import type { MemberPageDto } from '../models/MemberPageDto';
import type { ProcessedUploadIntentDto } from '../models/ProcessedUploadIntentDto';
import type { UpdateCommunityDto } from '../models/UpdateCommunityDto';
import type { UploadIntentDto } from '../models/UploadIntentDto';
import type { UploadRequestBody } from '../models/UploadRequestBody';
import type { UserIdsDto } from '../models/UserIdsDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class CommunityService {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Return a page of communities.
   * @returns CommunityPageDto Returns a page of community.
   * @throws ApiError
   */
  public findCommunities({
    status,
    searchQuery,
    pageSize,
    pageNumber,
  }: {
    /**
     * Status to filter by.
     */
    status?: 'active' | 'inactive',
    /**
     * text to search by.
     */
    searchQuery?: string,
    pageSize?: number,
    pageNumber?: number,
  }): CancelablePromise<CommunityPageDto> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/communities/find',
      query: {
        'status': status,
        'searchQuery': searchQuery,
        'pageSize': pageSize,
        'pageNumber': pageNumber,
      },
      errors: {
        401: `Credentials are missing or invalid.`,
      },
    });
  }

  /**
   * Create a community.
   * @returns CommunityDto Returns the created message.
   * @throws ApiError
   */
  public createCommunity({
    requestBody,
  }: {
    requestBody: CreateCommunityDto,
  }): CancelablePromise<CommunityDto> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/communities/create',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        401: `Credentials are missing or invalid.`,
        403: `The action is not allowed.`,
      },
    });
  }

  /**
   * Return the details of a community.
   * @returns CommunityDto Returns the community.
   * @throws ApiError
   */
  public getCommunityDetails({
    communityId,
  }: {
    /**
     * The id of the community.
     */
    communityId: string,
  }): CancelablePromise<CommunityDto> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/communities/get-details',
      query: {
        'communityId': communityId,
      },
      errors: {
        401: `Credentials are missing or invalid.`,
        404: `Resource not found.`,
      },
    });
  }

  /**
   * Update the details of a community.
   * @returns CommunityDto Returns the updated community.
   * @throws ApiError
   */
  public updateCommunityDetails({
    communityId,
    requestBody,
  }: {
    /**
     * The id of the community.
     */
    communityId: string,
    requestBody: UpdateCommunityDto,
  }): CancelablePromise<CommunityDto> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/communities/update-details',
      query: {
        'communityId': communityId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        401: `Credentials are missing or invalid.`,
        403: `The action is not allowed.`,
        404: `Resource not found.`,
      },
    });
  }

  /**
   * Suspend community.
   * @returns void
   * @throws ApiError
   */
  public suspendCommunity({
    communityId,
  }: {
    /**
     * The id of the community.
     */
    communityId: string,
  }): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/communities/suspend',
      query: {
        'communityId': communityId,
      },
      errors: {
        401: `Credentials are missing or invalid.`,
        403: `The action is not allowed.`,
        404: `Resource not found.`,
      },
    });
  }

  /**
   * Reactivate community.
   * @returns void
   * @throws ApiError
   */
  public reactivateCommunity({
    communityId,
  }: {
    /**
     * The id of the community.
     */
    communityId: string,
  }): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/communities/reactivate',
      query: {
        'communityId': communityId,
      },
      errors: {
        401: `Credentials are missing or invalid.`,
        403: `The action is not allowed.`,
        404: `Resource not found.`,
      },
    });
  }

  /**
   * Initiate community logo image upload.
   * @returns UploadIntentDto Returns an upload intent.
   * @throws ApiError
   */
  public initiateCommunityLogoUpload({
    communityId,
    requestBody,
  }: {
    /**
     * The id of the community.
     */
    communityId: string,
    /**
     * Only `image/jpeg`, `image/png` are accepted as mimeType.
     */
    requestBody: UploadRequestBody,
  }): CancelablePromise<UploadIntentDto> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/communities/initiate-logo-upload',
      query: {
        'communityId': communityId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        401: `Credentials are missing or invalid.`,
        403: `The action is not allowed.`,
      },
    });
  }

  /**
   * Initiate community membership card image upload.
   * @returns UploadIntentDto Returns an upload intent.
   * @throws ApiError
   */
  public initiateCommunityMembershipCardUpload({
    communityId,
    requestBody,
  }: {
    /**
     * The id of the community.
     */
    communityId: string,
    /**
     * Only `image/jpeg`, `image/png` are accepted as mimeType.
     */
    requestBody: UploadRequestBody,
  }): CancelablePromise<UploadIntentDto> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/communities/initiate-membership-card-upload',
      query: {
        'communityId': communityId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        401: `Credentials are missing or invalid.`,
        403: `The action is not allowed.`,
      },
    });
  }

  /**
   * Add managers to community.
   * @returns CommunityManagersDto Returns the added managers.
   * @throws ApiError
   */
  public addCommunityManagers({
    communityId,
    requestBody,
  }: {
    /**
     * The id of the community.
     */
    communityId: string,
    requestBody: EmailListDto,
  }): CancelablePromise<CommunityManagersDto> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/communities/add-managers',
      query: {
        'communityId': communityId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        401: `Credentials are missing or invalid.`,
        403: `The action is not allowed.`,
      },
    });
  }

  /**
   * Suspend managers.
   * @returns void
   * @throws ApiError
   */
  public suspendCommunityManagers({
    communityId,
    requestBody,
  }: {
    /**
     * The id of the community.
     */
    communityId: string,
    requestBody: UserIdsDto,
  }): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/communities/suspend-managers',
      query: {
        'communityId': communityId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        401: `Credentials are missing or invalid.`,
        403: `The action is not allowed.`,
        404: `Resource not found.`,
      },
    });
  }

  /**
   * Return a page of members part of a community.
   * @returns MemberPageDto Returns a page of members.
   * @throws ApiError
   */
  public findCommunityMembers({
    communityId,
    status,
    searchQuery,
    pageSize,
    pageNumber,
  }: {
    /**
     * The id of the community.
     */
    communityId: string,
    /**
     * Status to filter by.
     */
    status?: 'active' | 'inactive',
    /**
     * text to search by.
     */
    searchQuery?: string,
    pageSize?: number,
    pageNumber?: number,
  }): CancelablePromise<MemberPageDto> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/communities/find-members',
      query: {
        'communityId': communityId,
        'status': status,
        'searchQuery': searchQuery,
        'pageSize': pageSize,
        'pageNumber': pageNumber,
      },
      errors: {
        401: `Credentials are missing or invalid.`,
      },
    });
  }

  /**
   * Add members to a community.
   * @returns void
   * @throws ApiError
   */
  public addCommunityMembers({
    communityId,
    requestBody,
  }: {
    /**
     * The id of the community.
     */
    communityId: string,
    requestBody: EmailListDto,
  }): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/communities/add-members',
      query: {
        'communityId': communityId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        401: `Credentials are missing or invalid.`,
      },
    });
  }

  /**
   * Initiate bulk members upload.
   * @returns ProcessedUploadIntentDto Returns an upload intent.
   * @throws ApiError
   */
  public initiateBulkMembersUpload({
    communityId,
    requestBody,
  }: {
    /**
     * The id of the community.
     */
    communityId: string,
    /**
     * Only `text/csv`, `text/tab-separated-values` are accepted as mimeType.
     */
    requestBody: UploadRequestBody,
  }): CancelablePromise<ProcessedUploadIntentDto> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/communities/initiate-bulk-members-upload',
      query: {
        'communityId': communityId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        401: `Credentials are missing or invalid.`,
        404: `Resource not found.`,
      },
    });
  }

  /**
   * Get bulk members upload progress.
   * @returns BulkMembersUploadProgressDto Returns the progress of the bulk members upload.
   * @throws ApiError
   */
  public getBulkMembersUploadProgress({
    taskId,
    communityId,
  }: {
    /**
     * The id of the task.
     */
    taskId: string,
    /**
     * The id of the community.
     */
    communityId: string,
  }): CancelablePromise<BulkMembersUploadProgressDto> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/communities/get-bulk-members-upload-progress',
      query: {
        'taskId': taskId,
        'communityId': communityId,
      },
      errors: {
        401: `Credentials are missing or invalid.`,
      },
    });
  }

  /**
   * Suspend members.
   * @returns void
   * @throws ApiError
   */
  public suspendCommunityMembers({
    communityId,
    requestBody,
  }: {
    /**
     * The id of the community.
     */
    communityId: string,
    requestBody: UserIdsDto,
  }): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/communities/suspend-members',
      query: {
        'communityId': communityId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        401: `Credentials are missing or invalid.`,
      },
    });
  }

}
