import { ImageValue } from '@/components/Form/ImageFileInput';
import { useCallback } from 'react';

export const useImageInputChange = (onChangeCallback: (image?: ImageValue) => void) => {
  const onChange = useCallback(
    (value: File) => {
      const reader = new FileReader();

      // If there is an error reading the file, clear the value
      reader.onerror = () => onChangeCallback();
      reader.onload = () => {
        const image = new Image();

        // If there is an error loading the image, clear the value
        image.onerror = () => onChangeCallback();
        image.onload = () => onChangeCallback({ file: value, image });

        image.src = reader.result?.toString() ?? '';
      };

      reader.readAsDataURL(value);
    },
    [onChangeCallback]
  );

  return onChange;
};
