import React from 'react';

import { InstanceProps, OpenModal } from '@/components/Modal';

interface ModalState<P extends InstanceProps<any, any>> extends Pick<P, 'data'> {
  isOpen: boolean;
  resolve: (data: P['data']) => void;
  reject: () => void;
}

export const useModal = <P extends InstanceProps<any, any>>(modal: React.ComponentType<P>) => {
  const [state, setState] = React.useState<ModalState<P>>({} as ModalState<P>);

  return React.useMemo(
    () => ({
      open: ((data: Pick<P, 'data'>) => {
        return new Promise((resolve, reject) => {
          setState({ data, isOpen: true, reject, resolve });
        }).finally(() => setState((state) => ({ ...state, isOpen: false })));
      }) as OpenModal<P>,
      props: {
        data: state?.data,
        isOpen: !!state?.isOpen,
        modal,
        onAction: ((data: P['data']) => state?.resolve(data)) as P['onAction'],
        onClose: () => state?.reject()
      }
    }),
    [modal, state, setState]
  );
};
