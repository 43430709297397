import classnames from 'classnames';
import React from 'react';

import { Input, Props } from './Input';

export const TextArea = React.forwardRef<HTMLTextAreaElement, Props<'textarea'>>(function TextArea(props, ref) {
  return (
    <Input
      {...props}
      {...{ ref }}
      as="textarea"
      className={classnames(
        'rounded-md border border-gray-300 p-2 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500',
        props.className
      )}
    />
  );
});
