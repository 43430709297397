/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ProfileDto } from '../models/ProfileDto';
import type { UpdateProfileDto } from '../models/UpdateProfileDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class ProfileService {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Get profile info.
   * @returns ProfileDto Returns profile info.
   * @throws ApiError
   */
  public getProfileDetails(): CancelablePromise<ProfileDto> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/profile/get-details',
      errors: {
        401: `Credentials are missing or invalid.`,
      },
    });
  }

  /**
   * Update profile info.
   * @returns UpdateProfileDto Returns the updated profile.
   * @throws ApiError
   */
  public updateProfileDetails({
    requestBody,
  }: {
    requestBody: UpdateProfileDto,
  }): CancelablePromise<UpdateProfileDto> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/profile/update-details',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        401: `Credentials are missing or invalid.`,
      },
    });
  }

}
