import { MemberDto } from '@billy/management-api-sdk';
import { ChevronDownIcon } from '@heroicons/react/24/outline';
import classnames from 'classnames';
import React from 'react';
import { FormattedDate, FormattedMessage } from 'react-intl';
import { Link as RouterLink } from 'react-router-dom';

import { translations } from '@/locales';

import { apiClient } from '@/services/api';

import { useRole } from '@/hooks/useRole';

import { AsyncButton } from '@/components/AsyncButton';
import { Badge } from '@/components/Badge';
import { Button } from '@/components/Button';
import { Dropdown } from '@/components/Dropdown';
import { EmptyList } from '@/components/EmptyList';
import { NoResultsFound } from '@/components/NoResultsFound';
import { MemberProfile } from '@/components/Profile';

import { Table, TableBody, TableHeader, TableHeaderColumn, TableRow, TableRowColumn } from './Table';

export type Member = {
  id: string;
  firstName: string;
  lastName: string;
  image: string;
  email: string;
  phone: string;
  createdAt: Date;
  active: boolean;
};

type Props = {
  members?: MemberDto[];
  className?: string;
  empty?: boolean;
  noResults?: boolean;
  onAction?(): void;
  communityId?: string;
  onReload?(): void;
};

export const MembersList: React.FC<Props> = ({
  members,
  empty,
  noResults,
  onAction,
  communityId,
  onReload,
  className
}) => {
  const { isAdmin } = useRole();

  return empty ? (
    <EmptyList
      description={!isAdmin && <FormattedMessage id={translations.emptyStates.addMembers} />}
      title={<FormattedMessage id={translations.emptyStates.noMembers} />}
    >
      {!isAdmin && (
        <Dropdown
          buttonText={
            <span className="flex gap-3">
              <FormattedMessage id={translations.buttons.addMembers} />

              <ChevronDownIcon className="h-5 w-5 stroke-2" />
            </span>
          }
        >
          <Button className="w-full rounded-b-none py-2 px-4 text-sm hover:bg-gray-200" onClick={onAction}>
            <FormattedMessage id={translations.pages.members.addAMember} />
          </Button>

          <Button
            as={RouterLink}
            className="flex justify-center rounded-md rounded-t-none text-sm hover:bg-gray-200"
            to="/import-members"
          >
            <FormattedMessage id={translations.pages.members.importMembers} />
          </Button>
        </Dropdown>
      )}
    </EmptyList>
  ) : (
    <Table {...{ className }}>
      <TableHeader>
        <TableRow>
          <TableHeaderColumn>
            <FormattedMessage id={translations.fields.name} />
          </TableHeaderColumn>

          <TableHeaderColumn>
            <FormattedMessage id={translations.fields.phone} />
          </TableHeaderColumn>

          <TableHeaderColumn>
            <FormattedMessage id={translations.fields.dateAdded} />
          </TableHeaderColumn>

          <TableHeaderColumn>
            <FormattedMessage id={translations.fields.status} />
          </TableHeaderColumn>

          <TableHeaderColumn />
        </TableRow>
      </TableHeader>

      <TableBody>
        {noResults ? (
          <TableRow>
            <td colSpan={5}>
              <NoResultsFound />
            </td>
          </TableRow>
        ) : (
          members?.map((member, index) => (
            <TableRow key={index}>
              <TableRowColumn>
                <MemberProfile
                  email={member.email}
                  firstName={member.firstName}
                  lastName={member.lastName}
                  src={member.image}
                />
              </TableRowColumn>

              <TableRowColumn>{member.phone}</TableRowColumn>

              <TableRowColumn>
                <FormattedDate day="2-digit" month="2-digit" value={member.addedAt} year="numeric" />
              </TableRowColumn>

              <TableRowColumn>
                <Badge appearance={member.active ? 'active' : 'inactive'}>
                  <FormattedMessage id={translations.statuses.active} values={{ active: member.active }} />
                </Badge>
              </TableRowColumn>

              <TableRowColumn>
                <AsyncButton
                  appearance="basic"
                  className={classnames({ 'text-indigo-400': !member.active })}
                  onClick={async () => {
                    member.active
                      ? await apiClient.community
                          .suspendCommunityMembers({
                            communityId: communityId!,
                            requestBody: { userIds: [member.id] }
                          })
                          .then(() => !!onReload && onReload())
                      : await apiClient.community
                          .addCommunityMembers({
                            communityId: communityId!,
                            requestBody: { emails: [member.email] }
                          })
                          .then(() => !!onReload && onReload());
                  }}
                >
                  <FormattedMessage id={translations.buttons.suspend} values={{ active: member.active }} />
                </AsyncButton>
              </TableRowColumn>
            </TableRow>
          ))
        )}
      </TableBody>
    </Table>
  );
};
