import { ExclamationCircleIcon } from '@heroicons/react/24/outline';
import classnames from 'classnames';
import React from 'react';

import { extractErrorMessage } from '@/utils/functions';

type Props = {
  className?: string;
  error: Error;
};

export const DisplayError: React.FC<Props> = ({ className, error }) => {
  return (
    <div className={classnames('flex items-center justify-center gap-3', className)}>
      <div className="flex h-10 w-10 items-center justify-center rounded-full bg-red-100 p-2">
        <ExclamationCircleIcon className="h-6 w-6 stroke-2 text-lg text-red-600" />
      </div>

      <div className="font-medium text-gray-900">{extractErrorMessage(error)}</div>
    </div>
  );
};
